import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import Poles, { POLES_LACK } from '../Input/Poles';
import RivetNutPole from '../Input/RivetNutPole';
import MaskingRod from '../Input/MaskingRod';
import Holder, {
  HOLDER_FORM_NAME,
  HOLDER_NO,
} from '../Input/Holder';
import CoverBetweenWings from '../Input/CoverBetweenWings';
import Hinges from '../Input/Hinges';
import FormActions from '../Input/FormActions';
import { FENCE_TYPE_GATE_GATEWAY } from '../../FenceTypesConstants';
import Machine from '../Input/Machine';
import GateGatewayResponse from './GateGatewayResponse';
import invoke from '../../lambda';

const NonSymmetricalGate = ({ form, updateForm }) => {
  const formik = useFormik({
    initialValues: {
      height: form.values.height || '',
      leftWingWidth: form.values.leftWingWidth || '',
      rightWingWidth: form.values.rightWingWidth || '',
      poles: form.values.poles || '',
      rivetNutPole: form.values.rivetNutPole || '',
      holder: form.values.holder || '',
      hinges: form.values.hinges || '',
      coverBetweenWings: form.values.coverBetweenWings || '',
      machine: form.values.machine || '',
      maskingRod: form.values.maskingRod || '',
    },
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);

      const res = await invoke({
        amount: form.quantity,
        model: 'PB155',
        type: 'uchylna',
        assymetry: 'BRAMO-FURTKA',
        ...values,
      });

      const newForm = {
        ...form,
        values: {
          ...values,
        },
        response: {
          ...res.products[0],
        },
      };

      setSubmitting(false);
      updateForm(newForm);
    },
    validate: ({
      leftWingWidth, rightWingWidth,
    }) => {
      const errors = {};

      if (!leftWingWidth) {
        errors.leftWingWidth = 'Wymagana wartość';
      } else if (leftWingWidth < 500) {
        errors.leftWingWidth = 'Minimalna szerokość to 500mm.';
      } else if (leftWingWidth > 3750) {
        errors.leftWingWidth = 'Maksymalna szerokość to 3750mm.';
      }
      if (!rightWingWidth) {
        errors.rightWingWidth = 'Wymagana wartość';
      } else if (rightWingWidth < 500) {
        errors.rightWingWidth = 'Minimalna szerokość to 500mm.';
      } else if (rightWingWidth > 3750) {
        errors.rightWingWidth = 'Maksymalna szerokość to 3750mm.';
      }
      return errors;
    },
  });
  useEffect(() => {
    if (formik.values.poles === POLES_LACK) {
      formik.setFieldValue(HOLDER_FORM_NAME, HOLDER_NO);
    }
  }, [formik.values.poles]);
  const isResponseAvailable = Object.keys(form.response).length > 0;

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="formInputs">
        <div className="form-group">
          <label htmlFor={`height__${form.id}`}>Wysokość (mm)</label>
          <select
            type="number"
            className="form-control"
            placeholder="Wybierz"
            id={`height__${form.id}`}
            name="height"
            value={formik.values.height}
            onChange={formik.handleChange}
            required
          >
            <option value="" disabled>Wybierz</option>
            <option value={1025}>1025</option>
            <option value={1055}>1055</option>
            <option value={1085}>1085</option>
            <option value={1195}>1195</option>
            <option value={1230}>1230</option>
            <option value={1265}>1265</option>
            <option value={1365}>1365</option>
            <option value={1405}>1405</option>
            <option value={1445}>1445</option>
            <option value={1535}>1535</option>
            <option value={1580}>1580</option>
            <option value={1625}>1625</option>
            <option value={1705}>1705</option>
            <option value={1755}>1755</option>
            <option value={1805}>1805</option>
            <option value={1875}>1875</option>
            <option value={1930}>1930</option>
            <option value={1985}>1985</option>
            <option value={2045}>2045</option>
            <option value={2105}>2105</option>
            <option value={2165}>2165</option>
            <option value={2215}>2215</option>
            <option value={2280}>2280</option>
            <option value={2345}>2345</option>
          </select>
        </div>
        <div className="form-group">
          <label htmlFor={`leftWingWidth__${form.id}`}>Lewe skrzydło szerokość (mm)</label>
          <input
            type="number"
            className="form-control"
            placeholder="Wpisz"
            id={`leftWingWidth__${form.id}`}
            name="leftWingWidth"
            value={formik.values.leftWingWidth}
            autoComplete="off"
            onChange={formik.handleChange}
            required
          />
          <span className="error">{formik.errors.leftWingWidth && formik.errors.leftWingWidth}</span>
        </div>
        <div className="form-group">
          <label htmlFor={`rightWingWidth__${form.id}`}>Prawe skrzydło szerokość (mm)</label>
          <input
            type="number"
            className="form-control"
            placeholder="Wpisz"
            id={`rightWingWidth__${form.id}`}
            name="rightWingWidth"
            value={formik.values.rightWingWidth}
            autoComplete="off"
            onChange={formik.handleChange}
            required
          />
          <span className="error">{formik.errors.rightWingWidth && formik.errors.rightWingWidth}</span>
        </div>
        <Poles
          formId={form.id}
          onChange={formik.handleChange}
          fenceType={FENCE_TYPE_GATE_GATEWAY}
          value={formik.values.poles}
        />
        {formik.values.poles && formik.values.poles !== POLES_LACK && (
          <RivetNutPole
            formId={form.id}
            onChange={formik.handleChange}
            value={formik.values.rivetNutPole}
          />
        )}
        <Holder
          formId={form.id}
          onChange={formik.handleChange}
          poles={formik.values.poles}
          value={formik.values.holder}
        />
        <Hinges
          formId={form.id}
          onChange={formik.handleChange}
          fenceType={FENCE_TYPE_GATE_GATEWAY}
          value={formik.values.hinges}
        />
        {formik.values.hinges && formik.values.hinges === 'standard' && formik.values.leftWingWidth && formik.values.rightWingWidth && Number(formik.values.rightWingWidth) < 3000 && Number(formik.values.leftWingWidth) < 3000 && (
          <MaskingRod
            formId={form.id}
            onChange={formik.handleChange}
            value={formik.values.maskingRod}
          />
        )}
        <CoverBetweenWings
          formId={form.id}
          onChange={formik.handleChange}
          value={formik.values.coverBetweenWings}
        />
        {(formik.values.leftWingWidth < 3000 && formik.values.rightWingWidth < 3000) && (
          <Machine
            formId={form.id}
            onChange={formik.handleChange}
            value={formik.values.machine}
          />
        )}
      </div>
      <FormActions isSubmitting={formik.isSubmitting} />
      {form.response?.error && (
        <h2 style={{ color: 'red' }}>
          Wystąpił błąd:
          {' '}
          {form.response.error}
        </h2>
      )}
      {isResponseAvailable && !form.response?.error && <GateGatewayResponse values={form.values} response={form.response} isPdf={false} />}
    </form>
  );
};

NonSymmetricalGate.propTypes = {
  form: PropTypes.object.isRequired,
  updateForm: PropTypes.func.isRequired,
};

export default NonSymmetricalGate;
