import styled from 'styled-components';

export const Container = styled.main`
   background: #2d3234;
   min-height: 100vh;
  display: flex;
  justify-content: center;
`;

export const LoginContainerRow = styled.div`
   display: flex;
   flex-direction: row;
   justify-content: space-between;
`;

export const ContainerCol4 = styled.div`
   position: relative;
   &:after {
      content: '';
      position: absolute;
      top: 0;
      background: #ffffff;
      width: 90px;
      height: 100%;
      right: -45px;
   }
`;

export const LoginFormContainer = styled.div`
   height: 100%;
   padding: 4rem 3rem;
   background: #ffffff;
   border-radius: 22px 0 0 22px;
`;

export const LoginFormInput = styled.input`
   background: #f2f2f2;
   border-radius: 22px;
   border: none;
   padding-left: 2.5rem;

   &:focus {
      background: #f2f2f2;
      outline: none;
      border: none;
      box-shadow: none;
   }
`;

export const InputContainer = styled.div`
   position: relative;
   margin-bottom: 0.5rem;
`;

export const ButtonsContainer = styled.div`
   display: flex;
   flex-direction: row;
   align-content: center;
   justify-content: flex-start;
   margin: 1rem 0 1.5rem 0;
`;

export const LoginButton = styled.button`
   border-radius: 22px;
   padding: 0.25rem 2.5rem;
`;

export const ButtonText = styled.span`
   margin-right: 1rem;
`;

export const Image = styled.img`
   width: 100%;
   border-radius: 22px;
`;

export const ResponseContainer = styled.div`
   margin-top: 2rem;
  ${(props) => props.error && 'color: #ff0000;'}
`;
