import React from 'react';

export const HINGED_COVER_YES = 'yes';
export const HINGED_COVER_NO = 'no';
export const HINGED_COVER_FORM_NAME = 'cover';

const HingedCoverSelect = ({
  classNames,
  onChange,
  formId,
  value,
}) => (
  <div className={classNames}>
    <label htmlFor={`hingedCover__${formId}`}>Maskownica zawiasowa</label>
    <select
      className="form-control"
      id={`hingedCover__${formId}`}
      name={HINGED_COVER_FORM_NAME}
      onChange={onChange}
      value={value}
      required
    >
      <option value="" disabled>Wybierz</option>
      <option value={HINGED_COVER_YES}>TAK</option>
      <option value={HINGED_COVER_NO}>NIE</option>
    </select>
  </div>
);

const Cover = ({
  formId, onChange, value,
}) => {
  const getProps = () => ({
    onChange,
    formId,
    value,
  });
  const props = getProps();

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <HingedCoverSelect {...props} />
  );
};

export default Cover;
