import React from 'react';

export const KNS_MAILBOX_YES = 'yes';
export const KNS_MAILBOX_NO = 'no';

const InvisibleMailbox = ({
  formId, onChange, value,
}) => (
  <div className="form-group">
    <label htmlFor={`knsMailbox__${formId}`}>Skrzynka Invisible</label>
    <select
      className="form-control"
      id={`knsMailbox__${formId}`}
      name="knsMailbox"
      value={value || ''}
      onChange={onChange}
      required
    >
      <option value="" disabled>Wybierz</option>
      <option value={KNS_MAILBOX_YES}>TAK</option>
      <option value={KNS_MAILBOX_NO}>NIE</option>
    </select>
  </div>
);

export default InvisibleMailbox;
