import React from 'react';
import { useSelector } from 'react-redux';
import Footer from 'components/Footer';
import SignIn from 'Pages/SignIn/SignIn';
import Header from 'Pages/shared/header';
import Welcome from '../welcome';

const Home = () => {
  const { app: appState } = useSelector((s) => s);
  const { authenticated } = appState;

  if (!authenticated) {
    return (
      <>
        <main>
          <SignIn />
        </main>
        <Footer />
      </>
    );
  }
  return (
    <>
      <main>
        <Header />
        <Welcome />
      </main>
      <Footer />
    </>
  );
};

export default Home;
