import React from 'react';
import PropTypes from 'prop-types';
import { GEAR_RAIL_NO } from '../Input/GearRail';

export const PS004GateSelfSupportingSecondPagePdf = ({ values, response, isPdf }) => {
  const railHoles = () => (
    <>
      <table className="table">
        <thead>
          <tr>
            <th>
              OTWORY POD SZTACHETĘ
            </th>
          </tr>
        </thead>
      </table>
      <p>
        <b>Otwór 1</b>
        {': '}
        {response.productionMaterials.railHoles.holeOne.size.value}
        {' '}
        mm
        {response.productionMaterials.railHoles.holeTwo.name !== 'Nie dotyczy' && (
          <>
            {' '}
            |
            {' '}
            <b>Otwór 2</b>
            {': '}
            {response.productionMaterials.railHoles.holeTwo.size.value}
            {' '}
            mm
          </>
        )}
        {response.productionMaterials.railHoles.holeThree.name !== 'Nie dotyczy' && (
          <>
            {' '}
            |
            {' '}
            <b>Otwór 3</b>
            {': '}
            {response.productionMaterials.railHoles.holeThree.size.value}
            {' '}
            mm
          </>
        )}
        {response.productionMaterials.railHoles.holeFour.name !== 'Nie dotyczy' && (
          <>
            {' '}
            |
            {' '}
            <b>Otwór 4</b>
            {': '}
            {response.productionMaterials.railHoles.holeFour.size.value}
            {' '}
            mm
          </>
        )}
        {response.productionMaterials.railHoles.lastHole.name !== 'Nie dotyczy' && (
          <>
            {' '}
            |
            {' '}
            <b>{response.productionMaterials.railHoles.lastHole.name}</b>
            {': '}
            {response.productionMaterials.railHoles.lastHole.size.value}
            {' '}
            mm
          </>
        )}
      </p>
    </>
  );

  const GearRail = () => {
    if (Object.keys(response.productionMaterials.gearRail).length > 0) {
      if (response.productionMaterials.gearRail.gearRailAmount !== 'NIE DOTYCZY' && response.productionMaterials.gearRail.gearRail !== '#VALUE!' && response.productionMaterials.gearRail.gearRailAmount > 0) {
        return (
          <table className="table">
            <thead>
              <tr>
                <th colSpan={isPdf ? '7' : '5'}>MAGAZYN - ZBROJENIE</th>
              </tr>
              <tr>
                <th className="responseThSingular">Lp.</th>
                <th className="responseThName">Nazwa</th>
                <th className="responseThMaterial">Materiał</th>
                <th className="responseThQuantity">Ilość</th>
                <th className="responseThMeasure">Wymiar</th>
                {isPdf && (
                  <>
                    {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                    <th className="responseThSaws" />
                    <th className="responseThD">MAGAZYN</th>
                  </>
                )}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>Listwa zębata</td>
                <td>Listwa zębata 8x30x1005</td>
                <td>
                  {response.productionMaterials.gearRail.gearRailAmount}
                  {' '}
                  szt
                </td>
                <td>
                  1005 mm
                </td>
                {isPdf && (
                  <>
                    <td />
                    <td className="tdCenter">
                      <div className="pdfSquare" />
                    </td>
                  </>
                )}
              </tr>
              {response.productionMaterials.gearRail.cutGearRailAmount !== 'NIE DOTYCZY' && (
                <tr>
                  <td>2</td>
                  <td>Listwa zębata docinana</td>
                  <td>Listwa zębata 8x30x1005</td>
                  <td>
                    {response.productionMaterials.gearRail.cutGearRailAmount}
                  </td>
                  <td>
                    DOCINANA
                  </td>
                  {isPdf && (
                    <>
                      <td />
                      <td className="tdCenter">
                        <div className="pdfSquare" />
                      </td>
                    </>
                  )}
                </tr>
              )}
            </tbody>
          </table>
        );
      }
      return null;
    } return null;
  };

  const InfoForOffice = () => {
    if (values.width <= 5800 && response.productionMaterials.saw.rail) {
      return (
        <div>
          <p><b>BOK</b></p>
          <p>
            Całkowita długość bramy:
            {' '}
            {response.productionMaterials.saw.rail.size.value + 20}
            {' '}
            mm
          </p>
        </div>
      );
    }
    const firstGateHalf = values.gearRail === GEAR_RAIL_NO ? response.productionMaterials.saw.topFrontHorizontalFrameWidth.size.value : response.productionMaterials.saw.bottomZProfile.size.value + 200;
    const secondGateHalf = response.productionMaterials.saw.backRail.size.value;
    if (values.width <= 7500) {
      return (
        <div>
          <p><b>BOK</b></p>
          <p>
            1
            {'. '}
            {firstGateHalf > secondGateHalf ? 'Dłuższa ' : 'Krótsza '}
            połówka bramy:
            {' '}
            {firstGateHalf}
            {' '}
            mm
          </p>
          <p>
            2
            {'. '}
            {secondGateHalf > firstGateHalf ? 'Dłuższa ' : 'Krótsza '}
            połówka bramy:
            {' '}
            {secondGateHalf}
            {' '}
            mm
          </p>
        </div>
      );
    } return null;
  };

  return (
    <>
      {railHoles()}
      <GearRail />
      <InfoForOffice />
    </>
  );
};

const GateSelfSupportingResponse = ({ values, response, isPdf }) => {
  const angles = Object.keys(response.productionMaterials.angles).map((key, index) => (
    <tr key={key}>
      <td>{index + 1}</td>
      <td>{response.productionMaterials.angles[key].name}</td>
      <td>{response.productionMaterials.angles[key].material}</td>
      <td>
        {response.productionMaterials.angles[key].amount.value}
        {' '}
        {response.productionMaterials.angles[key].amount.unit}
      </td>
      <td>
        {response.productionMaterials.angles[key].size.value}
        {' '}
        {response.productionMaterials.angles[key].size.unit}
      </td>
      {isPdf && (
        <>
          <td />
          <td className="tdCenter">
            <div className="pdfSquare" />
          </td>
        </>
      )}
    </tr>
  ));

  const laser = Object.keys(response.productionMaterials.laser).map((key, index) => (
    <tr key={key}>
      <td>{index + 1}</td>
      <td>{response.productionMaterials.laser[key].name}</td>
      <td>{response.productionMaterials.laser[key].material}</td>
      <td>
        {response.productionMaterials.laser[key].amount.value}
        {' '}
        {response.productionMaterials.laser[key].amount.unit}
      </td>
      <td>
        {response.productionMaterials.laser[key].size.value}
        {' '}
        {response.productionMaterials.laser[key].size.unit}
      </td>
      {isPdf && (
        <>
          <td />
          <td className="tdCenter">
            <div className="pdfSquare" />
          </td>
        </>
      )}
    </tr>
  ));
  const saw = Object.keys(response.productionMaterials.saw).map((key, index) => (
    <tr key={key}>
      <td>{index + 1}</td>
      <td>{response.productionMaterials.saw[key].name}</td>
      <td>{response.productionMaterials.saw[key].material}</td>
      <td>
        {response.productionMaterials.saw[key].amount.value}
        {' '}
        {response.productionMaterials.saw[key].amount.unit}
      </td>
      <td>
        {response.productionMaterials.saw[key].size.value}
        {' '}
        {response.productionMaterials.saw[key].size.unit}
      </td>
      {isPdf && (
        <>
          <td className="tdCenter">
            <div className="pdfSquare" />
          </td>
          <td className="tdCenter">
            <div className="pdfSquare" />
          </td>
        </>
      )}
    </tr>
  ));

  const railing = Object.keys(response.productionMaterials.railing).map((key, index) => (
    <tr key={key}>
      <td>{index + 1}</td>
      <td>{response.productionMaterials.railing[key].name}</td>
      <td>{response.productionMaterials.railing[key].material}</td>
      <td>
        {response.productionMaterials.railing[key].amount.value}
        {' '}
        {response.productionMaterials.railing[key].amount.unit}
      </td>
      <td>
        {response.productionMaterials.railing[key].size.value}
        {' '}
        {response.productionMaterials.railing[key].size.unit}
      </td>
      {isPdf && (
        <>
          <td className="tdCenter">
            <div className="pdfSquare" />
          </td>
          <td />
        </>
      )}
    </tr>
  ));

  const railHoles = () => (
    <>
      <table className="table">
        <thead>
          <tr>
            <th>
              OTWORY POD SZTACHETĘ
            </th>
          </tr>
        </thead>
      </table>
      <p>
        <b>Otwór 1</b>
        {': '}
        {response.productionMaterials.railHoles.holeOne.size.value}
        {' '}
        mm
        {response.productionMaterials.railHoles.holeTwo.name !== 'Nie dotyczy' && (
          <>
            {' '}
            |
            {' '}
            <b>Otwór 2</b>
            {': '}
            {response.productionMaterials.railHoles.holeTwo.size.value}
            {' '}
            mm
          </>
        )}
        {response.productionMaterials.railHoles.holeThree.name !== 'Nie dotyczy' && (
          <>
            {' '}
            |
            {' '}
            <b>Otwór 3</b>
            {': '}
            {response.productionMaterials.railHoles.holeThree.size.value}
            {' '}
            mm
          </>
        )}
        {response.productionMaterials.railHoles.holeFour.name !== 'Nie dotyczy' && (
          <>
            {' '}
            |
            {' '}
            <b>Otwór 4</b>
            {': '}
            {response.productionMaterials.railHoles.holeFour.size.value}
            {' '}
            mm
          </>
        )}
        {response.productionMaterials.railHoles.lastHole.name !== 'Nie dotyczy' && (
          <>
            {' '}
            |
            {' '}
            <b>{response.productionMaterials.railHoles.lastHole.name}</b>
            {': '}
            {response.productionMaterials.railHoles.lastHole.size.value}
            {' '}
            mm
          </>
        )}
      </p>
    </>
  );

  const GearRail = () => {
    if (Object.keys(response.productionMaterials.gearRail).length > 0) {
      if (response.productionMaterials.gearRail.gearRailAmount !== 'NIE DOTYCZY' && response.productionMaterials.gearRail.gearRail !== '#VALUE!' && response.productionMaterials.gearRail.gearRailAmount > 0) {
        return (
          <table className="table">
            <thead>
              <tr>
                <th colSpan={isPdf ? '7' : '5'}>MAGAZYN - ZBROJENIE</th>
              </tr>
              <tr>
                <th className="responseThSingular">Lp.</th>
                <th className="responseThName">Nazwa</th>
                <th className="responseThMaterial">Materiał</th>
                <th className="responseThQuantity">Ilość</th>
                <th className="responseThMeasure">Wymiar</th>
                {isPdf && (
                  <>
                    {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                    <th className="responseThSaws" />
                    <th className="responseThD">MAGAZYN</th>
                  </>
                )}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>Listwa zębata</td>
                <td>Listwa zębata 8x30x1005</td>
                <td>
                  {response.productionMaterials.gearRail.gearRailAmount}
                  {' '}
                  szt
                </td>
                <td>
                  1005 mm
                </td>
                {isPdf && (
                  <>
                    <td />
                    <td className="tdCenter">
                      <div className="pdfSquare" />
                    </td>
                  </>
                )}
              </tr>
              {response.productionMaterials.gearRail.cutGearRailAmount !== 'NIE DOTYCZY' && (
                <tr>
                  <td>2</td>
                  <td>Listwa zębata docinana</td>
                  <td>Listwa zębata 8x30x1005</td>
                  <td>
                    {response.productionMaterials.gearRail.cutGearRailAmount}
                  </td>
                  <td>
                    DOCINANA
                  </td>
                  {isPdf && (
                    <>
                      <td />
                      <td className="tdCenter">
                        <div className="pdfSquare" />
                      </td>
                    </>
                  )}
                </tr>
              )}
            </tbody>
          </table>
        );
      }
      return null;
    } return null;
  };

  const InfoForOffice = () => {
    if (values.width <= 5800 && response.productionMaterials.saw.rail) {
      return (
        <div>
          <p><b>BOK</b></p>
          <p>
            Całkowita długość bramy:
            {' '}
            {response.productionMaterials.saw.rail.size.value + 20}
            {' '}
            mm
          </p>
        </div>
      );
    }
    const firstGateHalf = values.gearRail === GEAR_RAIL_NO ? response.productionMaterials.saw.topFrontHorizontalFrameWidth.size.value : response.productionMaterials.saw.bottomZProfile.size.value + 200;
    const secondGateHalf = response.productionMaterials.saw.backRail.size.value;
    if (values.width <= 7500) {
      return (
        <div>
          <p><b>BOK</b></p>
          <p>
            1
            {'. '}
            {firstGateHalf > secondGateHalf ? 'Dłuższa ' : 'Krótsza '}
            połówka bramy:
            {' '}
            {firstGateHalf}
            {' '}
            mm
          </p>
          <p>
            2
            {'. '}
            {secondGateHalf > firstGateHalf ? 'Dłuższa ' : 'Krótsza '}
            połówka bramy:
            {' '}
            {secondGateHalf}
            {' '}
            mm
          </p>
        </div>
      );
    } return null;
  };

  return (
    <div>
      {(values.width <= 5800 && response.productionMaterials.info.gateIsRectangle && response.productionMaterials.info.gateIsRectangle === 'TAK')
        && (
          <p>
            <b>Czy brama prostokątna?</b>
            {' '}
            {response.productionMaterials.info.gateIsRectangle}
          </p>
        )}
      <table className="table">
        <thead>
          <tr>
            <th colSpan={isPdf ? '7' : '5'}>KĄTY</th>
          </tr>
          <tr>
            <th className="responseThSingular">Lp.</th>
            <th className="responseThName">Nazwa</th>
            <th className="responseThMaterial">Materiał</th>
            <th className="responseThQuantity">Ilość</th>
            <th className="responseThMeasure">Wymiar</th>
            {isPdf && (
              <>
                <th className="responseThSaws" aria-label="extraColumn" />
                <th className="responseThD">KĄTY</th>
              </>
            )}
          </tr>
        </thead>
        <tbody>
          {angles}
        </tbody>
      </table>
      <table className="table">
        <thead>
          <tr>
            <th colSpan={isPdf ? 7 : 5}>LASER - MATERIAŁY DO WYPALENIA</th>
          </tr>
          <tr>
            <th className="responseThSingular">Lp.</th>
            <th className="responseThName">Nazwa</th>
            <th className="responseThMaterial">Materiał</th>
            <th className="responseThQuantity">Ilość</th>
            <th className="responseThMeasure">Wymiar</th>
            {isPdf && (
              <>
                <th>{' '}</th>
                <th className="responseThSaws">3D</th>
              </>
            )}
          </tr>
        </thead>
        <tbody>
          {laser}
        </tbody>
      </table>
      <table className="table">
        <thead>
          <tr>
            <th colSpan={isPdf ? '7' : '5'}>PIŁA - MATERIAŁY DO WYCIĘCIA</th>
          </tr>
          <tr>
            <th className="responseThSingular">Lp.</th>
            <th className="responseThName">Nazwa</th>
            <th className="responseThMaterial">Materiał</th>
            <th className="responseThQuantity">Ilość</th>
            <th className="responseThMeasure">Wymiar</th>
            {isPdf && (
              <>
                <th className="responseThSaws">PIŁA</th>
                <th className="responseThD">2D</th>
              </>
            )}
          </tr>
        </thead>
        <tbody>
          {saw}
          {Object.keys(response.productionMaterials.gearRail).length > 0 && response.productionMaterials.gearRail.cutGearRailAmount !== 'NIE DOTYCZY' && (
            <tr>
              <td>{saw.length + 1}</td>
              <td>Listwa zębata</td>
              <td>Listwa zębata 8x30x1005</td>
              <td>1 szt</td>
              <td>DOCINANA</td>
              {isPdf && (
                <>
                  <td className="tdCenter">
                    <div className="pdfSquare" />
                  </td>
                  <td className="tdCenter">
                    <div className="pdfSquare" />
                  </td>
                </>
              )}
            </tr>
          )}
        </tbody>
      </table>
      <table className="table">
        <thead>
          <tr>
            <th colSpan="7">SZTACHETY</th>
          </tr>
          <tr>
            <th className="responseThSingular">Lp.</th>
            <th className="responseThName">Nazwa</th>
            <th className="responseThMaterial">Materiał</th>
            <th className="responseThQuantity">Ilość</th>
            <th className="responseThMeasure">Wymiar</th>
            {isPdf && (
              <>
                <th className="responseThSaws">SZTACHETY</th>
                <th>{' '}</th>
              </>
            )}
          </tr>
        </thead>
        <tbody>
          {railing}
        </tbody>
      </table>
      {values.width <= 5600 && railHoles()}
      {!isPdf && (
        <>
          <GearRail />
          <InfoForOffice />
        </>
      )}
    </div>
  );
};

GateSelfSupportingResponse.propTypes = {
  response: PropTypes.object.isRequired,
};

export default GateSelfSupportingResponse;
