import React from 'react';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import FormActions from '../Input/FormActions';
import invoke from '../../lambda';
import EntranceResponse from './EntranceResponse';

const Entrance = ({ form, updateForm }) => {
  const formik = useFormik({
    initialValues: {
      eq: form.values.eq || '',
    },
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);

      const res = await invoke({
        amount: form.quantity,
        model: 'eq',
        type: 'eq',
        ...values,
      });

      const newForm = {
        ...form,
        values: {
          ...values,
        },
        response: {
          ...res.products[0],
        },
      };

      setSubmitting(false);
      updateForm(newForm);
    },
    validate: ({ eq }) => {
      const errors = {};

      if (!eq) {
        errors.eq = 'Wymagana wartość';
      }

      return errors;
    },
  });

  const isResponseAvailable = Object.keys(form.response).length > 0;

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="formInputs">
        <div className="form-group">
          <label htmlFor={`eq__${form.id}`}>Opcje wyposazenia</label>
          <select
            className="form-control"
            id={`eq__${form.id}`}
            name="eq"
            onChange={formik.handleChange}
            value={formik.values.eq}
            required
          >
            <option value="" disabled>Wybierz</option>
            <option key={Math.random()} value={1}>1</option>
            <option key={Math.random()} value={2}>2</option>
            <option key={Math.random()} value={3}>3</option>
            <option key={Math.random()} value={4}>4</option>
            <option key={Math.random()} value={5}>5</option>
            <option key={Math.random()} value={6}>6</option>
            <option key={Math.random()} value={7}>7</option>
            <option key={Math.random()} value={8}>8</option>
            <option key={Math.random()} value={10}>10</option>
            <option key={Math.random()} value={11}>11</option>
          </select>
          <span className="error">{formik.errors.eq && formik.errors.eq}</span>
        </div>
      </div>
      <FormActions isSubmitting={formik.isSubmitting} />
      {form.response?.error && (
        <h2 style={{ color: 'red' }}>
          Wystąpił błąd:
          {' '}
          {form.response.error}
        </h2>
      )}
      {isResponseAvailable && !form.response?.error && <EntranceResponse response={form.response} isPdf={false} />}
    </form>
  );
};

Entrance.propTypes = {
  form: PropTypes.object.isRequired,
  updateForm: PropTypes.func.isRequired,
};

export default Entrance;
