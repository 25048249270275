import React from 'react';
import PropTypes from 'prop-types';

export const PS004bNonSymmetricalSecondPagePdf = ({ values, response, isPdf }) => {
  const railing = Object.keys(response.productionMaterials.railing).map((key, index) => (
    <tr key={key}>
      <td>{index + 1}</td>
      <td>{response.productionMaterials.railing[key].name}</td>
      <td>{response.productionMaterials.railing[key].material}</td>
      <td>
        {response.productionMaterials.railing[key].amount.value}
        {' '}
        {response.productionMaterials.railing[key].amount.unit}
      </td>
      <td>
        {response.productionMaterials.railing[key].size.value}
        {' '}
        {response.productionMaterials.railing[key].size.unit}
      </td>
      <td className="tdCenter">
        <div className="pdfSquare" />
      </td>
      <td />
    </tr>
  ));

  const railHoles = (wing) => (
    <>
      <table className="table">
        <thead>
          <tr>
            <th>
              OTWORY POD SZTACHETĘ
              { }
              {wing === 'leftWing' ? ' - LEWE SKRZYDŁO' : ' - PRAWE SKRZYDŁO'}
            </th>
          </tr>
        </thead>
      </table>
      <p>
        <b>Otwór 1</b>
        {': '}
        {response.productionMaterials.railHoles[wing].holeOne.size.value}
        {' '}
        mm
        {response.productionMaterials.railHoles[wing].holeTwo.name !== 'Nie dotyczy' && (
          <>
            {' '}
            |
            {' '}
            <b>Otwór 2</b>
            {': '}
            {response.productionMaterials.railHoles[wing].holeTwo.size.value}
            {' '}
            mm
          </>
        )}
        {response.productionMaterials.railHoles[wing].holeThree.name !== 'Nie dotyczy' && (
          <>
            {' '}
            |
            {' '}
            <b>Otwór 3</b>
            {': '}
            {response.productionMaterials.railHoles[wing].holeThree.size.value}
            {' '}
            mm
          </>
        )}
        {response.productionMaterials.railHoles[wing].holeFour.name !== 'Nie dotyczy' && (
          <>
            {' '}
            |
            {' '}
            <b>Otwór 4</b>
            {': '}
            {response.productionMaterials.railHoles[wing].holeFour.size.value}
            {' '}
            mm
          </>
        )}
        {response.productionMaterials.railHoles[wing].lastHole.name !== 'Nie dotyczy' && (
          <>
            {' '}
            |
            {' '}
            <b>{response.productionMaterials.railHoles[wing].lastHole.name}</b>
            {': '}
            {response.productionMaterials.railHoles[wing].lastHole.size.value}
            {' '}
            mm
          </>
        )}
      </p>
    </>
  );

  const gallantry = Object.keys(response.productionMaterials.gallantry).map((key, index) => (
    <tr key={key}>
      <td>{index + 1}</td>
      <td>{response.productionMaterials.gallantry[key].name}</td>
      <td>{response.productionMaterials.gallantry[key].material}</td>
      <td>
        {response.productionMaterials.gallantry[key].amount.value}
        {' '}
        {response.productionMaterials.gallantry[key].amount.unit}
      </td>
      <td>
        {response.productionMaterials.gallantry[key].size.value}
        {' '}
        {response.productionMaterials.gallantry[key].size.unit}
      </td>
      {isPdf && (
        <>
          <td className="tdCenter">
            <div className="pdfSquare" />
          </td>
          <td />
        </>
      )}
    </tr>
  ));

  return (
    <>
      <table className="table">
        <thead>
          <tr>
            <th colSpan={isPdf ? 7 : 5}>GALANTERIA</th>
          </tr>
          <tr>
            <th className="responseThSingular">Lp.</th>
            <th className="responseThName">Nazwa</th>
            <th className="responseThMaterial">Materiał</th>
            <th className="responseThQuantity">Ilość</th>
            <th className="responseThMeasure">Wymiar</th>
            {isPdf && (
              <>
                <th className="responseThSaws">GALANTERIA</th>
                <th>{' '}</th>
              </>
            )}
          </tr>
        </thead>
        <tbody>
          {gallantry}
        </tbody>
      </table>
      <table className="table">
        <thead>
          <tr>
            <th colSpan="7">SZTACHETY</th>
          </tr>
          <tr>
            <th className="responseThSingular">Lp.</th>
            <th className="responseThName">Nazwa</th>
            <th className="responseThMaterial">Materiał</th>
            <th className="responseThQuantity">Ilość</th>
            <th className="responseThMeasure">Wymiar</th>
            {isPdf && (
              <>
                <th className="responseThSaws">SZTACHETY</th>
                <th>{' '}</th>
              </>
            )}
          </tr>
        </thead>
        <tbody>
          {railing}
        </tbody>
      </table>
      {railHoles('leftWing')}
      {railHoles('rightWing')}
      {((values.leftWingWidth >= 3000 || values.rightWingWidth >= 3000) && values.height <= 999) && (
        <>
          <p>LEWA STRONA</p>
          <div>
            <p>
              <b>Ilość pól:</b>
              {' '}
              {response.productionMaterials.infoLeft.areaAmount.value}
              {' '}
              {response.productionMaterials.infoLeft.areaAmount.unit}
            </p>
          </div>
          <div>
            <p>
              <b>Wysokość pola [H]:</b>
              {' '}
              {response.productionMaterials.infoLeft.areaHeight.value}
              {' '}
              {response.productionMaterials.infoLeft.areaHeight.unit}
            </p>
          </div>
          <div>
            <p>
              <b>Szerokość pola [L]:</b>
              {' '}
              {response.productionMaterials.infoLeft.areaWidth.value}
              {' '}
              {response.productionMaterials.infoLeft.areaWidth.unit}
            </p>
          </div>
          <div>
            <p>
              <b>Ilość sztachet na 1 pole:</b>
              {' '}
              {response.productionMaterials.infoLeft.railAmount.value}
              {' '}
              {response.productionMaterials.infoLeft.railAmount.unit}
            </p>
          </div>
          <p>PRAWA STRONA</p>
          <div>
            <p>
              <b>Ilość pól:</b>
              {' '}
              {response.productionMaterials.infoRight.areaAmount.value}
              {' '}
              {response.productionMaterials.infoRight.areaAmount.unit}
            </p>
          </div>
          <div>
            <p>
              <b>Wysokość pola [H]:</b>
              {' '}
              {response.productionMaterials.infoRight.areaHeight.value}
              {' '}
              {response.productionMaterials.infoRight.areaHeight.unit}
            </p>
          </div>
          <div>
            <p>
              <b>Szerokość pola [L]:</b>
              {' '}
              {response.productionMaterials.infoRight.areaWidth.value}
              {' '}
              {response.productionMaterials.infoRight.areaWidth.unit}
            </p>
          </div>
          <div>
            <p>
              <b>Ilość sztachet na 1 pole:</b>
              {' '}
              {response.productionMaterials.infoRight.railAmount.value}
              {' '}
              {response.productionMaterials.infoRight.railAmount.unit}
            </p>
          </div>
        </>
      )}
      {(values.leftWingWidth >= 3000 || values.rightWingWidth >= 3000) && values.height >= 1000 && (
        <>
          <p>LEWA STRONA</p>
          <div>
            <p>
              <b>Ilość pól nad automatem:</b>
              {' '}
              {response.productionMaterials.infoLeftAboveMachine.areaAmount.value}
              {' '}
              {response.productionMaterials.infoLeftAboveMachine.areaAmount.unit}
            </p>
          </div>
          <div>
            <p>
              <b>Wysokość pola [H]:</b>
              {' '}
              {response.productionMaterials.infoLeftAboveMachine.areaHeight.value}
              {' '}
              {response.productionMaterials.infoLeftAboveMachine.areaHeight.unit}
            </p>
          </div>
          <div>
            <p>
              <b>Szerokość pola [L]:</b>
              {' '}
              {response.productionMaterials.infoLeftAboveMachine.areaWidth.value}
              {' '}
              {response.productionMaterials.infoLeftAboveMachine.areaWidth.unit}
            </p>
          </div>
          <div>
            <p>
              <b>Ilość sztachet na 1 pole:</b>
              {' '}
              {response.productionMaterials.infoLeftAboveMachine.railAmount.value}
              {' '}
              {response.productionMaterials.infoLeftAboveMachine.railAmount.unit}
            </p>
          </div>
          <div>
            <p>
              <b>Ilość pól pod automatem:</b>
              {' '}
              {response.productionMaterials.infoLeftUnderMachine.areaAmount.value}
              {' '}
              {response.productionMaterials.infoLeftUnderMachine.areaAmount.unit}
            </p>
          </div>
          <div>
            <p>
              <b>Wysokość pola [H]:</b>
              {' '}
              {response.productionMaterials.infoLeftUnderMachine.areaHeight.value}
              {' '}
              {response.productionMaterials.infoLeftUnderMachine.areaHeight.unit}
            </p>
          </div>
          <div>
            <p>
              <b>Szerokość pola [L]:</b>
              {' '}
              {response.productionMaterials.infoLeftUnderMachine.areaWidth.value}
              {' '}
              {response.productionMaterials.infoLeftUnderMachine.areaWidth.unit}
            </p>
          </div>
          <div>
            <p>
              <b>Ilość sztachet na 1 pole:</b>
              {' '}
              {response.productionMaterials.infoLeftUnderMachine.railAmount.value}
              {' '}
              {response.productionMaterials.infoLeftUnderMachine.railAmount.unit}
            </p>
          </div>
          <p>PRAWA STRONA</p>
          <div>
            <p>
              <b>Ilość pól nad automatem:</b>
              {' '}
              {response.productionMaterials.infoRightAboveMachine.areaAmount.value}
              {' '}
              {response.productionMaterials.infoRightAboveMachine.areaAmount.unit}
            </p>
          </div>
          <div>
            <p>
              <b>Wysokość pola [H]:</b>
              {' '}
              {response.productionMaterials.infoRightAboveMachine.areaHeight.value}
              {' '}
              {response.productionMaterials.infoRightAboveMachine.areaHeight.unit}
            </p>
          </div>
          <div>
            <p>
              <b>Szerokość pola [L]:</b>
              {' '}
              {response.productionMaterials.infoRightAboveMachine.areaWidth.value}
              {' '}
              {response.productionMaterials.infoRightAboveMachine.areaWidth.unit}
            </p>
          </div>
          <div>
            <p>
              <b>Ilość sztachet na 1 pole:</b>
              {' '}
              {response.productionMaterials.infoRightAboveMachine.railAmount.value}
              {' '}
              {response.productionMaterials.infoRightAboveMachine.railAmount.unit}
            </p>
          </div>
          <div>
            <p>
              <b>Ilość pól pod automatem:</b>
              {' '}
              {response.productionMaterials.infoRightUnderMachine.areaAmount.value}
              {' '}
              {response.productionMaterials.infoRightUnderMachine.areaAmount.unit}
            </p>
          </div>
          <div>
            <p>
              <b>Wysokość pola [H]:</b>
              {' '}
              {response.productionMaterials.infoRightUnderMachine.areaHeight.value}
              {' '}
              {response.productionMaterials.infoRightUnderMachine.areaHeight.unit}
            </p>
          </div>
          <div>
            <p>
              <b>Szerokość pola [L]:</b>
              {' '}
              {response.productionMaterials.infoRightUnderMachine.areaWidth.value}
              {' '}
              {response.productionMaterials.infoRightUnderMachine.areaWidth.unit}
            </p>
          </div>
          <div>
            <p>
              <b>Ilość sztachet na 1 pole:</b>
              {' '}
              {response.productionMaterials.infoRightUnderMachine.railAmount.value}
              {' '}
              {response.productionMaterials.infoRightUnderMachine.railAmount.unit}
            </p>
          </div>
        </>
      )}
    </>
  );
};

const GateGatewayResponse = ({ values, response, isPdf }) => {
  if (values.leftWingWidth <= 2999 && values.rightWingWidth <= 2999) {
    const angles = Object.keys(response.productionMaterials.angles).map((key, index) => (
      <tr key={key}>
        <td>{index + 1}</td>
        <td>{response.productionMaterials.angles[key].name}</td>
        <td>{response.productionMaterials.angles[key].material}</td>
        <td>
          {response.productionMaterials.angles[key].amount.value}
          {' '}
          {response.productionMaterials.angles[key].amount.unit}
        </td>
        <td>
          {response.productionMaterials.angles[key].size.value}
          {' '}
          {response.productionMaterials.angles[key].size.unit}
        </td>
        {isPdf && (
          <>
            <td />
            <td className="tdCenter">
              <div className="pdfSquare" />
            </td>
          </>
        )}
      </tr>
    ));

    const laser = Object.keys(response.productionMaterials.laser).map((key, index) => (
      <tr key={key}>
        <td>{index + 1}</td>
        <td>{response.productionMaterials.laser[key].name}</td>
        <td>{response.productionMaterials.laser[key].material}</td>
        <td>
          {response.productionMaterials.laser[key].amount.value}
          {' '}
          {response.productionMaterials.laser[key].amount.unit}
        </td>
        <td>
          {response.productionMaterials.laser[key].size.value}
          {' '}
          {response.productionMaterials.laser[key].size.unit}
        </td>
        {isPdf && (
          <>
            <td />
            <td className="tdCenter">
              <div className="pdfSquare" />
            </td>
          </>
        )}
      </tr>
    ));
    const saw = Object.keys(response.productionMaterials.saw).map((key, index) => (
      <tr key={key}>
        <td>{index + 1}</td>
        <td>{response.productionMaterials.saw[key].name}</td>
        <td>{response.productionMaterials.saw[key].material}</td>
        <td>
          {response.productionMaterials.saw[key].amount.value}
          {' '}
          {response.productionMaterials.saw[key].amount.unit}
        </td>
        <td>
          {response.productionMaterials.saw[key].size.value}
          {' '}
          {response.productionMaterials.saw[key].size.unit}
        </td>
        {isPdf && (
          <>
            <td className="tdCenter">
              <div className="pdfSquare" />
            </td>
            <td className="tdCenter">
              <div className="pdfSquare" />
            </td>
          </>
        )}
      </tr>
    ));

    const palisade = (wing) => {
      if (values.leftWingWidth < 3000 && values.rightWingWidth < 3000) {
        return (
          <>
            <table className="table">
              <thead>
                <tr>
                  <th>
                    OTWORY POD PALISADĘ
                    { }
                    {wing === 'leftWing' ? ' - LEWE SKRZYDŁO' : ' - PRAWE SKRZYDŁO'}
                  </th>
                </tr>
              </thead>
            </table>
            <p>
              {response.productionMaterials.palisadeHoles[wing]?.holeOne.name !== 'Nie dotyczy' && (
                <>
                  <b>Otwór 1</b>
                  {': '}
                  {response.productionMaterials.palisadeHoles[wing].holeOne.size.value}
                  {' '}
                  mm
                </>
              )}
              {response.productionMaterials.palisadeHoles[wing].holeTwo.name !== 'Nie dotyczy' && (
                <>
                  {' '}
                  |
                  {' '}
                  <b>Otwór 2</b>
                  {': '}
                  {response.productionMaterials.palisadeHoles[wing].holeTwo.size.value}
                  {' '}
                  mm
                </>
              )}
              {response.productionMaterials.palisadeHoles[wing].holeThree.name !== 'Nie dotyczy' && (
                <>
                  {' '}
                  |
                  {' '}
                  <b>Otwór 3</b>
                  {': '}
                  {response.productionMaterials.palisadeHoles[wing].holeThree.size.value}
                  {' '}
                  mm
                </>
              )}
              {response.productionMaterials.palisadeHoles[wing].holeFour?.name !== 'Nie dotyczy' && (
                <>
                  {' '}
                  |
                  {' '}
                  <b>Otwór 4</b>
                  {': '}
                  {response.productionMaterials.palisadeHoles[wing].holeFour.size.value}
                  {' '}
                  mm
                </>
              )}
              {response.productionMaterials.palisadeHoles[wing].holeFive && response.productionMaterials.palisadeHoles[wing].holeFive?.name !== 'Nie dotyczy' && (
                <>
                  {' '}
                  |
                  {' '}
                  <b>Otwór 5</b>
                  {': '}
                  {response.productionMaterials.palisadeHoles[wing].holeFive.size.value}
                  {' '}
                  mm
                </>
              )}
              {response.productionMaterials.palisadeHoles[wing].holeSix && response.productionMaterials.palisadeHoles[wing].holeSix?.name !== 'Nie dotyczy' && (
                <>
                  {' '}
                  |
                  {' '}
                  <b>Otwór 6</b>
                  {': '}
                  {response.productionMaterials.palisadeHoles[wing].holeSix.size.value}
                  {' '}
                  mm
                </>
              )}
              {response.productionMaterials.palisadeHoles[wing].holeSeven && response.productionMaterials.palisadeHoles[wing].holeSeven?.name !== 'Nie dotyczy' && (
                <>
                  {' '}
                  |
                  {' '}
                  <b>Otwór 7</b>
                  {': '}
                  {response.productionMaterials.palisadeHoles[wing].holeSeven.size.value}
                  {' '}
                  mm
                </>
              )}
              {response.productionMaterials.palisadeHoles[wing].lastHole.name !== 'Nie dotyczy' && (
                <>
                  {' '}
                  |
                  {' '}
                  <b>{response.productionMaterials.palisadeHoles[wing].lastHole.name}</b>
                  {': '}
                  {response.productionMaterials.palisadeHoles[wing].lastHole.size.value}
                  {' '}
                  mm
                </>
              )}
            </p>
          </>
        );
      }
      return (
        <>
          <table className="table">
            <thead>
              <tr>
                <th>
                  OTWORY POD PALISADĘ RAMA
                  { }
                  {wing === 'leftWing' ? ' - LEWE SKRZYDŁO' : ' - PRAWE SKRZYDŁO'}
                </th>
              </tr>
            </thead>
          </table>
          <p>
            {response.productionMaterials.palisadeHoles[wing]?.holeOne.name !== 'Nie dotyczy' && (
              <>
                <b>Otwór 1</b>
                {': '}
                {response.productionMaterials.palisadeHoles[wing].holeOne.size.value}
                {' '}
                mm
              </>
            )}
            {response.productionMaterials.palisadeHoles[wing].holeTwo.name !== 'Nie dotyczy' && (
              <>
                {' '}
                |
                {' '}
                <b>Otwór 2</b>
                {': '}
                {response.productionMaterials.palisadeHoles[wing].holeTwo.size.value}
                {' '}
                mm
              </>
            )}
            {response.productionMaterials.palisadeHoles[wing].holeThree.name !== 'Nie dotyczy' && (
              <>
                {' '}
                |
                {' '}
                <b>Otwór 3</b>
                {': '}
                {response.productionMaterials.palisadeHoles[wing].holeThree.size.value}
                {' '}
                mm
              </>
            )}
            {response.productionMaterials.palisadeHoles[wing].holeFour?.name !== 'Nie dotyczy' && (
              <>
                {' '}
                |
                {' '}
                <b>Otwór 4</b>
                {': '}
                {response.productionMaterials.palisadeHoles[wing].holeFour.size.value}
                {' '}
                mm
              </>
            )}
            {response.productionMaterials.palisadeHoles[wing].holeFive && response.productionMaterials.palisadeHoles[wing].holeFive?.name !== 'Nie dotyczy' && (
              <>
                {' '}
                |
                {' '}
                <b>Otwór 5</b>
                {': '}
                {response.productionMaterials.palisadeHoles[wing].holeFive.size.value}
                {' '}
                mm
              </>
            )}
            {response.productionMaterials.palisadeHoles[wing].holeSix && response.productionMaterials.palisadeHoles[wing].holeSix?.name !== 'Nie dotyczy' && (
              <>
                {' '}
                |
                {' '}
                <b>Otwór 6</b>
                {': '}
                {response.productionMaterials.palisadeHoles[wing].holeSix.size.value}
                {' '}
                mm
              </>
            )}
            {response.productionMaterials.palisadeHoles[wing].holeSeven && response.productionMaterials.palisadeHoles[wing].holeSeven?.name !== 'Nie dotyczy' && (
              <>
                {' '}
                |
                {' '}
                <b>Otwór 7</b>
                {': '}
                {response.productionMaterials.palisadeHoles[wing].holeSeven.size.value}
                {' '}
                mm
              </>
            )}
            {response.productionMaterials.palisadeHoles[wing].lastHole.name !== 'Nie dotyczy' && (
              <>
                {' '}
                |
                {' '}
                <b>{response.productionMaterials.palisadeHoles[wing].lastHole.name}</b>
                {': '}
                {response.productionMaterials.palisadeHoles[wing].lastHole.size.value}
                {' '}
                mm
              </>
            )}
          </p>
          {values.height > 999 && (values.leftWingWidth >= 3000 || values.rightWingWidth >= 3000) && (
            <>
              <table className="table">
                <thead>
                  <tr>
                    <th>
                      OTWORY POD PALISADĘ W DODATKOWYM POZIOMIE
                      {wing === 'leftWing' ? ' - LEWE SKRZYDŁO' : ' - PRAWE SKRZYDŁO'}
                    </th>
                  </tr>
                </thead>
              </table>
              <p>
                <b>Otwór 1</b>
                {': '}
                {response.productionMaterials.railHoles[wing].holeOne.size.value + 2.5}
                {' '}
                mm
                {response.productionMaterials.railHoles[wing].holeTwo.name !== 'Nie dotyczy' && (
                  <>
                    {' '}
                    |
                    {' '}
                    <b>Otwór 2</b>
                    {': '}
                    {response.productionMaterials.railHoles[wing].holeTwo.size.value + 2.5}
                    {' '}
                    mm
                  </>
                )}
                {response.productionMaterials.railHoles[wing].holeThree.name !== 'Nie dotyczy' && (
                  <>
                    {' '}
                    |
                    {' '}
                    <b>Otwór 3</b>
                    {': '}
                    {response.productionMaterials.railHoles[wing].holeThree.size.value + 2.5}
                    {' '}
                    mm
                  </>
                )}
                {response.productionMaterials.railHoles[wing].holeFour.name !== 'Nie dotyczy' && (
                  <>
                    {' '}
                    |
                    {' '}
                    <b>Otwór 4</b>
                    {': '}
                    {response.productionMaterials.railHoles[wing].holeFour.size.value + 2.5}
                    {' '}
                    mm
                  </>
                )}
                {response.productionMaterials.railHoles[wing].lastHole.name !== 'Nie dotyczy' && (
                  <>
                    {' '}
                    |
                    {' '}
                    <b>{response.productionMaterials.railHoles[wing].lastHole.name}</b>
                    {': '}
                    {response.productionMaterials.railHoles[wing].lastHole.size.value + 2.5}
                    {' '}
                    mm
                  </>
                )}
              </p>
            </>
          )}
        </>
      );
    };

    return (
      <div>
        <table className="table">
          <thead>
            <tr>
              <th colSpan={isPdf ? '7' : '5'}>KĄTY</th>
            </tr>
            <tr>
              <th className="responseThSingular">Lp.</th>
              <th className="responseThName">Nazwa</th>
              <th className="responseThMaterial">Materiał</th>
              <th className="responseThQuantity">Ilość</th>
              <th className="responseThMeasure">Wymiar</th>
              {isPdf && (
                <>
                  <th className="responseThSaws" aria-label="extraColumn" />
                  <th className="responseThD">KĄTY</th>
                </>
              )}
            </tr>
          </thead>
          <tbody>
            {angles}
          </tbody>
        </table>
        <table className="table">
          <thead>
            <tr>
              <th colSpan={isPdf ? 7 : 5}>LASER - MATERIAŁY DO WYPALENIA</th>
            </tr>
            <tr>
              <th className="responseThSingular">Lp.</th>
              <th className="responseThName">Nazwa</th>
              <th className="responseThMaterial">Materiał</th>
              <th className="responseThQuantity">Ilość</th>
              <th className="responseThMeasure">Wymiar</th>
              {isPdf && (
                <>
                  <th>{' '}</th>
                  <th className="responseThSaws">3D</th>
                </>
              )}
            </tr>
          </thead>
          <tbody>
            {laser}
          </tbody>
        </table>
        <table className="table">
          <thead>
            <tr>
              <th colSpan={isPdf ? '7' : '5'}>PIŁA - MATERIAŁY DO WYCIĘCIA</th>
            </tr>
            <tr>
              <th className="responseThSingular">Lp.</th>
              <th className="responseThName">Nazwa</th>
              <th className="responseThMaterial">Materiał</th>
              <th className="responseThQuantity">Ilość</th>
              <th className="responseThMeasure">Wymiar</th>
              {isPdf && (
                <>
                  <th className="responseThSaws">PIŁA</th>
                  <th className="responseThD">2D</th>
                </>
              )}
            </tr>
          </thead>
          <tbody>
            {saw}
          </tbody>
        </table>
        {palisade('leftWing')}
        {palisade('rightWing')}
        {!isPdf && <PS004bNonSymmetricalSecondPagePdf values={values} response={response} isPdf={false} />}
      </div>
    );
  }
  const angles = Object.keys(response.productionMaterials.angles).map((key, index) => (
    <tr key={key}>
      <td>{index + 1}</td>
      <td>{response.productionMaterials.angles[key].name}</td>
      <td>{response.productionMaterials.angles[key].material}</td>
      <td>
        {response.productionMaterials.angles[key].amount.value}
        {' '}
        {response.productionMaterials.angles[key].amount.unit}
      </td>
      <td>
        {response.productionMaterials.angles[key].size.value}
        {' '}
        {response.productionMaterials.angles[key].size.unit}
      </td>
      {isPdf && (
        <>
          <td />
          <td className="tdCenter">
            <div className="pdfSquare" />
          </td>
        </>
      )}
    </tr>
  ));

  const laser = Object.keys(response.productionMaterials.laser).map((key, index) => (
    <tr key={key}>
      <td>{index + 1}</td>
      <td>{response.productionMaterials.laser[key].name}</td>
      <td>{response.productionMaterials.laser[key].material}</td>
      <td>
        {response.productionMaterials.laser[key].amount.value}
        {' '}
        {response.productionMaterials.laser[key].amount.unit}
      </td>
      <td>
        {response.productionMaterials.laser[key].size.value}
        {' '}
        {response.productionMaterials.laser[key].size.unit}
      </td>
      {isPdf && (
        <>
          <td />
          <td className="tdCenter">
            <div className="pdfSquare" />
          </td>
        </>
      )}
    </tr>
  ));
  const saw = Object.keys(response.productionMaterials.saw).map((key, index) => (
    <tr key={key}>
      <td>{index + 1}</td>
      <td>{response.productionMaterials.saw[key].name}</td>
      <td>{response.productionMaterials.saw[key].material}</td>
      <td>
        {response.productionMaterials.saw[key].amount.value}
        {' '}
        {response.productionMaterials.saw[key].amount.unit}
      </td>
      <td>
        {response.productionMaterials.saw[key].size.value}
        {' '}
        {response.productionMaterials.saw[key].size.unit}
      </td>
      {isPdf && (
        <>
          <td className="tdCenter">
            <div className="pdfSquare" />
          </td>
          <td className="tdCenter">
            <div className="pdfSquare" />
          </td>
        </>
      )}
    </tr>
  ));

  const palisade = (wing) => {
    if (values.leftWingWidth < 3000 && values.rightWingWidth < 3000) {
      return (
        <>
          <table className="table">
            <thead>
              <tr>
                <th>
                  OTWORY POD PALISADĘ
                  { }
                  {wing === 'leftWing' ? ' - LEWE SKRZYDŁO' : ' - PRAWE SKRZYDŁO'}
                </th>
              </tr>
            </thead>
          </table>
          <p>
            {response.productionMaterials.palisadeHoles[wing]?.holeOne.name !== 'Nie dotyczy' && (
              <>
                <b>Otwór 1</b>
                {': '}
                {response.productionMaterials.palisadeHoles[wing].holeOne.size.value}
                {' '}
                mm
              </>
            )}
            {response.productionMaterials.palisadeHoles[wing].holeTwo.name !== 'Nie dotyczy' && (
              <>
                {' '}
                |
                {' '}
                <b>Otwór 2</b>
                {': '}
                {response.productionMaterials.palisadeHoles[wing].holeTwo.size.value}
                {' '}
                mm
              </>
            )}
            {response.productionMaterials.palisadeHoles[wing].holeThree.name !== 'Nie dotyczy' && (
              <>
                {' '}
                |
                {' '}
                <b>Otwór 3</b>
                {': '}
                {response.productionMaterials.palisadeHoles[wing].holeThree.size.value}
                {' '}
                mm
              </>
            )}
            {response.productionMaterials.palisadeHoles[wing].holeFour?.name !== 'Nie dotyczy' && (
              <>
                {' '}
                |
                {' '}
                <b>Otwór 4</b>
                {': '}
                {response.productionMaterials.palisadeHoles[wing].holeFour.size.value}
                {' '}
                mm
              </>
            )}
            {response.productionMaterials.palisadeHoles[wing].holeFive && response.productionMaterials.palisadeHoles[wing].holeFive?.name !== 'Nie dotyczy' && (
              <>
                {' '}
                |
                {' '}
                <b>Otwór 5</b>
                {': '}
                {response.productionMaterials.palisadeHoles[wing].holeFive.size.value}
                {' '}
                mm
              </>
            )}
            {response.productionMaterials.palisadeHoles[wing].holeSix && response.productionMaterials.palisadeHoles[wing].holeSix?.name !== 'Nie dotyczy' && (
              <>
                {' '}
                |
                {' '}
                <b>Otwór 6</b>
                {': '}
                {response.productionMaterials.palisadeHoles[wing].holeSix.size.value}
                {' '}
                mm
              </>
            )}
            {response.productionMaterials.palisadeHoles[wing].holeSeven && response.productionMaterials.palisadeHoles[wing].holeSeven?.name !== 'Nie dotyczy' && (
              <>
                {' '}
                |
                {' '}
                <b>Otwór 7</b>
                {': '}
                {response.productionMaterials.palisadeHoles[wing].holeSeven.size.value}
                {' '}
                mm
              </>
            )}
            {response.productionMaterials.palisadeHoles[wing].lastHole.name !== 'Nie dotyczy' && (
              <>
                {' '}
                |
                {' '}
                <b>{response.productionMaterials.palisadeHoles[wing].lastHole.name}</b>
                {': '}
                {response.productionMaterials.palisadeHoles[wing].lastHole.size.value}
                {' '}
                mm
              </>
            )}
          </p>
        </>
      );
    }
    return (
      <>
        <table className="table">
          <thead>
            <tr>
              <th>
                OTWORY POD PALISADĘ RAMA
                { }
                {wing === 'leftWing' ? ' - LEWE SKRZYDŁO' : ' - PRAWE SKRZYDŁO'}
              </th>
            </tr>
          </thead>
        </table>
        <p>
          {response.productionMaterials.palisadeHoles[wing]?.holeOne.name !== 'Nie dotyczy' && (
            <>
              <b>Otwór 1</b>
              {': '}
              {response.productionMaterials.palisadeHoles[wing].holeOne.size.value}
              {' '}
              mm
            </>
          )}
          {response.productionMaterials.palisadeHoles[wing].holeTwo.name !== 'Nie dotyczy' && (
            <>
              {' '}
              |
              {' '}
              <b>Otwór 2</b>
              {': '}
              {response.productionMaterials.palisadeHoles[wing].holeTwo.size.value}
              {' '}
              mm
            </>
          )}
          {response.productionMaterials.palisadeHoles[wing].holeThree.name !== 'Nie dotyczy' && (
            <>
              {' '}
              |
              {' '}
              <b>Otwór 3</b>
              {': '}
              {response.productionMaterials.palisadeHoles[wing].holeThree.size.value}
              {' '}
              mm
            </>
          )}
          {response.productionMaterials.palisadeHoles[wing].holeFour?.name !== 'Nie dotyczy' && (
            <>
              {' '}
              |
              {' '}
              <b>Otwór 4</b>
              {': '}
              {response.productionMaterials.palisadeHoles[wing].holeFour.size.value}
              {' '}
              mm
            </>
          )}
          {response.productionMaterials.palisadeHoles[wing].holeFive && response.productionMaterials.palisadeHoles[wing].holeFive?.name !== 'Nie dotyczy' && (
            <>
              {' '}
              |
              {' '}
              <b>Otwór 5</b>
              {': '}
              {response.productionMaterials.palisadeHoles[wing].holeFive.size.value}
              {' '}
              mm
            </>
          )}
          {response.productionMaterials.palisadeHoles[wing].holeSix && response.productionMaterials.palisadeHoles[wing].holeSix?.name !== 'Nie dotyczy' && (
            <>
              {' '}
              |
              {' '}
              <b>Otwór 6</b>
              {': '}
              {response.productionMaterials.palisadeHoles[wing].holeSix.size.value}
              {' '}
              mm
            </>
          )}
          {response.productionMaterials.palisadeHoles[wing].holeSeven && response.productionMaterials.palisadeHoles[wing].holeSeven?.name !== 'Nie dotyczy' && (
            <>
              {' '}
              |
              {' '}
              <b>Otwór 7</b>
              {': '}
              {response.productionMaterials.palisadeHoles[wing].holeSeven.size.value}
              {' '}
              mm
            </>
          )}
          {response.productionMaterials.palisadeHoles[wing].lastHole.name !== 'Nie dotyczy' && (
            <>
              {' '}
              |
              {' '}
              <b>{response.productionMaterials.palisadeHoles[wing].lastHole.name}</b>
              {': '}
              {response.productionMaterials.palisadeHoles[wing].lastHole.size.value}
              {' '}
              mm
            </>
          )}
        </p>
        {values.height > 999 && (
          <>
            <table className="table">
              <thead>
                <tr>
                  <th>
                    OTWORY POD PALISADĘ W DODATKOWYM POZIOMIE
                    {wing === 'leftWing' ? ' - LEWE SKRZYDŁO' : ' - PRAWE SKRZYDŁO'}
                  </th>
                </tr>
              </thead>
            </table>
            <p>
              <b>Otwór 1</b>
              {': '}
              {response.productionMaterials.railHoles[wing].holeOne.size.value + 2.5}
              {' '}
              mm
              {response.productionMaterials.railHoles[wing].holeTwo.name !== 'Nie dotyczy' && (
                <>
                  {' '}
                  |
                  {' '}
                  <b>Otwór 2</b>
                  {': '}
                  {response.productionMaterials.railHoles[wing].holeTwo.size.value + 2.5}
                  {' '}
                  mm
                </>
              )}
              {response.productionMaterials.railHoles[wing].holeThree.name !== 'Nie dotyczy' && (
                <>
                  {' '}
                  |
                  {' '}
                  <b>Otwór 3</b>
                  {': '}
                  {response.productionMaterials.railHoles[wing].holeThree.size.value + 2.5}
                  {' '}
                  mm
                </>
              )}
              {response.productionMaterials.railHoles[wing].holeFour.name !== 'Nie dotyczy' && (
                <>
                  {' '}
                  |
                  {' '}
                  <b>Otwór 4</b>
                  {': '}
                  {response.productionMaterials.railHoles[wing].holeFour.size.value + 2.5}
                  {' '}
                  mm
                </>
              )}
              {response.productionMaterials.railHoles[wing].lastHole.name !== 'Nie dotyczy' && (
                <>
                  {' '}
                  |
                  {' '}
                  <b>{response.productionMaterials.railHoles[wing].lastHole.name}</b>
                  {': '}
                  {response.productionMaterials.railHoles[wing].lastHole.size.value + 2.5}
                  {' '}
                  mm
                </>
              )}
            </p>
          </>
        )}
      </>
    );
  };

  return (
    <div>
      <table className="table">
        <thead>
          <tr>
            <th colSpan={isPdf ? '7' : '5'}>KĄTY</th>
          </tr>
          <tr>
            <th className="responseThSingular">Lp.</th>
            <th className="responseThName">Nazwa</th>
            <th className="responseThMaterial">Materiał</th>
            <th className="responseThQuantity">Ilość</th>
            <th className="responseThMeasure">Wymiar</th>
            {isPdf && (
              <>
                <th className="responseThSaws" aria-label="extraColumn" />
                <th className="responseThD">KĄTY</th>
              </>
            )}
          </tr>
        </thead>
        <tbody>
          {angles}
        </tbody>
      </table>
      <table className="table">
        <thead>
          <tr>
            <th colSpan={isPdf ? 7 : 5}>LASER - MATERIAŁY DO WYPALENIA</th>
          </tr>
          <tr>
            <th className="responseThSingular">Lp.</th>
            <th className="responseThName">Nazwa</th>
            <th className="responseThMaterial">Materiał</th>
            <th className="responseThQuantity">Ilość</th>
            <th className="responseThMeasure">Wymiar</th>
            {isPdf && (
              <>
                <th>{' '}</th>
                <th className="responseThSaws">3D</th>
              </>
            )}
          </tr>
        </thead>
        <tbody>
          {laser}
        </tbody>
      </table>
      <table className="table">
        <thead>
          <tr>
            <th colSpan={isPdf ? '7' : '5'}>PIŁA - MATERIAŁY DO WYCIĘCIA</th>
          </tr>
          <tr>
            <th className="responseThSingular">Lp.</th>
            <th className="responseThName">Nazwa</th>
            <th className="responseThMaterial">Materiał</th>
            <th className="responseThQuantity">Ilość</th>
            <th className="responseThMeasure">Wymiar</th>
            {isPdf && (
              <>
                <th className="responseThSaws">PIŁA</th>
                <th className="responseThD">2D</th>
              </>
            )}
          </tr>
        </thead>
        <tbody>
          {saw}
        </tbody>
      </table>
      {palisade('leftWing')}
      {palisade('rightWing')}
      {!isPdf && <PS004bNonSymmetricalSecondPagePdf values={values} response={response} isPdf={false} />}
    </div>
  );
};

GateGatewayResponse.propTypes = {
  response: PropTypes.object.isRequired,
};

export default GateGatewayResponse;
