import React from 'react';

export const ARCH_YES = 'yes';
export const ARCH_NO = 'no';

const Arch = ({ formId, onChange, value }) => (
  <div className="form-group">
    <label htmlFor={`arch__${formId}`}>Łuk</label>
    <select
      className="form-control"
      id={`arch__${formId}`}
      name="arch"
      value={value}
      onChange={onChange}
      required
    >
      <option value="" disabled>Wybierz</option>
      <option value={ARCH_YES}>TAK</option>
      <option value={ARCH_NO}>NIE</option>
    </select>
  </div>
);

export default Arch;
