import React from 'react';

export const RIVET_NUT_POLE_YES = 'TAK';
export const RIVET_NUT_POLE_NO = 'NIE';

const RivetNutPole = ({
  formId, onChange, value,
}) => (
  <div className="polesInput">
    <div className="form-group">
      <label htmlFor={`rivetNutPole__${formId}`}>Słup z nitonakrętką</label>
      <select
        className="form-control"
        id={`rivetNutPole__${formId}`}
        name="rivetNutPole"
        onChange={onChange}
        value={value}
        required
      >
        <option value="" disabled>Wybierz</option>
        <option value={RIVET_NUT_POLE_YES}>TAK</option>
        <option value={RIVET_NUT_POLE_NO}>NIE</option>
      </select>
    </div>
  </div>
);

export default RivetNutPole;
