import React from 'react';

export const LONG_HANDRAIL_YES = 'yes';
export const LONG_HANDRAIL_NO = 'no';

const LongHandrail = ({
  formId, onChange, value,
}) => (
  <div className="form-group">
    <label htmlFor={`longHandrail__${formId}`}>Pochwyt długi</label>
    <select
      className="form-control"
      id={`longHandrail__${formId}`}
      name="longHandrail"
      value={value || ''}
      onChange={onChange}
      required
    >
      <option value="" disabled>Wybierz</option>
      <option value={LONG_HANDRAIL_YES}>TAK</option>
      <option value={LONG_HANDRAIL_NO}>NIE</option>
    </select>
  </div>
);

export default LongHandrail;
