import React from 'react';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import invoke from '../../lambda';
import FormActions from '../Input/FormActions';
import SpanResponse from './SpanResponse';
import SpanReadySize from '../Input/SpanReadySize';

const Span = ({ form, updateForm }) => {
  const formik = useFormik({
    initialValues: {
      height: form.values.height || '',
      width: form.values.width || '',
      spanReadySize: form.values.spanReadySize || '',
    },
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);

      const res = await invoke({
        amount: form.quantity,
        model: 'PS004',
        type: 'span',
        ...values,
      });

      const newForm = {
        ...form,
        values: {
          ...values,
        },
        response: {
          ...res.products[0],
        },
      };

      setSubmitting(false);
      updateForm(newForm);
    },
    validate: ({ height, width }) => {
      const errors = {};

      if (!height) {
        errors.height = 'Wymagana wartość';
      } else if (height < 147) {
        errors.height = 'Minimalna wysokość to 147mm.';
      } else if (height > 3000) {
        errors.height = 'Maksymalna wysokość to 3000mm.';
      }
      if (!width) {
        errors.width = 'Wymagana wartość';
      } else if (width < 200) {
        errors.width = 'Minimalna szerokość to 200mm.';
      } else if (width > 3100) {
        errors.width = 'Maksymalna szerokość to 3100mm.';
      }

      return errors;
    },
  });
  const isResponseAvailable = Object.keys(form.response).length > 0;

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="formInputs">
        <div className="form-group">
          <label htmlFor={`height__${form.id}`}>Wysokość (mm)</label>
          <input
            type="number"
            className="form-control"
            placeholder="Wpisz"
            id={`height__${form.id}`}
            name="height"
            value={formik.values.height}
            autoComplete="off"
            onChange={formik.handleChange}
            required
          />
          <span className="error">{formik.errors.height && formik.errors.height}</span>
        </div>
        <div className="form-group">
          <label htmlFor={`width__${form.id}`}>Szerokość (mm)</label>
          <input
            type="number"
            className="form-control"
            placeholder="Wpisz"
            id={`width__${form.id}`}
            name="width"
            value={formik.values.width}
            autoComplete="off"
            onChange={formik.handleChange}
            required
          />
          <span className="error">{formik.errors.width && formik.errors.width}</span>
        </div>
        <SpanReadySize
          formId={form.id}
          onChange={formik.handleChange}
          value={formik.values.spanReadySize}
        />
      </div>
      <FormActions isSubmitting={formik.isSubmitting} />
      {form.response?.error && (
        <h2 style={{ color: 'red' }}>
          Wystąpił błąd:
          {' '}
          {form.response.error}
        </h2>
      )}
      {isResponseAvailable && !form.response?.error && <SpanResponse values={form.values} response={form.response} isPdf={false} />}
    </form>
  );
};

Span.propTypes = {
  form: PropTypes.object.isRequired,
  updateForm: PropTypes.func.isRequired,
};

export default Span;
