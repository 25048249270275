import React, { useState } from 'react';
import PropTypes from 'prop-types';

const OrderFormQuantity = ({ form, updateForm }) => {
  const [error, setError] = useState(null);
  if (!form.productSingular) {
    return null;
  }

  const handleChange = (e) => {
    e.preventDefault();

    if (form.quantity <= 0) {
      setError('Ilość musi być liczbą większą od 0.');
    } else {
      setError(null);
    }

    updateForm({
      ...form,
      quantity: parseInt(e.target.value),
    });
  };

  return (
    <div className="form-group orderFormFenceModel">
      <label htmlFor={`orderFormFenceModel__${form.id}`}>Podaj ilość</label>
      <input
        type="number"
        className="form-control"
        placeholder="Podaj ilość"
        id={`orderFormQuantity__${form.id}`}
        name="quantity"
        step={1}
        value={form.quantity || ''}
        onChange={handleChange}
        required
      />
      <small className="form-text text-muted error">{error}</small>
    </div>
  );
};

OrderFormQuantity.propTypes = {
  form: PropTypes.object.isRequired,
  updateForm: PropTypes.func.isRequired,
};

export default OrderFormQuantity;
