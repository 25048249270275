/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import './OrderForm.css';
import OrderFormVersionBtn from './OrderFormVersionBtn';

const OrderFormRecreated = ({
  header, materialsHtml, zamowieniaId, index,
}) => (
  <section className="container-xl raw">
    <div className="accordion">
      <div className="row">
        <div className="col">
          <div className="card">
            {window.location.href.includes('test.production.konsport') && <OrderFormVersionBtn />}
            {index === 0 && (
              <button type="button" className="zamowieniaBtn btn btn-success">
                <b>
                  Zamówienie nr
                  {' '}
                  {zamowieniaId}
                </b>
                <br />
              </button>
            )}
            {header}
            <article className="collapse show">
              <div className="card-body">
                <div className="container-xl">
                  <div className="row">
                    <div className="col">
                      {materialsHtml}
                    </div>
                  </div>
                </div>
              </div>
            </article>
          </div>
        </div>
      </div>
    </div>
  </section>
);

OrderFormRecreated.propTypes = {
  header: PropTypes.object.isRequired,
  materialsHtml: PropTypes.object.isRequired,
};

export default OrderFormRecreated;
