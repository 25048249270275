import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import {
  FENCE_TYPE_GATE_GATEWAY,
  FENCE_TYPE_GATE_SELF_SUPPORTING,
  FENCE_TYPE_GATE_TILTING,
  FENCE_TYPE_GATEWAY,
  FENCE_TYPE_NON_SYMMETRICAL_GATE,
  FENCE_TYPE_SINGLE_LEAF_GATE,
  FENCE_TYPE_SPAN,
  fencesTypesInPolish,
} from '../../FenceTypesConstants';
import OrderFormHeaderDeleteBtn from './OrderFormHeaderDeleteBtn';
import {
  MODEL_ECO, MODEL_HIGHWAY,
  MODEL_PP002P102,
  MODEL_PS004,
  MODEL_PS005,
  MODEL_VERTICALE, MODEL_VMS,
  MODEL_VERTICALE_LM,
  modelComponentsInPolish,
  MODEL_PS004_2,
  MODEL_PP002DIVERSO1,
  MODEL_PP002DIVERSO2,
  MODEL_PP002DIVERSO3,
  MODEL_P302,
  MODEL_P302_PB002,
  MODEL_P302_PS005,
  MODEL_PP002P82NOCE1_0,
  MODEL_PP002P82NOCE2_0,
  MODEL_PP002P82COLORE1_0,
  MODEL_PP002P82COLORE2_0,
  MODEL_PP002P82PINO1_0,
  MODEL_PP002P82PINO2_0,
  MODEL_VERTICALE_2,
  MODEL_VERTICALE_3,
  MODEL_SOLAR_PANEL_POLE,
  MODEL_TABLE_SOLAR_PANEL,
} from '../../ModelsConstants';
import { ARCH_YES } from '../Input/Arch';
import {
  BORDER_SPACING_SIZE,
  SPACING_SIZE,
  SPAN_SPACING_SIZE,
} from '../../ResponseConstants';
import { hingesInPolish } from '../Input/Hinges';
import { clampsInPolish } from '../Input/Clamp';
import { directionInPolish } from '../Input/Direction';
import { counterweightInPolish } from '../Input/Counterweight';
import { SLIDE_TOGETHER, slideInPolish } from '../Input/Slide';
import { colorsInPolish } from './OrderFormFenceColor';
import { spanReadySizeInPolish } from '../Input/SpanReadySize';

export const createFormTitle = (form) => {
  let title = '';
  let titleLineTwo = '';

  if (form.productSingular) {
    title += `# ${form.productSingular} `;
  }
  if (form.type) {
    if (`${fencesTypesInPolish[form.type]}` !== 'undefined') {
      title += ` | ${fencesTypesInPolish[form.type]} `.toUpperCase();
    }
  }
  if (form.values.gateDirection) {
    title += ` | ${form.values.gateDirection}`.toUpperCase();
  }
  if (form.model) {
    title += ` | ${modelComponentsInPolish[form.model]} `.toUpperCase();
  }
  if (form.color && (form.model !== MODEL_P302) && form.model !== MODEL_P302_PB002 && form.model !== MODEL_P302_PS005 && form.model !== MODEL_SOLAR_PANEL_POLE && form.model !== MODEL_TABLE_SOLAR_PANEL && form.model !== MODEL_PP002P82NOCE1_0
    && form.model !== MODEL_PP002P82NOCE2_0 && form.model !== MODEL_PP002P82COLORE1_0 && form.model !== MODEL_PP002P82COLORE2_0 && form.model !== MODEL_PP002P82PINO1_0 && form.model !== MODEL_PP002P82PINO2_0 && form.model !== MODEL_PS005
    && form.model !== MODEL_VERTICALE_2 && form.model !== MODEL_VERTICALE_3 && form.model !== MODEL_VERTICALE_LM) {
    title += ` | ${colorsInPolish[form.color]}`.toUpperCase();
  }
  if (form.values.arch === ARCH_YES) {
    title += '| ŁUK ';
  }
  if (form.values.height) {
    title += `| H ${form.values.height} mm`;
  }
  if (form.type !== 'gatewayAccessories' && form.type !== 'gateTiltingAccessories') {
    if (form.values.width) {
      title += ` L ${form.values.width} mm`;
    } else if (form.model === MODEL_VMS && form.type === FENCE_TYPE_GATEWAY) {
      title += ' L 1075';
    } else if (form.model === MODEL_ECO && form.type === FENCE_TYPE_GATEWAY) {
      title += ' L 1075';
    } else if ((form.type === FENCE_TYPE_GATE_GATEWAY || form.type === FENCE_TYPE_NON_SYMMETRICAL_GATE) && form.values.leftWingWidth && form.values.rightWingWidth) {
      title += ` L lewe skrzydło ${form.values.leftWingWidth} mm, L prawe skrzydło ${form.values.rightWingWidth} mm`;
    }
  }
  if (form.model === MODEL_PS005 && form.type !== FENCE_TYPE_SPAN && form.values.moduleHeight) {
    title += ` | MODUŁ H: ${form.values.moduleHeight} mm`;
  }
  if (form.quantity) {
    title += ` | ${form.quantity} szt`;
  }
  if (form.model === MODEL_PP002P102 && form.type === FENCE_TYPE_SPAN && form.response[SPAN_SPACING_SIZE]) {
    title += ` | Rozstaw: ${form.response[SPAN_SPACING_SIZE]} mm`;
  }
  if (form.response[SPACING_SIZE]) {
    title += ` | Rozstaw: ${form.response[SPACING_SIZE]} mm`;
  }
  if (form.model === MODEL_PP002DIVERSO1 || form.model === MODEL_PP002DIVERSO3) {
    title += ' | Rozstaw: 15 mm';
  }
  if (form.model === MODEL_PP002DIVERSO2) {
    title += ' | Rozstaw: 40 mm';
  }
  if (form.response.productionMaterials?.spacing) {
    if (form.response.productionMaterials.spacing?.size) {
      if (form.model === MODEL_VERTICALE && form.type === FENCE_TYPE_GATEWAY) {
        title += ` | Rozstaw krańcowy: ${form.response.productionMaterials.spacing.size.value} mm`;
      } else {
        title += ` | Rozstaw: ${form.response.productionMaterials.spacing.size.value} mm`;
      }
    } else {
      title += ` | Rozstaw: ${Math.round(form.response.productionMaterials.spacing * 100) / 100} mm`;
    }
  }
  if (form.type === FENCE_TYPE_NON_SYMMETRICAL_GATE && form.response.productionMaterials?.plank?.spacing) {
    titleLineTwo += `Rozstaw: ${form.response.productionMaterials.plank.spacing.size.value} mm`;
  }
  if (form.type === FENCE_TYPE_NON_SYMMETRICAL_GATE && form.response.productionMaterials?.studding?.spacing) {
    titleLineTwo += `Rozstaw: ${form.response.productionMaterials.studding.spacing.size.value} mm`;
  }

  if ((form.type === FENCE_TYPE_GATE_GATEWAY || (form.type === FENCE_TYPE_GATE_TILTING && form.values.width >= 6000)) && form.response.productionMaterials?.plank?.spacing) {
    title += ` | Rozstaw: ${form.response.productionMaterials.plank.spacing.size.value} mm`;
  }
  if ((form.type === FENCE_TYPE_GATE_GATEWAY || (form.type === FENCE_TYPE_GATE_TILTING && form.values.width >= 6000)) && form.response.productionMaterials?.studding?.spacing) {
    titleLineTwo += `Rozstaw: ${form.response.productionMaterials.studding.spacing.size.value} mm`;
  }

  if (form.model !== MODEL_VERTICALE_LM && ((form.type === FENCE_TYPE_GATEWAY && form.model !== MODEL_HIGHWAY) || (form.type === FENCE_TYPE_SINGLE_LEAF_GATE && form.values.width && form.values.width < 3000) || (form.type === FENCE_TYPE_GATE_TILTING && form.values.hinges && form.model !== MODEL_HIGHWAY))) {
    title += ` | Zawiasy: ${hingesInPolish[form.values.hinges]}`;
  }

  if (form.model !== MODEL_VERTICALE_LM && (form.type === FENCE_TYPE_GATE_GATEWAY || form.type === FENCE_TYPE_NON_SYMMETRICAL_GATE) && form.values.hinges) {
    titleLineTwo += ` | Zawiasy: ${hingesInPolish[form.values.hinges]}`;
  }

  if (form.model !== MODEL_VERTICALE_LM && form.type === FENCE_TYPE_SINGLE_LEAF_GATE && form.values.width && form.values.width >= 3000 && form.values.hinges) {
    titleLineTwo += `Zawiasy: ${hingesInPolish[form.values.hinges]}`;
  }

  if (form.type === FENCE_TYPE_SPAN && form.values.clamp) {
    title += ` | Uchwyt montażowy: ${clampsInPolish[form.values.clamp]}`;
  }
  if (form.model === MODEL_VERTICALE && form.response[BORDER_SPACING_SIZE]) {
    title += ` | Rozstaw krańcowy: ${form.response[BORDER_SPACING_SIZE]} mm`;
  }
  if (form.type === FENCE_TYPE_GATE_SELF_SUPPORTING && form.values.counterweight) {
    title += ` | Przeciwwaga: ${counterweightInPolish[form.values.counterweight]}`;
  }
  if (form.values.fullLength) {
    title += ` | Całkowita długość bramy: ${form.values.fullLength} mm`;
  }
  if (form.type === FENCE_TYPE_GATE_SELF_SUPPORTING && form.values.slide === SLIDE_TOGETHER) {
    title += ` | ${slideInPolish[form.values.slide]}`;
  }
  if (form.model === MODEL_PS004 && form.type === FENCE_TYPE_SPAN && form.values.spanReadySize) {
    title += ` | Wymiar na gotowo: ${spanReadySizeInPolish[form.values.spanReadySize]}`;
  }
  if (form.model === MODEL_PS004_2 && form.type === FENCE_TYPE_GATE_TILTING && form.response.productionMaterials?.spacing) {
    title += ` | Rozstaw: ${form.response.productionMaterials.spacing.size.value} mm`;
  }
  if (form.type === FENCE_TYPE_SINGLE_LEAF_GATE && form.values.direction) {
    title += ` | Kierunek: ${directionInPolish[form.values.direction]}`;
  }

  if (form.model === MODEL_VERTICALE_LM) {
    if (form.type === 'gatewayAccessories' || form.type === 'gateTiltingAccessories') {
      title += ` | Pudełko zestaw nr ${form.values.accessories}`;
    }
  }

  return (
    <span>
      {title}
      <br />
      {titleLineTwo}
    </span>
  );
};

const OrderFormHeader = ({ form, updateForm }) => {
  const toggle = (e) => {
    e.preventDefault();

    updateForm({ ...form, show: !form.show });
  };

  const Icon = () => (form.show ? <FontAwesomeIcon icon={faAngleUp} size="2x" /> : <FontAwesomeIcon icon={faAngleDown} size="2x" />);

  const title = createFormTitle(form);

  return (
    <article className="card-header">
      <h2 className="mb-0 orderFormTitle">
        <button className="btn btn-link" type="button" onClick={toggle} tabIndex={-1}>
          <span>{title}</span>
          <Icon />
        </button>
      </h2>
      <OrderFormHeaderDeleteBtn id={form.id} title={title} />
    </article>
  );
};

OrderFormHeader.propTypes = {
  form: PropTypes.object.isRequired,
  updateForm: PropTypes.func.isRequired,
};

export default OrderFormHeader;
