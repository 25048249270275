import React from 'react';

export const DIRECTION_PROPERTY = 'property';
export const DIRECTION_STREET = 'street';

export const directionInPolish = {
  [DIRECTION_PROPERTY]: 'Posesja',
  [DIRECTION_STREET]: 'Ulica',
};

const Direction = ({ formId, onChange, value }) => (
  <div className="form-group">
    <label htmlFor={`direction__${formId}`}>Kierunek otwierania</label>
    <select
      className="form-control"
      id={`direction__${formId}`}
      onChange={onChange}
      name="direction"
      value={value}
      required
    >
      <option value="" disabled>Wybierz</option>
      <option value={DIRECTION_PROPERTY}>Posesja</option>
      <option value={DIRECTION_STREET}>Ulica</option>
    </select>
  </div>
);

export default Direction;
