import { connect } from 'react-redux';
import PdfBtn from '../../components/Pdf/PdfBtn';

const mapStateToProps = (state) => ({
  forms: state.app.forms,
  orderNumber: state.app.orderNumber,
});

export default connect(
  mapStateToProps,
)(PdfBtn);
