import React from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import Poles, { POLES_LACK } from '../Input/Poles';
import Holder, {
  HOLDER_FORM_NAME,
  HOLDER_NO,
} from '../Input/Holder';
import FormActions from '../Input/FormActions';
import { FENCE_TYPE_GATEWAY_WITH_MACHINE } from '../../FenceTypesConstants';
import invoke from '../../lambda';
import GatewayWithMachineResponse from './GatewayWithMachineResponse';

const GatewayWithMachine = ({ form, updateForm }) => {
  const initialValues = {
    height: form.values.height || '',
    width: form.values.width || '',
    poles: form.values.poles || '',
    holder: form.values.holder || '',
  };

  const isResponseAvailable = Object.keys(form.response).length > 0;

  const polesOnChange = (e, handleChange, setFieldValue) => {
    handleChange(e);

    if (e.target.value === POLES_LACK) {
      setFieldValue(HOLDER_FORM_NAME, HOLDER_NO);
    }
  };

  const onSubmit = async (props, { setSubmitting }) => {
    setSubmitting(true);

    const res = await invoke({
      amount: form.quantity,
      model: 'PS004',
      type: 'furtka_automat',
      ...props,
    });

    const newForm = {
      ...form,
      values: {
        height: props.height,
        width: props.width,
        poles: props.poles,
        holder: props.holder,
      },
      response: {
        ...res.products[0],
      },
    };

    setSubmitting(false);
    updateForm(newForm);
  };

  const validation = ({ height, width }) => {
    const errors = {};

    if (!height) {
      errors.height = 'Wymagana wartość';
    } else if (height < 1450) {
      errors.height = 'Minimalna wysokość to 1450mm.';
    } else if (height > 2300) {
      errors.height = 'Maksymalna wysokość to 2300mm.';
    }
    if (!width) {
      errors.width = 'Wymagana wartość';
    } else if (width < 500) {
      errors.width = 'Minimalna szerokość to 500mm.';
    } else if (width > 1270) {
      errors.width = 'Maksymalna szerokość to 1270mm.';
    }
    return errors;
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validate={validation}
    >
      {({
        values,
        errors,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
      }) => (
        <form onSubmit={handleSubmit}>
          <div className="formInputs">
            <div className="form-group">
              <label htmlFor={`height__${form.id}`}>Wysokość (mm)</label>
              <input
                type="number"
                className="form-control"
                placeholder="Wpisz"
                id={`height__${form.id}`}
                name="height"
                value={values.height}
                autoComplete="off"
                onChange={handleChange}
                required
              />
              <span className="error">{errors.height && errors.height}</span>
            </div>
            <div className="form-group">
              <label htmlFor={`width__${form.id}`}>Szerokość (mm)</label>
              <input
                type="number"
                className="form-control"
                placeholder="Wpisz"
                id={`width__${form.id}`}
                name="width"
                value={values.width}
                autoComplete="off"
                onChange={handleChange}
                required
              />
              <span className="error">{errors.width && errors.width}</span>
            </div>
            <Poles
              formId={form.id}
              onChange={(e) => polesOnChange(e, handleChange, setFieldValue)}
              fenceType={FENCE_TYPE_GATEWAY_WITH_MACHINE}
              value={values.poles}
            />
            <Holder
              formId={form.id}
              onChange={handleChange}
              poles={values.poles}
              value={values.holder}
            />
          </div>
          <FormActions isSubmitting={isSubmitting} />
          {form.response?.error && (
            <h2 style={{ color: 'red' }}>
              Wystąpił błąd:
              {' '}
              {form.response.error}
            </h2>
          )}
          {isResponseAvailable && !form.response?.error && <GatewayWithMachineResponse response={form.response} isPdf={false} />}
        </form>
      )}
    </Formik>
  );
};

GatewayWithMachine.propTypes = {
  form: PropTypes.object.isRequired,
  updateForm: PropTypes.func.isRequired,
};

export default GatewayWithMachine;
