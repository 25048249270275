export const ADD_NEXT_FORM = 'ADD_NEXT_FORM';
export const UPDATE_FORM = 'UPDATE_FORM';
export const DELETE_FORM = 'DELETE_FORM';

export const addNextForm = (form) => ({ type: ADD_NEXT_FORM, form });
export const updateForm = (form) => ({ type: UPDATE_FORM, form });

export const SET_ORDER_NUMBER = 'SET_ORDER_NUMBER';
export const SET_GENERAL_COMMENT = 'SET_GENERAL_COMMENT';
export const SET_ZAMOWIENIE = 'SET_ZAMOWIENIE';

export const setOrderNumber = (orderNumber) => ({ type: SET_ORDER_NUMBER, orderNumber });
export const setZamowienie = (products) => ({ type: SET_ZAMOWIENIE, products });
