import React, { useState } from 'react';
import PropTypes from 'prop-types';

const OrderFormComment = ({ form, updateForm }) => {
  const [savingInfo, setSavingInfo] = useState('');

  const isResponseAvailable = Object.keys(form.response).length > 0;
  if (!isResponseAvailable) {
    return null;
  }

  const change = (e) => {
    e.preventDefault();

    setSavingInfo('Zapisuję . . .');

    updateForm({
      ...form,
      comments: e.target.value,
    });

    setSavingInfo('Zapisano.');
    setTimeout(() => {
      setSavingInfo('');
    }, 3000);
  };

  return (
    <div className="form-group orderFormComment">
      <label htmlFor={`orderFormComment__${form.id}`}>Komentarz</label>
      <textarea
        className="form-control"
        id={`orderFormComment__${form.id}`}
        rows={3}
        onChange={change}
      >
        {form.comments}
      </textarea>
      <small className="form-text text-muted">{savingInfo}</small>
    </div>
  );
};

OrderFormComment.propTypes = {
  form: PropTypes.object.isRequired,
  updateForm: PropTypes.func.isRequired,
};

export default OrderFormComment;
