import React from 'react';

export const COUNTERWEIGHT_STANDARD = 'standard';
export const COUNTERWEIGHT_NON_STANDARD = 'non-standard';
export const COUNTERWEIGHT_FORM_NAME = 'counterweight';
export const COUNTERWEIGHT_40 = '40';
export const COUNTERWEIGHT_30 = '30';

export const counterweightInPolish = {
  [COUNTERWEIGHT_STANDARD]: 'Standard',
  [COUNTERWEIGHT_NON_STANDARD]: 'Niestandardowa',
  [COUNTERWEIGHT_40]: '40%',
  [COUNTERWEIGHT_30]: '30%',
};

const Counterweight = ({ formId, onChange, value }) => (
  <div className="form-group">
    <label htmlFor={`counterweight__${formId}}`}>Przeciwwaga</label>
    <select
      className="form-control"
      id={`counterweight__${formId}`}
      name={COUNTERWEIGHT_FORM_NAME}
      onChange={onChange}
      value={value}
      required
    >
      <option value="" disabled>Wybierz</option>
      <option value={COUNTERWEIGHT_STANDARD}>Standard</option>
      <option value={COUNTERWEIGHT_NON_STANDARD}>Niestandardowa</option>
      <option value={COUNTERWEIGHT_40}>40%</option>
      <option value={COUNTERWEIGHT_30}>30%</option>
    </select>
  </div>
);

export default Counterweight;
