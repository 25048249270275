import React from 'react';

export const MACHINE_YES = 'yes';
export const MACHINE_NO = 'no';
export const MACHINE_FORM_NAME = 'machine';

const Machine = ({
  formId, onChange, machineOption = false, height = null, value,
}) => {
  if (machineOption) {
    if (height < 1000) {
      return null;
    }
  }

  return (
    <div className="form-group">
      <label htmlFor={`machine__${formId}`}>Automat</label>
      <select
        className="form-control"
        id={`machine__${formId}`}
        name="machine"
        value={value || ''}
        onChange={onChange}
        required
      >
        <option value="" disabled>Wybierz</option>
        <option value={MACHINE_YES}>TAK</option>
        <option value={MACHINE_NO}>NIE</option>
      </select>
    </div>
  );
};

export default Machine;
