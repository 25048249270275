import React from 'react';
import PropTypes from 'prop-types';
import {
  models,
  modelAndModelComponents,
  modelComponentsInPolish,
} from '../../ModelsConstants';

const OrderFormFenceModel = ({ form, updateForm, suggestedModel = null }) => {
  if (!form.quantity) {
    return null;
  }

  if (suggestedModel && !form.model) {
    updateForm({
      ...form,
      model: suggestedModel,
      type: null,
      values: {},
      response: {},
    });
  }

  const handleChange = (e) => {
    e.preventDefault();

    if (e.target.value === 'SOLAR_PANEL_POLE' || e.target.value === 'TABLE_SOLAR_PANEL' || e.target.value === 'EQ') {
      updateForm({
        ...form,
        model: e.target.value,
        type: e.target.value,
        values: {},
        response: {},
      });
    } else {
      // we need to clean up values and response, because it's different form
      updateForm({
        ...form,
        model: e.target.value,
        type: null,
        color: null,
        values: {},
        response: {},
      });
    }
  };

  const fenceModelOptions = models.map((model) => <option key={modelAndModelComponents[model]} value={modelAndModelComponents[model]}>{modelComponentsInPolish[modelAndModelComponents[model]]}</option>);

  return (
    <div className="form-group orderFormFenceModel">
      <label htmlFor={`orderFormFenceModel__${form.id}`}>Wybierz model ogrodzenia</label>
      <select
        className="form-control"
        id={`orderFormFenceModel__${form.id}`}
        name="fenceModel"
        onChange={handleChange}
        value={form.model || suggestedModel || ''}
        required
      >
        <option value="" disabled>Wybierz model ogrodzenia</option>
        {fenceModelOptions}
      </select>
    </div>
  );
};

OrderFormFenceModel.propTypes = {
  form: PropTypes.object.isRequired,
  updateForm: PropTypes.func.isRequired,
};

export default OrderFormFenceModel;
