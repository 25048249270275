import React from 'react';
import PropTypes from 'prop-types';

const GatewayWithMachineResponse = ({ response, isPdf }) => {
  const angles = Object.keys(response.productionMaterials.angles).map((key, index) => (
    <tr key={key}>
      <td>{index + 1}</td>
      <td>{response.productionMaterials.angles[key].name}</td>
      <td>{response.productionMaterials.angles[key].material}</td>
      <td>
        {response.productionMaterials.angles[key].amount.value}
        {' '}
        {response.productionMaterials.angles[key].amount.unit}
      </td>
      <td>
        {response.productionMaterials.angles[key].size.value}
        {' '}
        {response.productionMaterials.angles[key].size.unit}
      </td>
      {isPdf && (
        <>
          <td />
          <td className="tdCenter">
            <div className="pdfSquare" />
          </td>
        </>
      )}
    </tr>
  ));

  const laser = Object.keys(response.productionMaterials.laser).map((key, index) => {
    if (key !== 'palisadeHoles') {
      return (
        <tr key={key}>
          <td>{index + 1}</td>
          <td>{response.productionMaterials.laser[key].name}</td>
          <td>{response.productionMaterials.laser[key].material}</td>
          <td>
            {response.productionMaterials.laser[key].amount.value}
            {' '}
            {response.productionMaterials.laser[key].amount.unit}
          </td>
          <td>
            {response.productionMaterials.laser[key].size.value}
            {' '}
            {response.productionMaterials.laser[key].size.unit}
          </td>
          {isPdf && (
            <>
              <td />
              <td className="tdCenter">
                <div className="pdfSquare" />
              </td>
            </>
          )}
        </tr>
      );
    }
    return null;
  });

  const saw = Object.keys(response.productionMaterials.saw).map((key, index) => (
    <tr key={key}>
      <td>{index + 1}</td>
      <td>{response.productionMaterials.saw[key].name}</td>
      <td>{response.productionMaterials.saw[key].material}</td>
      <td>
        {response.productionMaterials.saw[key].amount.value}
        {' '}
        {response.productionMaterials.saw[key].amount.unit}
      </td>
      <td>
        {response.productionMaterials.saw[key].size.value}
        {' '}
        {response.productionMaterials.saw[key].size.unit}
      </td>
      {isPdf && (
        <>
          <td className="tdCenter">
            <div className="pdfSquare" />
          </td>
          <td className="tdCenter">
            <div className="pdfSquare" />
          </td>
        </>
      )}
    </tr>
  ));

  const railing = Object.keys(response.productionMaterials.railing).map((key, index) => (
    <tr key={key}>
      <td>{index + 1}</td>
      <td>{response.productionMaterials.railing[key].name}</td>
      <td>{response.productionMaterials.railing[key].material}</td>
      <td>
        {response.productionMaterials.railing[key].amount.value}
        {' '}
        {response.productionMaterials.railing[key].amount.unit}
      </td>
      <td>
        {response.productionMaterials.railing[key].size.value}
        {' '}
        {response.productionMaterials.railing[key].size.unit}
      </td>
      {isPdf && (
        <>
          <td className="tdCenter">
            <div className="pdfSquare" />
          </td>
          <td />
        </>
      )}
    </tr>
  ));

  return (
    <div>
      <table className="table">
        <thead>
          <tr>
            <th colSpan={isPdf ? '7' : '5'}>KĄTY</th>
          </tr>
          <tr>
            <th className="responseThSingular">Lp.</th>
            <th className="responseThName">Nazwa</th>
            <th className="responseThMaterial">Materiał</th>
            <th className="responseThQuantity">Ilość</th>
            <th className="responseThMeasure">Wymiar</th>
            {isPdf && (
              <>
                <th className="responseThSaws" aria-label="extraColumn" />
                <th className="responseThD">KĄTY</th>
              </>
            )}
          </tr>
        </thead>
        <tbody>
          {angles}
        </tbody>
      </table>
      <table className="table">
        <thead>
          <tr>
            <th colSpan={isPdf ? '7' : '5'}>LASER - MATERIAŁY DO WYPALENIA</th>
          </tr>
          <tr>
            <th className="responseThSingular">Lp.</th>
            <th className="responseThName">Nazwa</th>
            <th className="responseThMaterial">Materiał</th>
            <th className="responseThQuantity">Ilość</th>
            <th className="responseThMeasure">Wymiar</th>
            {isPdf && (
              <>
                <th className="responseThD">3D</th>
                <th className="responseThSaws" aria-label="extraColumn" />
              </>
            )}
          </tr>
        </thead>
        <tbody>
          {laser}
        </tbody>
      </table>
      <table className="table">
        <thead>
          <tr>
            <th colSpan={isPdf ? '7' : '5'}>PIŁA - MATERIAŁY DO WYCIĘCIA</th>
          </tr>
          <tr>
            <th className="responseThSingular">Lp.</th>
            <th className="responseThName">Nazwa</th>
            <th className="responseThMaterial">Materiał</th>
            <th className="responseThQuantity">Ilość</th>
            <th className="responseThMeasure">Wymiar</th>
            {isPdf && (
              <>
                <th className="responseThSaws">PIŁA</th>
                <th className="responseThD">2D</th>
              </>
            )}
          </tr>
        </thead>
        <tbody>
          {saw}
        </tbody>
      </table>
      <table className="table">
        <thead>
          <tr>
            <th>OTWORY POD PALISADĘ</th>
          </tr>
        </thead>
      </table>
      <p>
        <b>{response.productionMaterials.palisadeHoles.holeOne.name}</b>
        {': '}
        {response.productionMaterials.palisadeHoles.holeOne.size.value}
        {' '}
        mm
        {response.productionMaterials.palisadeHoles.holeTwo.name !== 'Nie dotyczy' && (
          <>
            {' '}
            |
            {' '}
            <b>{response.productionMaterials.palisadeHoles.holeTwo.name}</b>
            {': '}
            {response.productionMaterials.palisadeHoles.holeTwo.size.value}
            {' '}
            mm
          </>
        )}
        {response.productionMaterials.palisadeHoles.lastHole.name !== 'Nie dotyczy' && (
          <>
            {' '}
            |
            {' '}
            <b>{response.productionMaterials.palisadeHoles.lastHole.name}</b>
            {': '}
            {response.productionMaterials.palisadeHoles.lastHole.size.value}
            {' '}
            mm
          </>
        )}
      </p>
      <table className="table">
        <thead>
          <tr>
            <th colSpan={isPdf ? '7' : '5'}>SZTACHETY</th>
          </tr>
          <tr>
            <th className="responseThSingular">Lp.</th>
            <th className="responseThName">Nazwa</th>
            <th className="responseThMaterial">Materiał</th>
            <th className="responseThQuantity">Ilość</th>
            <th className="responseThMeasure">Wymiar</th>
            {isPdf && (
              <>
                <th className="responseThD">SZTACHETY</th>
                <th className="responseThSaws" aria-label="extraColumn" />
              </>
            )}
          </tr>
        </thead>
        <tbody>
          {railing}
        </tbody>
      </table>
      <table className="table">
        <thead>
          <tr>
            <th>OTWORY POD SZTACHETĘ</th>
          </tr>
        </thead>
      </table>
      <p>
        <b>{response.productionMaterials.railHoles.holeOne.name}</b>
        {': '}
        {response.productionMaterials.railHoles.holeOne.size.value}
        {' '}
        mm
        {response.productionMaterials.railHoles.holeTwo.name !== 'Nie dotyczy' && (
          <>
            {' '}
            |
            {' '}
            <b>{response.productionMaterials.railHoles.holeTwo.name}</b>
            {': '}
            {response.productionMaterials.railHoles.holeTwo.size.value}
            {' '}
            mm
          </>
        )}
        {response.productionMaterials.railHoles.lastHole.name !== 'Nie dotyczy' && (
          <>
            {' '}
            |
            {' '}
            <b>{response.productionMaterials.railHoles.lastHole.name}</b>
            {': '}
            {response.productionMaterials.railHoles.lastHole.size.value}
            {' '}
            mm
          </>
        )}
      </p>
    </div>
  );
};

GatewayWithMachineResponse.propTypes = {
  response: PropTypes.object.isRequired,
};

export default GatewayWithMachineResponse;
