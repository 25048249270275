import React from 'react';

const Comments = ({ form }) => {
  if (!form.comments) {
    return null;
  }

  return (
    <div className="comments">
      <h2>Komentarz</h2>
      <p>{form.comments}</p>
    </div>
  );
};

export default Comments;
