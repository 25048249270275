import { connect } from 'react-redux';
import GettingStarted from '../components/GettingStarted';
import { addNextForm, setOrderNumber, setZamowienie } from '../actions';

const mapStateToProps = (state) => ({
  orderNumber: state.app.orderNumber,
});

const mapDispatchToProps = (dispatch) => ({
  addNextForm: (form) => dispatch(addNextForm(form)),
  setOrderNumber: (orderNumber) => dispatch(setOrderNumber(orderNumber)),
  setZamowienie: (products) => dispatch(setZamowienie(products)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GettingStarted);
