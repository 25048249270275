import React from 'react';
import PropTypes from 'prop-types';
import Gateway from './Gateway';
import GateTilting from './GateTilting';
import GateSelfSupporting from './GateSelfSupporting';
import Span from './Span';
import FoldingGate from './FoldingGate';
import SingleLeafFoldingGate from './SingleLeafFoldingGate';
import SingleLeafGate from './SingleLeafGate';
import GateGateway from './GateGateway';
import NonSymmetricalGate from './NonSymmetricalGate';

const PP002P64 = ({ form, addNextForm, updateForm }) => {
  const FormComponent = () => {
    // TODO try to dynamically render type, to avoid switch case
    switch (form.type) {
      case 'gateway':
        return <Gateway form={form} addNextForm={addNextForm} updateForm={updateForm} />;
      case 'gateGateway':
        return <GateGateway form={form} addNextForm={addNextForm} updateForm={updateForm} />;
      case 'nonSymmetricalGate':
        return <NonSymmetricalGate form={form} addNextForm={addNextForm} updateForm={updateForm} />;
      case 'singleLeafGate':
        return <SingleLeafGate form={form} addNextForm={addNextForm} updateForm={updateForm} />;
      case 'gateTilting':
        return <GateTilting form={form} addNextForm={addNextForm} updateForm={updateForm} />;
      case 'foldingGate':
        return <FoldingGate form={form} addNextForm={addNextForm} updateForm={updateForm} />;
      case 'singleLeafFoldingGate':
        return <SingleLeafFoldingGate form={form} addNextForm={addNextForm} updateForm={updateForm} />;
      case 'gateSelfSupporting':
        return <GateSelfSupporting form={form} addNextForm={addNextForm} updateForm={updateForm} />;
      case 'span':
        return <Span form={form} addNextForm={addNextForm} updateForm={updateForm} />;
      default:
        throw new Error(`Unknown type (${form.type}).`);
    }
  };

  return (
    <section>
      <FormComponent />
    </section>
  );
};

PP002P64.propTypes = {
  form: PropTypes.object.isRequired,

  updateForm: PropTypes.func.isRequired,
};

export default PP002P64;
