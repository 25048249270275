import styled from 'styled-components';

export const Page = styled.div`
  page-break-before: always;
  box-shadow: 0 0 15px 3px #343a40;
  margin: 2rem 0;
  padding: 2rem;

  @media print {
    box-shadow: none;
    margin: 0;
    padding: 0;
  }
`;

export const PdfSquare = styled.div`
  display: block;
  height: 25px;
  width: 25px;
  border: black 2px solid;
  margin-left: auto;
  margin-right: auto;
`;

export const Illustration = styled.div`
  height: 100px;
  width: 120px;
  margin-top: -100px;
  margin-left: 60%;
`;

export const IllustrationSquareGate = styled.div`
  height: 75px;
  width: 90px;
  border: black 2px solid;
`;

export const IllustrationSymbolH = styled.div`
  font-size: 1rem;
  margin-left: 95px;
  margin-top: -50px;
`;

export const IllustrationSymbolL = styled.div`
  font-size: 1rem;
  margin-left: 40px;
  padding-top: 3px;
`;

export const AllSignatures = styled.div`
  margin-top: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const SignatureFirstColumn = styled.div`
  & > div > p {
    border-bottom: 2px solid #000;
    margin-bottom: 2rem;
    width: 400px;
  }
`;

export const SignatureSecondColumn = styled.div`
  & > div > p {
    border-bottom: 2px solid #000;
    margin-bottom: 2rem;
    width: 400px;
  }
`;

export const SquareColumnHeader = styled.th`
  text-align: center;
`;

export const LpColumnHeader = styled.th`
  width: 30px;
`;

export const CustomLpColumnHeader = styled.th`
  width: 200px;
`;

export const NameColumnHeader = styled.th`
  width: 275px;
`;

export const MaterialColumnHeader = styled.th`
  width: 220px;
`;

export const AmountColumnHeader = styled.th`
  width: 135px;
`;
