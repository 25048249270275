import React from 'react';

const Spacing = ({
  formId, onChange, value, name, label,
}) => (
  <div className="form-group">
    <label htmlFor={`${name}__${formId}`}>{label}</label>
    <input
      type="number"
      className="form-control"
      placeholder="Wpisz"
      id={`${name}__${formId}`}
      name={name}
      value={value}
      onChange={onChange}
      autoComplete="off"
      required
    />
  </div>
);

export default Spacing;
