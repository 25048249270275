/* eslint-disable camelcase */
import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import Poles, {
  POLES_LACK,
} from '../Input/Poles';
import RivetNutPole from '../Input/RivetNutPole';
import MaskingRod from '../Input/MaskingRod';
import Holder, {
  HOLDER_FORM_NAME,
  HOLDER_NO,
} from '../Input/Holder';
import FormActions from '../Input/FormActions';
import FoldingGateResponse from './FoldingGateResponse';
import CoverBetweenWings from '../Input/CoverBetweenWings';
import MountingStrip, { MOUNTING_STRIP_ZERO } from '../Input/MountingStrip';
import { FENCE_TYPE_FOLDING_GATE } from '../../FenceTypesConstants';
import invoke from '../../lambda';

const FoldingGate = ({ form, updateForm }) => {
  const formik = useFormik({
    initialValues: {
      height: form.values.height || '',
      width: form.values.width || '',
      poles: form.values.poles || '',
      rivetNutPole: form.values.rivetNutPole || '',
      holder: form.values.holder || '',
      coverBetweenWings: form.values.coverBetweenWings || '',
      mountingStrip: form.values.mountingStrip || '',
      maskingRod: form.values.maskingRod || '',
    },
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);

      const res = await invoke({
        amount: form.quantity,
        model: 'PP002(P82_0)',
        type: 'folding_gate',
        ...values,
      });

      const newForm = {
        ...form,
        values: {
          ...values,
        },
        response: {
          ...res.products[0],
        },
      };
      setSubmitting(false);
      updateForm(newForm);
    },
    validate: ({
      width,
    }) => {
      const errors = {};

      if (!width) {
        errors.width = 'Wymagana wartość';
      } else if (width < 1000) {
        errors.width = 'Minimalna szerokość to 1000mm.';
      } else if (width > 5999) {
        errors.width = 'Maksymalna szerokość to 5999mm.';
      }

      return errors;
    },
  });
  useEffect(() => {
    if (formik.values.poles === POLES_LACK) {
      formik.setFieldValue(HOLDER_FORM_NAME, HOLDER_NO);
    }
    formik.setFieldValue('mountingStrip', MOUNTING_STRIP_ZERO);
  }, [formik.values.poles]);
  const isResponseAvailable = Object.keys(form.response).length > 0;

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="formInputs">
        <div className="form-group">
          <label htmlFor={`height__${form.id}`}>Wysokość (mm)</label>
          <select
            className="form-control"
            placeholder="Wpisz"
            id={`height__${form.id}`}
            name="height"
            value={formik.values.height}
            onChange={formik.handleChange}
            required
          >
            <option value="" disabled>Wybierz</option>
            <option value={240}>240</option>
            <option value={320}>320</option>
            <option value={400}>400</option>
            <option value={480}>480</option>
            <option value={560}>560</option>
            <option value={640}>640</option>
            <option value={720}>720</option>
            <option value={800}>800</option>
            <option value={880}>880</option>
            <option value={960}>960</option>
            <option value={1040}>1040</option>
            <option value={1120}>1120</option>
            <option value={1200}>1200</option>
            <option value={1280}>1280</option>
            <option value={1360}>1360</option>
            <option value={1440}>1440</option>
            <option value={1520}>1520</option>
            <option value={1600}>1600</option>
            <option value={1680}>1680</option>
            <option value={1760}>1760</option>
            <option value={1840}>1840</option>
            <option value={1920}>1920</option>
            <option value={2000}>2000</option>
            <option value={2080}>2080</option>
            <option value={2160}>2160</option>
            <option value={2240}>2240</option>
            <option value={2340}>2340</option>
          </select>
        </div>
        <div className="form-group">
          <label htmlFor={`width__${form.id}`}>Szerokość (mm)</label>
          <input
            type="number"
            className="form-control"
            placeholder="Wpisz"
            id={`width__${form.id}`}
            name="width"
            value={formik.values.width}
            onChange={formik.handleChange}
            autoComplete="off"
            required
          />
          <span className="error">{formik.errors.width && formik.errors.width}</span>
        </div>
        <Poles
          formId={form.id}
          onChange={formik.handleChange}
          fenceType={FENCE_TYPE_FOLDING_GATE}
          value={formik.values.poles}
        />
        {formik.values.poles && formik.values.poles !== POLES_LACK && (
          <RivetNutPole
            formId={form.id}
            onChange={formik.handleChange}
            value={formik.values.rivetNutPole}
          />
        )}
        <Holder
          formId={form.id}
          onChange={formik.handleChange}
          poles={formik.values.poles}
          value={formik.values.holder}
        />
        <CoverBetweenWings
          formId={form.id}
          onChange={formik.handleChange}
          value={formik.values.coverBetweenWings}
        />
        <MountingStrip
          formId={form.id}
          onChange={formik.handleChange}
          value={formik.values.mountingStrip}
          poles={formik.values.poles}
          type={FENCE_TYPE_FOLDING_GATE}
        />
        <MaskingRod
          formId={form.id}
          onChange={formik.handleChange}
          value={formik.values.maskingRod}
        />
      </div>
      <FormActions isSubmitting={formik.isSubmitting} />
      {form.response?.error && (
        <h2 style={{ color: 'red' }}>
          Wystąpił błąd:
          {' '}
          {form.response.error}
        </h2>
      )}
      {isResponseAvailable && !form.response?.error && <FoldingGateResponse values={form.values} response={form.response} isPdf={false} />}
    </form>
  );
};

FoldingGate.propTypes = {
  form: PropTypes.object.isRequired,
  updateForm: PropTypes.func.isRequired,
};

export default FoldingGate;
