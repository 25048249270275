import React from 'react';

const ModuleHeight = ({ formId, onChange, value }) => (
  <div className="form-group">
    <label htmlFor={`moduleHeight__${formId}`}>Wysokość modułu</label>
    <input
      type="number"
      className="form-control"
      placeholder="Wpisz"
      id={`moduleHeight__${formId}`}
      name="moduleHeight"
      value={value}
      onChange={onChange}
      autoComplete="off"
      required
    />
  </div>
);

export default ModuleHeight;
