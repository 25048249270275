import React from 'react';

const LedLight = ({ formId, onChange, value }) => (
  <div className="lightInput">
    <div className="form-group">
      <label htmlFor={`light__${formId}`}>Podświetlany napis</label>
      <select
        className="form-control"
        id={`light__${formId}`}
        name="light"
        onChange={onChange}
        value={value}
        required
      >
        <option value="" disabled>Wybierz</option>
        <option value="TAK">TAK</option>
        <option value="NIE">NIE</option>
      </select>
    </div>
  </div>
);

export default LedLight;
