import React from 'react';

export const CLAMP_STANDARD = 'standard';
export const CLAMP_GLUED = 'glued';
export const CLAMP_HINGED = 'hinged';
export const CLAMP_HINGED_STANDARD = 'hingedStandard';
export const CLAMP_GLUED_HINGED = 'gluedHinged';
export const CLAMP_STANDARD_GLUED = 'standardGlued';
export const CLAMP_READY_MADE = 'readyMade';
export const CLAMP_NO = 'lack';

export const clampsInPolish = {
  [CLAMP_STANDARD]: 'Standardowy',
  [CLAMP_GLUED]: 'Wklejany',
  [CLAMP_HINGED]: 'Zawiasowy',
  [CLAMP_HINGED_STANDARD]: 'Zawiasowy/Standardowy (2/2)',
  [CLAMP_GLUED_HINGED]: 'Zawiasowy/Wklejany (2/2)',
  [CLAMP_STANDARD_GLUED]: 'Standardowy/Wklejany (2/2)',
  [CLAMP_READY_MADE]: 'Wymiar na gotowo',
  [CLAMP_NO]: 'Brak',
};

const Clamp = ({ formId, onChange, value }) => (
  <div className="clampInput">
    <div className="form-group">
      <label htmlFor={`clamp__${formId}`}>Uchwyt montażowy</label>
      <select
        className="form-control"
        id={`clamp__${formId}`}
        name="clamp"
        onChange={onChange}
        value={value}
        required
      >
        <option value="" disabled>Wybierz</option>
        <option value={CLAMP_STANDARD}>Standardowy</option>
        <option value={CLAMP_GLUED}>Wklejany</option>
        <option value={CLAMP_HINGED}>Zawiasowy</option>
        <option value={CLAMP_HINGED_STANDARD}>Zawiasowy/Standardowy (2/2)</option>
        <option value={CLAMP_GLUED_HINGED}>Zawiasowy/Wklejany (2/2)</option>
        <option value={CLAMP_STANDARD_GLUED}>Standardowy/Wklejany (2/2)</option>
        <option value={CLAMP_READY_MADE}>Wymiar na gotowo</option>
        <option value={CLAMP_NO}>Brak uchwytu</option>
      </select>
    </div>
  </div>
);

export default Clamp;
