import React from 'react';
import {
  FENCE_TYPE_GATE_GATEWAY,
  FENCE_TYPE_GATE_TILTING,
  FENCE_TYPE_GATEWAY,
  FENCE_TYPE_NON_SYMMETRICAL_GATE,
  FENCE_TYPE_SINGLE_LEAF_GATE,
} from '../../FenceTypesConstants';

export const HINGES_STANDARD = 'standard';
export const HINGES_180_DEGREES = '180degrees';
export const HINGES_LOCINOX = 'locinox';

export const hingesInPolish = {
  [HINGES_STANDARD]: 'Standard/Wklejany',
  [HINGES_180_DEGREES]: '180 stopni',
  [HINGES_LOCINOX]: 'Locinox',
};

const Hinges = ({
  formId, onChange, fenceType, value,
}) => (
  <div className="hingesInput">
    {fenceType === FENCE_TYPE_GATEWAY && (
      <div className="form-group">
        <label htmlFor={`hinges__${formId}`}>Zawiasy</label>
        <select
          className="form-control"
          id={`hinges__${formId}`}
          name="hinges"
          onChange={onChange}
          value={value}
          required
        >
          <option value="" disabled>Wybierz</option>
          <option value={HINGES_STANDARD}>Standard/Wklejane</option>
          <option value={HINGES_180_DEGREES}>180 stopni</option>
          <option value={HINGES_LOCINOX}>Locinox mamoth</option>
        </select>
      </div>
    )}
    {(fenceType === FENCE_TYPE_GATE_TILTING || fenceType === FENCE_TYPE_SINGLE_LEAF_GATE || fenceType === FENCE_TYPE_GATE_GATEWAY || fenceType === FENCE_TYPE_NON_SYMMETRICAL_GATE)
      && (
      <div className="form-group">
        <label htmlFor={`hinges__${formId}`}>Zawiasy</label>
        <select
          className="form-control"
          id={`hinges__${formId}`}
          name="hinges"
          onChange={onChange}
          value={value}
          required
        >
          <option value="" disabled>Wybierz</option>
          <option value={HINGES_STANDARD}>Standard/Wklejane</option>
          <option value={HINGES_180_DEGREES}>180 stopni</option>
        </select>
      </div>
      )}
  </div>
);

export default Hinges;
