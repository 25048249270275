/* eslint-disable camelcase */
import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import Poles, { POLES_LACK } from '../Input/Poles';
import MaskingRod from '../Input/MaskingRod';
import Holder, {
  HOLDER_FORM_NAME,
  HOLDER_NO,
} from '../Input/Holder';
import CoverBetweenWings from '../Input/CoverBetweenWings';
import Hinges from '../Input/Hinges';
import FormActions from '../Input/FormActions';
import { FENCE_TYPE_GATE_GATEWAY } from '../../FenceTypesConstants';
import GateGatewayResponse from './GateGatewayResponse';
import invoke from '../../lambda';

const GateGateway = ({ form, updateForm }) => {
  const formik = useFormik({
    initialValues: {
      height: form.values.height || '',
      leftWingWidth: form.values.leftWingWidth || '',
      rightWingWidth: form.values.rightWingWidth || '',
      poles: form.values.poles || '',
      holder: form.values.holder || '',
      hinges: form.values.hinges || '',
      coverBetweenWings: form.values.coverBetweenWings || '',
      maskingRod: form.values.maskingRod || '',
    },
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);

      const res = await invoke({
        amount: form.quantity,
        model: 'PP002(P82)C1_0',
        type: 'uchylna',
        assymetry: 'BRAMO-FURTKA',
        ...values,
      });

      const newForm = {
        ...form,
        values: {
          ...values,
        },
        response: {
          ...res.products[0],
        },
      };

      setSubmitting(false);
      updateForm(newForm);
    },
    validate: ({
      leftWingWidth,
      rightWingWidth,
    }) => {
      const errors = {};

      if (!leftWingWidth) {
        errors.leftWingWidth = 'Wymagana wartość';
      } else if (leftWingWidth < 500) {
        errors.leftWingWidth = 'Minimalna szerokość to 500mm.';
      } else if (leftWingWidth > 3750) {
        errors.leftWingWidth = 'Maksymalna szerokość to 3750mm.';
      }
      if (!rightWingWidth) {
        errors.rightWingWidth = 'Wymagana wartość';
      } else if (rightWingWidth < 500) {
        errors.rightWingWidth = 'Minimalna szerokość to 500mm.';
      } else if (rightWingWidth > 3750) {
        errors.rightWingWidth = 'Maksymalna szerokość to 3750mm.';
      }
    },
  });
  useEffect(() => {
    if (formik.values.poles === POLES_LACK) {
      formik.setFieldValue(HOLDER_FORM_NAME, HOLDER_NO);
    }
  }, [formik.values.poles]);
  const isResponseAvailable = Object.keys(form.response).length > 0;

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="formInputs">
        <div className="form-group">
          <label htmlFor={`height__${form.id}`}>Wysokość (mm)</label>
          <select
            className="form-control"
            placeholder="Wpisz"
            id={`height__${form.id}`}
            name="height"
            value={formik.values.height}
            onChange={formik.handleChange}
            required
          >
            <option value="" disabled>Wybierz</option>
            <option value={240}>240</option>
            <option value={320}>320</option>
            <option value={400}>400</option>
            <option value={480}>480</option>
            <option value={560}>560</option>
            <option value={640}>640</option>
            <option value={720}>720</option>
            <option value={800}>800</option>
            <option value={880}>880</option>
            <option value={960}>960</option>
            <option value={1040}>1040</option>
            <option value={1120}>1120</option>
            <option value={1200}>1200</option>
            <option value={1280}>1280</option>
            <option value={1360}>1360</option>
            <option value={1440}>1440</option>
            <option value={1520}>1520</option>
            <option value={1600}>1600</option>
            <option value={1680}>1680</option>
            <option value={1760}>1760</option>
            <option value={1840}>1840</option>
            <option value={1920}>1920</option>
            <option value={2000}>2000</option>
            <option value={2080}>2080</option>
            <option value={2160}>2160</option>
            <option value={2240}>2240</option>
            <option value={2340}>2340</option>
          </select>
        </div>
        <div className="form-group">
          <label htmlFor={`leftWingWidth__${form.id}`}>Lewe skrzydło szerokość (mm)</label>
          <input
            type="number"
            className="form-control"
            placeholder="Wpisz"
            id={`leftWingWidth__${form.id}`}
            name="leftWingWidth"
            value={formik.values.leftWingWidth}
            autoComplete="off"
            onChange={formik.handleChange}
            required
          />
          <span className="error">{formik.errors.leftWingWidth && formik.errors.leftWingWidth}</span>
        </div>
        <div className="form-group">
          <label htmlFor={`rightWingWidth__${form.id}`}>Prawe skrzydło szerokość (mm)</label>
          <input
            type="number"
            className="form-control"
            placeholder="Wpisz"
            id={`rightWingWidth__${form.id}`}
            name="rightWingWidth"
            value={formik.values.rightWingWidth}
            autoComplete="off"
            onChange={formik.handleChange}
            required
          />
          <span className="error">{formik.errors.rightWingWidth && formik.errors.rightWingWidth}</span>
        </div>
        <Poles
          formId={form.id}
          onChange={formik.handleChange}
          fenceType={FENCE_TYPE_GATE_GATEWAY}
          value={formik.values.poles}
        />
        <Holder
          formId={form.id}
          onChange={formik.handleChange}
          poles={formik.values.poles}
          value={formik.values.holder}
        />
        <Hinges
          formId={form.id}
          onChange={formik.handleChange}
          fenceType={FENCE_TYPE_GATE_GATEWAY}
          value={formik.values.hinges}
        />
        {formik.values.hinges && formik.values.hinges === 'standard' && (
          <MaskingRod
            formId={form.id}
            onChange={formik.handleChange}
            value={formik.values.maskingRod}
          />
        )}
        <CoverBetweenWings
          formId={form.id}
          onChange={formik.handleChange}
          value={formik.values.coverBetweenWings}
        />
      </div>
      <FormActions isSubmitting={formik.isSubmitting} />
      {form.response?.error && (
        <h2 style={{ color: 'red' }}>
          Wystąpił błąd:
          {' '}
          {form.response.error}
        </h2>
      )}
      {isResponseAvailable && !form.response?.error && <GateGatewayResponse response={form.response} values={form.values} isPdf={false} />}
    </form>
  );
};

GateGateway.propTypes = {
  form: PropTypes.object.isRequired,
  updateForm: PropTypes.func.isRequired,
};

export default GateGateway;
