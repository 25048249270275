import axios from 'axios';

const SaveMaterialsAsHTML = async () => {
  const order = Number(sessionStorage.getItem('order'));
  const zamowieniaId = sessionStorage.getItem('zamowieniaId');
  const html = sessionStorage.getItem('modelComponent');
  const header = sessionStorage.getItem('header');
  const pattern = /<div class="formInputs">[\s\S]*?Licz<\/button><\/div>/g;
  const headerPattern = /<svg[\s\S]*?<\/svg>/g;
  const clearedHTML = html.replace(pattern, '');
  const clearedHeader = header.replace(headerPattern, '');
  const payload = {
    html: clearedHTML,
    header: clearedHeader,
    order,
  };
  const { status } = await axios.post(
    `/production-materials-manually-calculated/${zamowieniaId}/html`,
    payload,
  );
  switch (status) {
    case 204:
      sessionStorage.removeItem('modelComponent');
      sessionStorage.removeItem('header');
      return true;
    default:
      return false;
  }
};

export default SaveMaterialsAsHTML;
