import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import invoke from '../../lambda';
import FormActions from '../Input/FormActions';
import TableSolarPanelsResponse from './TableSolarPanelsResponse';

const TableSolarPanels = ({ form, updateForm }) => {
  const formik = useFormik({
    initialValues: {
      poleHeight: form.values.poleHeight || '',
    },
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);

      const res = await invoke({
        amount: form.quantity,
        model: 'table_solar_panel',
        type: 'table_solar_panel',
        ...values,
      });

      const newForm = {
        ...form,
        values: {
          ...values,
        },
        response: {
          ...res.products[0],
        },
      };

      setSubmitting(false);
      updateForm(newForm);
    },
  });
  const isResponseAvailable = Object.keys(form.response).length > 0;

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="formInputs">
        <div className="form-group">
          <label htmlFor={`poleHeight__${form.id}`}>Wysokość słupów</label>
          <select
            className="form-control"
            id={`poleHeight__${form.id}`}
            onChange={formik.handleChange}
            name="poleHeight"
            value={formik.values.poleHeight}
            required
          >
            <option value="" disabled>Wybierz</option>
            <option value="1500">1500</option>
            <option value="1800">1800</option>
          </select>
        </div>
      </div>
      <FormActions isSubmitting={formik.isSubmitting} />
      {form.response?.error && (
        <h2 style={{ color: 'red' }}>
          Wystąpił błąd:
          {' '}
          {form.response.error}
        </h2>
      )}
      {isResponseAvailable && !form.response?.error && <TableSolarPanelsResponse values={form.values} response={form.response} isPdf={false} />}
    </form>
  );
};

TableSolarPanels.propTypes = {
  form: PropTypes.object.isRequired,
  updateForm: PropTypes.func.isRequired,
};

export default TableSolarPanels;
