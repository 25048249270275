import React from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { signOut } from 'auth';

// eslint-disable-next-line arrow-body-style
const Header = () => {
  const dispatch = useDispatch();
  return (
    <Container>
      <Link to="/">
        <Button>Strona głowna</Button>
      </Link>
      <Button onClick={() => signOut(dispatch)}>
        Wyloguj
      </Button>
    </Container>
  );
};

const Container = styled.div`
  background: #2d3234;
  display: flex;
  justify-content: flex-end;
`;

const Button = styled.button`
   border-radius: 22px;
   padding: 0.25rem 2.5rem;
   margin: 20px 10px;
`;

export default Header;
