import React from 'react';
import PropTypes from 'prop-types';
import Entrance from './Entrance';

const Eq = ({ form, addNextForm, updateForm }) => {
  const FormComponent = () => {
    switch (form.type) {
      case 'EQ':
        return <Entrance form={form} addNextForm={addNextForm} updateForm={updateForm} />;
      default:
        throw new Error(`Unknown type (${form.type}).`);
    }
  };

  return (
    <section>
      <FormComponent />
    </section>
  );
};

Eq.propTypes = {
  form: PropTypes.object.isRequired,
  addNextForm: PropTypes.func.isRequired,
  updateForm: PropTypes.func.isRequired,
};

export default Eq;
