import React from 'react';
import PropTypes from 'prop-types';
import { createFormTitle } from './OrderFormHeader';

const OrderFormProductSingular = ({ forms, form, updateForm }) => {
  let error = null;

  const formWithTheSameProductSingular = forms.filter((f) => f.id !== form.id && f.productSingular === form.productSingular)[0];

  if (formWithTheSameProductSingular) {
    error = `LP produktu musi być unikalna dla całego zamówienia.\nTaka wartość już istnieje w ${createFormTitle(formWithTheSameProductSingular)}`;
  }

  const onChange = (e) => {
    e.preventDefault();

    const productSingular = e.target.value;
    updateForm({ ...form, productSingular });
    sessionStorage.setItem('order', productSingular);
  };

  return (
    <div className="form-group">
      <label htmlFor={`orderFormProductSingular__${form.id}`}>Podaj LP produktu ze zlecenia</label>
      <input
        type="number"
        className="form-control"
        placeholder="Podaj LP produktu ze zlecenia"
        id={`orderFormProductSingular__${form.id}`}
        name="productSingular"
        value={form.productSingular || ''}
        onChange={onChange}
        required
      />
      <p className="form-text text-muted error">{error}</p>
    </div>
  );
};

OrderFormProductSingular.propTypes = {
  form: PropTypes.object.isRequired,
  updateForm: PropTypes.func.isRequired,
};

export default OrderFormProductSingular;
