import React from 'react';
import {
  FENCE_TYPE_FOLDING_GATE,
  FENCE_TYPE_GATE_GATEWAY,
  FENCE_TYPE_GATE_SELF_SUPPORTING, FENCE_TYPE_GATE_TILTING, FENCE_TYPE_GATEWAY,
  FENCE_TYPE_NON_SYMMETRICAL_GATE, FENCE_TYPE_SINGLE_LEAF_FOLDING_GATE, FENCE_TYPE_SINGLE_LEAF_GATE,
} from '../../FenceTypesConstants';

export const POLES_LACK = 'brak';
export const POLES_ONE = 'jeden';
export const POLES_STANDARD = 'standard';
export const POLES_GSS_ZERO = 0;
export const POLES_GSS_ONE = 1;
export const POLES_GSS_TWO = 2;
export const POLES_GSS_THREE = 3;
export const POLES_FORM_NAME = 'poles';

const Poles = ({
  formId, onChange, fenceType, value,
}) => (
  <div className="polesInput">
    {(fenceType === FENCE_TYPE_GATEWAY || fenceType === FENCE_TYPE_GATE_TILTING || fenceType === FENCE_TYPE_GATE_GATEWAY || fenceType === FENCE_TYPE_NON_SYMMETRICAL_GATE || fenceType === FENCE_TYPE_FOLDING_GATE || fenceType === FENCE_TYPE_SINGLE_LEAF_FOLDING_GATE || fenceType === FENCE_TYPE_SINGLE_LEAF_GATE)
      && (
        <div className="form-group">
          <label htmlFor={`poles__${formId}`}>Słupy</label>
          <select
            className="form-control"
            id={`poles__${formId}`}
            name={POLES_FORM_NAME}
            onChange={onChange}
            value={value}
            required
          >
            <option value="" disabled>Wybierz</option>
            <option value={POLES_LACK}>0 szt</option>
            <option value={POLES_ONE}>1 szt</option>
            <option value={POLES_STANDARD}>2 szt</option>
          </select>
        </div>
      )}
    {(fenceType === 'gatewayWithMachine')
    && (
      <div className="form-group">
        <label htmlFor={`poles__${formId}`}>Słupy</label>
        <select
          className="form-control"
          id={`poles__${formId}`}
          name={POLES_FORM_NAME}
          onChange={onChange}
          value={value}
          required
        >
          <option value="" disabled>Wybierz</option>
          <option value={POLES_LACK}>0 szt</option>
          <option value={POLES_ONE}>1 szt</option>
        </select>
      </div>
    )}
    {fenceType === FENCE_TYPE_GATE_SELF_SUPPORTING
        && (
          <div className="form-group">
            <label htmlFor={`poles__${formId}`}>Słupy do wbetonowania</label>
            <select
              className="form-control"
              id={`poles__${formId}`}
              name={POLES_FORM_NAME}
              onChange={onChange}
              value={value}
              required
            >
              <option value="" disabled>Wybierz</option>
              <option value={POLES_GSS_ZERO}>0 szt</option>
              <option value={POLES_GSS_ONE}>1 szt</option>
              <option value={POLES_GSS_TWO}>2 szt</option>
              <option value={POLES_GSS_THREE}>3 szt</option>
            </select>
          </div>
        )}
  </div>
);

export default Poles;
