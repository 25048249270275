import axios from 'axios';

const UploadToGoogleDrive = async (outer, zamowieniaId) => {
  if (process.env.NODE_ENV !== 'production') {
    return;
  }
  const styleReplaced = outer.replace(
    /<link href="\/static\/css\/main\.\w+\.css" rel="stylesheet">/,
    (match) => {
      // Extract the href value
      const hrefMatch = match.match(/href="([^"]+)"/);
      if (hrefMatch) {
        const originalHref = hrefMatch[1];
        // Add the prefix to the href
        const newHref = `https://production.konsport.com${originalHref}`;
        // Replace the original href with the new href
        return match.replace(originalHref, newHref);
      }
      return match;
    },
  );
  const payload = {
    File: styleReplaced,
    Name: `${zamowieniaId}.html`,
    DriveId: '0ACIzmz43tGekUk9PVA',
    FileType: 'text/html',
  };
  try {
    await axios.post(`/production-materials-manually-calculated/${zamowieniaId}/upload`,
      payload);
  } catch (error) {
    /* eslint-disable no-console */
    console.error(`Error while uploading calculation view: ${error}`);
  }
};

export default UploadToGoogleDrive;
