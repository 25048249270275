import { connect } from 'react-redux';
import OrderFormProductSingular from '../../components/OrderForm/OrderFormProductSingular';

const mapStateToProps = (state) => ({
  forms: state.app.forms,
});

export default connect(
  mapStateToProps,
)(OrderFormProductSingular);
