export const MODEL_PP002P82 = 'PP002P82';
export const MODEL_PP002P82_0 = 'PP002P82_0';
export const MODEL_2D = 'TwoD';
export const MODEL_2DS = 'TwoDS';
export const MODEL_3D_4 = 'ThreeD4';
export const MODEL_3D_5 = 'ThreeD5';
export const MODEL_PB001 = 'PB001';
export const MODEL_PB003 = 'PB003';
export const MODEL_PB155 = 'PB155';
export const MODEL_PP001C = 'PP001C';
export const MODEL_PP001CC = 'PP001CC';
export const MODEL_PP001W = 'PP001W';
export const MODEL_PP001N = 'PP001N';
export const MODEL_PP002P82COLORE1 = 'PP002P82COLORE1';
export const MODEL_PP002P82COLORE1_0 = 'PP002P82COLORE1_0';
export const MODEL_PP002P82COLORE2 = 'PP002P82COLORE2';
export const MODEL_PP002P82COLORE2_0 = 'PP002P82COLORE2_0';
export const MODEL_PP002P82NOCE1 = 'PP002P82NOCE1';
export const MODEL_PP002P82NOCE1_0 = 'PP002P82NOCE1_0';
export const MODEL_PP002P82NOCE2 = 'PP002P82NOCE2';
export const MODEL_PP002P82NOCE2_0 = 'PP002P82NOCE2_0';
export const MODEL_PP002P82PINO1 = 'PP002P82PINO1';
export const MODEL_PP002P82PINO1_0 = 'PP002P82PINO1_0';
export const MODEL_PP002P82PINO2 = 'PP002P82PINO2';
export const MODEL_PP002P82PINO2_0 = 'PP002P82PINO2_0';
export const MODEL_PP002P82MIX = 'PP002P82MIX';
export const MODEL_PP002 = 'PP002';
export const MODEL_PP002DIVERSO = 'PP002DIVERSO';
export const MODEL_PP002DIVERSO1 = 'PP002DIVERSO1';
export const MODEL_PP002DIVERSO2 = 'PP002DIVERSO2';
export const MODEL_PP002DIVERSO3 = 'PP002DIVERSO3';
export const MODEL_PP002P = 'PP002P';
export const MODEL_PP001P46 = 'PP001P46';
export const MODEL_PP002P64 = 'PP002P64';
export const MODEL_PP002P64V = 'PP002P64V';
export const MODEL_PP002P102 = 'PP002P102';
export const MODEL_PP002P102_0 = 'PP002P102_0';
export const MODEL_P302 = 'P302';
export const MODEL_P302_PB002 = 'P302PB002';
export const MODEL_P302_PS005 = 'P302PS005';
export const MODEL_PS001 = 'PS001';
export const MODEL_PS002 = 'PS002';
export const MODEL_PS003 = 'PS003';
export const MODEL_PS004 = 'PS004';
export const MODEL_PS004_2 = 'PS004_2';
export const MODEL_PS005 = 'PS005';
export const MODEL_STILE = 'STILE';
export const MODEL_TVORA1 = 'TVORA1';
export const MODEL_TVORA2 = 'TVORA2';
export const MODEL_TVORA3 = 'TVORA3';
export const MODEL_TVORA4 = 'TVORA4';
export const MODEL_TVORA5 = 'TVORA5';
export const MODEL_VERTICALE = 'VERTICALE';
export const MODEL_VERTICALE_2 = 'VERTICALE_2';
export const MODEL_VERTICALE_3 = 'VERTICALE_3';
export const MODEL_VERTICALE_LM = 'VERTICALE_LM';
export const MODEL_VMS = 'VMS';
export const MODEL_ECO = 'ECO';
export const MODEL_HIGHWAY = 'HIGHWAY';
export const MODEL_SOLAR_PANEL_POLE = 'SOLAR_PANEL_POLE';
export const MODEL_TABLE_SOLAR_PANEL = 'TABLE_SOLAR_PANEL';
export const MODEL_CARPORT = 'CARPORT';
export const MODEL_BALLAST = 'BALLAST';
export const MODEL_EQ = 'EQ';

export const MODEL_COMPONENT_PP002P82 = 'PP002P82';
export const MODEL_COMPONENT_PP002P82_0 = 'PP002P82_0';
export const MODEL_COMPONENT_2D = 'TwoD';
export const MODEL_COMPONENT_2DS = 'TwoDS';
export const MODEL_COMPONENT_3D4 = 'ThreeD4';
export const MODEL_COMPONENT_3D5 = 'ThreeD5';
export const MODEL_COMPONENT_PB001 = 'PB001';
export const MODEL_COMPONENT_PB003 = 'PB003';
export const MODEL_COMPONENT_PB155 = 'PB155';
export const MODEL_COMPONENT_PP001C = 'PP001C';
export const MODEL_COMPONENT_PP001CC = 'PP001CC';
export const MODEL_COMPONENT_PP001W = 'PP001W';
export const MODEL_COMPONENT_PP001N = 'PP001N';
export const MODEL_COMPONENT_PP002P82COLORE1 = 'PP002P82COLORE1';
export const MODEL_COMPONENT_PP002P82COLORE1_0 = 'PP002P82COLORE1_0';
export const MODEL_COMPONENT_PP002P82COLORE2 = 'PP002P82COLORE2';
export const MODEL_COMPONENT_PP002P82COLORE2_0 = 'PP002P82COLORE2_0';
export const MODEL_COMPONENT_PP002P82NOCE1 = 'PP002P82NOCE1';
export const MODEL_COMPONENT_PP002P82NOCE1_0 = 'PP002P82NOCE1_0';
export const MODEL_COMPONENT_PP002P82NOCE2 = 'PP002P82NOCE2';
export const MODEL_COMPONENT_PP002P82NOCE2_0 = 'PP002P82NOCE2_0';
export const MODEL_COMPONENT_PP002P82PINO1 = 'PP002P82PINO1';
export const MODEL_COMPONENT_PP002P82PINO1_0 = 'PP002P82PINO1_0';
export const MODEL_COMPONENT_PP002P82PINO2 = 'PP002P82PINO2';
export const MODEL_COMPONENT_PP002P82PINO2_0 = 'PP002P82PINO2_0';
export const MODEL_COMPONENT_PP002P82MIX = 'PP002P82MIX';
export const MODEL_COMPONENT_PP002 = 'PP002';
export const MODEL_COMPONENT_PP002DIVERSO = 'PP002DIVERSO';
export const MODEL_COMPONENT_PP002DIVERSO1 = 'PP002DIVERSO1';
export const MODEL_COMPONENT_PP002DIVERSO2 = 'PP002DIVERSO2';
export const MODEL_COMPONENT_PP002DIVERSO3 = 'PP002DIVERSO3';
export const MODEL_COMPONENT_PP002P = 'PP002P';
export const MODEL_COMPONENT_PP001P46 = 'PP001P46';
export const MODEL_COMPONENT_PP002P64 = 'PP002P64';
export const MODEL_COMPONENT_PP002P64V = 'PP002P64V';
export const MODEL_COMPONENT_PP002P102 = 'PP002P102';
export const MODEL_COMPONENT_PP002P102_0 = 'PP002P102_0';
export const MODEL_COMPONENT_P302 = 'P302';
export const MODEL_COMPONENT_P302_PB002 = 'P302PB002';
export const MODEL_COMPONENT_P302_PS005 = 'P302PS005';
export const MODEL_COMPONENT_PS001 = 'PS001';
export const MODEL_COMPONENT_PS002 = 'PS002';
export const MODEL_COMPONENT_PS003 = 'PS003';
export const MODEL_COMPONENT_PS004 = 'PS004';
export const MODEL_COMPONENT_PS004_2 = 'PS004_2';
export const MODEL_COMPONENT_PS005 = 'PS005';
export const MODEL_COMPONENT_STILE = 'STILE';
export const MODEL_COMPONENT_TVORA1 = 'TVORA1';
export const MODEL_COMPONENT_TVORA2 = 'TVORA2';
export const MODEL_COMPONENT_TVORA3 = 'TVORA3';
export const MODEL_COMPONENT_TVORA4 = 'TVORA4';
export const MODEL_COMPONENT_TVORA5 = 'TVORA5';
export const MODEL_COMPONENT_VERTICALE = 'VERTICALE';
export const MODEL_COMPONENT_VERTICALE_2 = 'VERTICALE_2';
export const MODEL_COMPONENT_VERTICALE_3 = 'VERTICALE_3';
export const MODEL_COMPONENT_VERTICALE_LM = 'VERTICALE_LM';
export const MODEL_COMPONENT_VMS = 'VMS';
export const MODEL_COMPONENT_ECO = 'ECO';
export const MODEL_COMPONENT_HIGHWAY = 'HIGHWAY';
export const MODEL_COMPONENT_SOLAR_PANEL_POLE = 'SOLAR_PANEL_POLE';
export const MODEL_COMPONENT_TABLE_SOLAR_PANEL = 'TABLE_SOLAR_PANEL';
export const MODEL_COMPONENT_CARPORT = 'CARPORT';
export const MODEL_COMPONENT_BALLAST = 'BALLAST';
export const MODEL_COMPONENT_EQ = 'EQ';

export const modelAndModelComponents = {
  [MODEL_PP001W]: MODEL_COMPONENT_PP001W,
  [MODEL_PP001C]: MODEL_COMPONENT_PP001C,
  [MODEL_PP001CC]: MODEL_COMPONENT_PP001CC,
  [MODEL_PP001N]: MODEL_COMPONENT_PP001N,
  [MODEL_PP002]: MODEL_COMPONENT_PP002,
  [MODEL_PP002P]: MODEL_COMPONENT_PP002P,
  [MODEL_P302]: MODEL_COMPONENT_P302,
  [MODEL_P302_PB002]: MODEL_COMPONENT_P302_PB002,
  [MODEL_P302_PS005]: MODEL_COMPONENT_P302_PS005,
  [MODEL_PP001P46]: MODEL_COMPONENT_PP001P46,
  [MODEL_PP002P64]: MODEL_COMPONENT_PP002P64,
  [MODEL_PP002P64V]: MODEL_COMPONENT_PP002P64V,
  [MODEL_PP002P102]: MODEL_COMPONENT_PP002P102,
  [MODEL_PP002P102_0]: MODEL_COMPONENT_PP002P102_0,
  [MODEL_PP002P82]: MODEL_COMPONENT_PP002P82,
  [MODEL_PP002P82_0]: MODEL_COMPONENT_PP002P82_0,
  [MODEL_PP002P82PINO1]: MODEL_COMPONENT_PP002P82PINO1,
  [MODEL_PP002P82PINO1_0]: MODEL_COMPONENT_PP002P82PINO1_0,
  [MODEL_PP002P82PINO2]: MODEL_COMPONENT_PP002P82PINO2,
  [MODEL_PP002P82PINO2_0]: MODEL_COMPONENT_PP002P82PINO2_0,
  [MODEL_PP002P82NOCE1]: MODEL_COMPONENT_PP002P82NOCE1,
  [MODEL_PP002P82NOCE1_0]: MODEL_COMPONENT_PP002P82NOCE1_0,
  [MODEL_PP002P82NOCE2]: MODEL_COMPONENT_PP002P82NOCE2,
  [MODEL_PP002P82NOCE2_0]: MODEL_COMPONENT_PP002P82NOCE2_0,
  [MODEL_PP002P82COLORE1]: MODEL_COMPONENT_PP002P82COLORE1,
  [MODEL_PP002P82COLORE1_0]: MODEL_COMPONENT_PP002P82COLORE1_0,
  [MODEL_PP002P82COLORE2]: MODEL_COMPONENT_PP002P82COLORE2,
  [MODEL_PP002P82COLORE2_0]: MODEL_COMPONENT_PP002P82COLORE2_0,
  [MODEL_PP002P82MIX]: MODEL_COMPONENT_PP002P82MIX,
  [MODEL_PP002DIVERSO]: MODEL_COMPONENT_PP002DIVERSO,
  [MODEL_PP002DIVERSO1]: MODEL_COMPONENT_PP002DIVERSO1,
  [MODEL_PP002DIVERSO2]: MODEL_COMPONENT_PP002DIVERSO2,
  [MODEL_PP002DIVERSO3]: MODEL_COMPONENT_PP002DIVERSO3,
  [MODEL_PS001]: MODEL_COMPONENT_PS001,
  [MODEL_PS002]: MODEL_COMPONENT_PS002,
  [MODEL_PS003]: MODEL_COMPONENT_PS003,
  [MODEL_PS004]: MODEL_COMPONENT_PS004,
  [MODEL_PS004_2]: MODEL_COMPONENT_PS004_2,
  [MODEL_PS005]: MODEL_COMPONENT_PS005,
  [MODEL_PB001]: MODEL_COMPONENT_PB001,
  [MODEL_PB003]: MODEL_COMPONENT_PB003,
  [MODEL_PB155]: MODEL_COMPONENT_PB155,
  [MODEL_2D]: MODEL_COMPONENT_2D,
  [MODEL_2DS]: MODEL_COMPONENT_2DS,
  [MODEL_3D_4]: MODEL_COMPONENT_3D4,
  [MODEL_3D_5]: MODEL_COMPONENT_3D5,
  [MODEL_STILE]: MODEL_COMPONENT_STILE,
  [MODEL_TVORA1]: MODEL_COMPONENT_TVORA1,
  [MODEL_TVORA2]: MODEL_COMPONENT_TVORA2,
  [MODEL_TVORA3]: MODEL_COMPONENT_TVORA3,
  [MODEL_TVORA4]: MODEL_COMPONENT_TVORA4,
  [MODEL_TVORA5]: MODEL_COMPONENT_TVORA5,
  [MODEL_VERTICALE]: MODEL_COMPONENT_VERTICALE,
  [MODEL_VERTICALE_2]: MODEL_COMPONENT_VERTICALE_2,
  [MODEL_VERTICALE_3]: MODEL_COMPONENT_VERTICALE_3,
  [MODEL_VERTICALE_LM]: MODEL_COMPONENT_VERTICALE_LM,
  [MODEL_VMS]: MODEL_COMPONENT_VMS,
  [MODEL_ECO]: MODEL_COMPONENT_ECO,
  [MODEL_HIGHWAY]: MODEL_COMPONENT_HIGHWAY,
  [MODEL_SOLAR_PANEL_POLE]: MODEL_SOLAR_PANEL_POLE,
  [MODEL_TABLE_SOLAR_PANEL]: MODEL_COMPONENT_TABLE_SOLAR_PANEL,
  [MODEL_CARPORT]: MODEL_COMPONENT_CARPORT,
  [MODEL_BALLAST]: MODEL_COMPONENT_BALLAST,
  [MODEL_EQ]: MODEL_COMPONENT_EQ,
};

export const modelComponentsInPolish = {
  [MODEL_COMPONENT_PP002P82]: 'PP002 P82',
  [MODEL_COMPONENT_PP002P82_0]: 'PP002 P82 (0)',
  [MODEL_COMPONENT_2D]: 'Panel 2D',
  [MODEL_COMPONENT_2DS]: 'Panel 2DS',
  [MODEL_COMPONENT_3D4]: 'Panel 3D [4]',
  [MODEL_COMPONENT_3D5]: 'Panel 3D [5]',
  [MODEL_COMPONENT_PB001]: 'PB001',
  [MODEL_COMPONENT_PB003]: 'PB003',
  [MODEL_COMPONENT_PB155]: 'PB155',
  [MODEL_COMPONENT_PP001C]: 'PP001 C',
  [MODEL_COMPONENT_PP001CC]: 'PP001 CC',
  [MODEL_COMPONENT_PP001W]: 'PP001 W',
  [MODEL_COMPONENT_PP001N]: 'PP001 N',
  [MODEL_COMPONENT_PP002P82COLORE1]: 'COLORE I',
  [MODEL_COMPONENT_PP002P82COLORE1_0]: 'COLORE I (0)',
  [MODEL_COMPONENT_PP002P82COLORE2]: 'COLORE II',
  [MODEL_COMPONENT_PP002P82COLORE2_0]: 'COLORE II (0)',
  [MODEL_COMPONENT_PP002P82NOCE1]: 'NOCE I',
  [MODEL_COMPONENT_PP002P82NOCE1_0]: 'NOCE I (0)',
  [MODEL_COMPONENT_PP002P82NOCE2]: 'NOCE II',
  [MODEL_COMPONENT_PP002P82NOCE2_0]: 'NOCE II (0)',
  [MODEL_COMPONENT_PP002P82PINO1]: 'PINO I',
  [MODEL_COMPONENT_PP002P82PINO1_0]: 'PINO I (0)',
  [MODEL_COMPONENT_PP002P82PINO2]: 'PINO II',
  [MODEL_COMPONENT_PP002P82PINO2_0]: 'PINO II (0)',
  [MODEL_COMPONENT_PP002P82MIX]: 'MIX',
  [MODEL_COMPONENT_PP002]: 'PP002',
  [MODEL_COMPONENT_PP002DIVERSO]: 'DIVERSO',
  [MODEL_COMPONENT_PP002DIVERSO1]: 'DIVERSO 1',
  [MODEL_COMPONENT_PP002DIVERSO2]: 'DIVERSO 2',
  [MODEL_COMPONENT_PP002DIVERSO3]: 'DIVERSO 3',
  [MODEL_COMPONENT_PP002P]: 'PP002 P',
  [MODEL_COMPONENT_PP001P46]: 'PP001 P46',
  [MODEL_COMPONENT_PP002P64]: 'PP002 P64',
  [MODEL_COMPONENT_PP002P64V]: 'PP002 P64 V',
  [MODEL_COMPONENT_PP002P102]: 'PP002 P102',
  [MODEL_COMPONENT_PP002P102_0]: 'PP002 P102 (0)',
  [MODEL_COMPONENT_P302]: 'P302',
  [MODEL_COMPONENT_P302_PB002]: 'P302 + PB002',
  [MODEL_COMPONENT_P302_PS005]: 'P302 + PS005',
  [MODEL_COMPONENT_PS001]: 'PS001',
  [MODEL_COMPONENT_PS002]: 'PS002',
  [MODEL_COMPONENT_PS003]: 'PS003',
  [MODEL_COMPONENT_PS004]: 'PS004 (Nieaktualna konstrukcja)',
  [MODEL_COMPONENT_PS004_2]: 'PS004',
  [MODEL_COMPONENT_PS005]: 'PS005',
  [MODEL_COMPONENT_STILE]: 'Ramiak',
  [MODEL_COMPONENT_TVORA1]: 'Ramiak Tvora 1',
  [MODEL_COMPONENT_TVORA2]: 'Ramiak Tvora 2',
  [MODEL_COMPONENT_TVORA3]: 'Ramiak Tvora 3',
  [MODEL_COMPONENT_TVORA4]: 'Ramiak Tvora 4',
  [MODEL_COMPONENT_TVORA5]: 'Ramiak Tvora 5',
  [MODEL_COMPONENT_VERTICALE]: 'Verticale',
  [MODEL_COMPONENT_VERTICALE_2]: 'Verticale II',
  [MODEL_COMPONENT_VERTICALE_3]: 'Verticale III',
  [MODEL_COMPONENT_VERTICALE_LM]: 'Verticale Leroy Merlin',
  [MODEL_COMPONENT_VMS]: 'VMS',
  [MODEL_COMPONENT_ECO]: 'ECO',
  [MODEL_HIGHWAY]: 'Autostrada',
  [MODEL_SOLAR_PANEL_POLE]: 'Słupy do fotowoltaiki',
  [MODEL_TABLE_SOLAR_PANEL]: 'Stoły do fotowoltaiki',
  [MODEL_CARPORT]: 'Carport',
  [MODEL_BALLAST]: 'System balastowy',
  [MODEL_EQ]: 'EQ ENTRANCE',
};

/*
* The order is a business requirement
*/
export const models = [
  MODEL_PP001W,
  MODEL_PP001C,
  MODEL_PP001CC,
  MODEL_PP001N,
  MODEL_PP002,
  MODEL_PP002P,
  MODEL_P302,
  MODEL_P302_PS005,
  MODEL_P302_PB002,
  MODEL_PP001P46,
  MODEL_PP002P64,
  MODEL_PP002P64V,
  MODEL_PP002P102,
  MODEL_PP002P102_0,
  MODEL_PP002P82,
  MODEL_PP002P82_0,
  MODEL_PP002P82PINO1,
  MODEL_PP002P82PINO1_0,
  MODEL_PP002P82PINO2,
  MODEL_PP002P82PINO2_0,
  MODEL_PP002P82NOCE1,
  MODEL_PP002P82NOCE1_0,
  MODEL_PP002P82NOCE2,
  MODEL_PP002P82NOCE2_0,
  MODEL_PP002P82COLORE1,
  MODEL_PP002P82COLORE1_0,
  MODEL_PP002P82COLORE2,
  MODEL_PP002P82COLORE2_0,
  MODEL_PP002P82MIX,
  MODEL_PP002DIVERSO,
  MODEL_PP002DIVERSO1,
  MODEL_PP002DIVERSO2,
  MODEL_PP002DIVERSO3,
  MODEL_PS001,
  MODEL_PS002,
  MODEL_PS003,
  MODEL_PS004,
  MODEL_PS004_2,
  MODEL_PS005,
  MODEL_PB001,
  MODEL_PB003,
  MODEL_PB155,
  MODEL_2D,
  MODEL_2DS,
  MODEL_3D_4,
  MODEL_3D_5,
  MODEL_STILE,
  MODEL_TVORA1,
  MODEL_TVORA2,
  MODEL_TVORA3,
  MODEL_TVORA4,
  MODEL_TVORA5,
  MODEL_VERTICALE,
  MODEL_VERTICALE_2,
  MODEL_VERTICALE_3,
  MODEL_VERTICALE_LM,
  MODEL_VMS,
  MODEL_ECO,
  MODEL_HIGHWAY,
  MODEL_SOLAR_PANEL_POLE,
  MODEL_TABLE_SOLAR_PANEL,
  MODEL_CARPORT,
  MODEL_BALLAST,
  MODEL_EQ,
];

export default models;
