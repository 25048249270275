import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import invoke from '../../lambda';
import FormActions from '../Input/FormActions';
import BallastResponse from './Ballast_Response';

const BallastStandard = ({ form, updateForm }) => {
  const formik = useFormik({
    initialValues: {
    },
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);

      const res = await invoke({
        amount: form.quantity,
        model: 'Ballast',
        type: 'ballast',
        ...values,
      });

      const newForm = {
        ...form,
        values: {
          ...values,
        },
        response: {
          ...res.products[0],
        },
      };

      setSubmitting(false);
      updateForm(newForm);
    },
  });
  const isResponseAvailable = Object.keys(form.response).length > 0;

  return (
    <form onSubmit={formik.handleSubmit}>
      <FormActions isSubmitting={formik.isSubmitting} />
      {form.response?.error && (
        <h2 style={{ color: 'red' }}>
          Wystąpił błąd:
          {' '}
          {form.response.error}
        </h2>
      )}
      {isResponseAvailable && !form.response?.error && <BallastResponse values={form.values} response={form.response} isPdf={false} />}
    </form>
  );
};

BallastStandard.propTypes = {
  form: PropTypes.object.isRequired,
  updateForm: PropTypes.func.isRequired,
};

export default BallastStandard;
