import React from 'react';

export const COVER_BETWEEN_WINGS_YES = 'yes';
export const COVER_BETWEEN_WINGS_NO = 'no';

const CoverBetweenWings = ({ formId, onChange, value }) => (
  <div className="form-group">
    <label htmlFor={`coverBetweenWings__${formId}`}>Maskownica między skrzydłami</label>
    <select
      className="form-control"
      id={`coverBetweenWings__${formId}`}
      onChange={onChange}
      name="coverBetweenWings"
      value={value}
      required
    >
      <option value="" disabled>Wybierz</option>
      <option value={COVER_BETWEEN_WINGS_YES}>TAK</option>
      <option value={COVER_BETWEEN_WINGS_NO}>NIE</option>
    </select>
  </div>
);

export default CoverBetweenWings;
