import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import { FENCE_TYPE_GATEWAY } from 'FenceTypesConstants';
import invoke from '../../lambda';
import Poles, { POLES_LACK } from '../Input/Poles';
import RivetNutPole from '../Input/RivetNutPole';
import MaskingRod from '../Input/MaskingRod';
import Holder, { HOLDER_FORM_NAME, HOLDER_NO } from '../Input/Holder';
import Hinges from '../Input/Hinges';
import FormActions from '../Input/FormActions';
import GatewayResponse from './GatewayResponse';
import { MORE_THAN_ONE_HEIGHT_NO } from '../Input/MoreThanOneHeight';
import Arch from '../Input/Arch';
import Strip from '../Input/Strip';
import LongHandrail from '../Input/LongHandrail';

const Gateway = ({ form, updateForm }) => {
  const formik = useFormik({
    initialValues: {
      height: form.values.height || '',
      width: form.values.width || '',
      moreThanOneHeight: MORE_THAN_ONE_HEIGHT_NO,
      arch: form.values.arch || '',
      poles: form.values.poles || '',
      rivetNutPole: form.values.rivetNutPole || '',
      holder: form.values.holder || '',
      hinges: form.values.hinges || '',
      strip: form.values.strip || '',
      longHandrail: form.values.longHandrail || '',
      maskingRod: form.values.maskingRod || '',
    },
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);

      const res = await invoke({
        amount: form.quantity,
        model: 'PS001',
        type: 'gateway',
        ...values,
      });
      const newForm = {
        ...form,
        values: {
          ...values,
        },
        response: {
          ...res.products[0],
        },
      };

      setSubmitting(false);
      updateForm(newForm);
    },
    validate: ({ height, width }) => {
      const errors = {};

      if (!height) {
        errors.height = 'Wymagana wartość';
      } else if (height < 500) {
        errors.height = 'Minimalna wysokość to 500mm.';
      } else if (height > 2300) {
        errors.height = 'Maksymalna wysokość to 2300mm.';
      }
      if (!width) {
        errors.width = 'Wymagana wartość';
      } else if (width < 500) {
        errors.width = 'Minimalna szerokość to 500mm.';
      } else if (width > 1500) {
        errors.width = 'Maksymalna szerokość to 1500mm.';
      }

      return errors;
    },
  });
  useEffect(() => {
    if (formik.values.poles === POLES_LACK) {
      formik.setFieldValue(HOLDER_FORM_NAME, HOLDER_NO);
    }
  }, [formik.values.poles]);
  const isResponseAvailable = Object.keys(form.response).length > 0;

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="formInputs">
        <div className="form-group">
          <label htmlFor={`height__${form.id}`}>Wysokość (mm)</label>
          <input
            type="number"
            className="form-control"
            placeholder="Wpisz"
            id={`height__${form.id}`}
            name="height"
            value={formik.values.height}
            autoComplete="off"
            onChange={formik.handleChange}
            required
          />
          <span className="error">{formik.errors.height && formik.errors.height}</span>
        </div>
        <div className="form-group">
          <label htmlFor={`width__${form.id}`}>Szerokość (mm)</label>
          <input
            type="number"
            className="form-control"
            placeholder="Wpisz"
            id={`width__${form.id}`}
            name="width"
            value={formik.values.width}
            autoComplete="off"
            onChange={formik.handleChange}
            required
          />
          <span className="error">{formik.errors.width && formik.errors.width}</span>
        </div>
        {/* <MoreThanOneHeight */}
        {/*  formId={form.id} */}
        {/*  onChange={handleChange} */}
        {/*  value={values.moreThanOneHeight} */}
        {/* /> */}
        <Arch
          formId={form.id}
          onChange={formik.handleChange}
          value={formik.values.arch}
        />
        <Poles
          formId={form.id}
          onChange={formik.handleChange}
          fenceType={FENCE_TYPE_GATEWAY}
          value={formik.values.poles}
        />
        {formik.values.poles && formik.values.poles !== POLES_LACK && (
          <RivetNutPole
            formId={form.id}
            onChange={formik.handleChange}
            value={formik.values.rivetNutPole}
          />
        )}
        <Holder
          formId={form.id}
          onChange={formik.handleChange}
          poles={formik.values.poles}
          value={formik.values.holder}
        />
        <Hinges
          formId={form.id}
          onChange={formik.handleChange}
          fenceType={FENCE_TYPE_GATEWAY}
          value={formik.values.hinges}
        />
        {formik.values.hinges && formik.values.hinges === 'standard' && (
          <MaskingRod
            formId={form.id}
            onChange={formik.handleChange}
            value={formik.values.maskingRod}
          />
        )}
        <Strip
          formId={form.id}
          onChange={formik.handleChange}
          value={formik.values.strip}
        />
        <LongHandrail
          formId={form.id}
          onChange={formik.handleChange}
          value={formik.values.longHandrail}
        />
      </div>
      <FormActions isSubmitting={formik.isSubmitting} />
      {form.response?.error && (
        <h2 style={{ color: 'red' }}>
          Wystąpił błąd:
          {' '}
          {form.response.error}
        </h2>
      )}
      {isResponseAvailable && !form.response?.error && <GatewayResponse response={form.response} isPdf={false} />}
    </form>
  );
};

Gateway.propTypes = {
  form: PropTypes.object.isRequired,
  updateForm: PropTypes.func.isRequired,
};

export default Gateway;
