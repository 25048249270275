/* eslint-disable camelcase */
import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import { FENCE_TYPE_GATE_SELF_SUPPORTING } from 'FenceTypesConstants';
import Counterweight, {
  COUNTERWEIGHT_STANDARD,
} from '../Input/Counterweight';
import Poles, {
  POLES_GSS_ONE,
  POLES_GSS_THREE, POLES_GSS_TWO,
} from '../Input/Poles';
import Holder, { HOLDER_FORM_NAME, HOLDER_NO } from '../Input/Holder';
import GearRail from '../Input/GearRail';
import FullLength, { FULL_LENGTH_FORM_NAME } from '../Input/FullLength';
import LockAndHandrail from '../Input/LockAndHandrail';
import FormActions from '../Input/FormActions';
import GateSelfSupportingResponse from './GateSelfSupportingResponse';
import GateDirection from '../Input/GateDirection';
import Slide from '../Input/Slide';
import invoke from '../../lambda';

const GateSelfSupporting = ({ form, updateForm }) => {
  const formik = useFormik({
    initialValues: {
      height: form.values.height || '',
      width: form.values.width || '',
      poles: form.values.poles || '',
      holder: form.values.holder || '',
      gearRail: form.values.gearRail || '',
      counterweight: form.values.counterweight || '',
      fullLength: form.values.fullLength || '',
      lockAndHandrail: form.values.lockAndHandrail || '',
      gateDirection: form.values.gateDirection || '',
      slide: form.values.slide || '',
    },
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);

      const res = await invoke({
        amount: form.quantity,
        model: 'PP002(P82)_0 PINO I',
        type: 'samonosna',
        ...values,
      });

      const newForm = {
        ...form,
        values: {
          ...values,
        },
        response: {
          ...res.products[0],
        },
      };

      setSubmitting(false);
      updateForm(newForm);
    },
    validate: ({
      width,
    }) => {
      const errors = {};

      if (!width) {
        errors.width = 'Wymagana wartość';
      } else if (width < 1000) {
        errors.width = 'Minimalna szerokość to 1000mm.';
      } else if (width > 7500) {
        errors.width = 'Maksymalna szerokość to 7500mm.';
      }

      return errors;
    },
  });
  useEffect(() => {
    if (formik.values.poles === POLES_GSS_THREE || formik.values.poles === POLES_GSS_TWO || formik.values.poles === POLES_GSS_ONE) {
      formik.setFieldValue(HOLDER_FORM_NAME, HOLDER_NO);
    }
  }, [formik.values.poles]);
  useEffect(() => {
    if (formik.values.counterweight === COUNTERWEIGHT_STANDARD || formik.values.counterweight === '40' || formik.values.counterweight === '30') {
      formik.setFieldValue(FULL_LENGTH_FORM_NAME, 0);
    }
  }, [formik.values.counterweight]);
  const isResponseAvailable = Object.keys(form.response).length > 0;

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="formInputs">
          <div className="form-group">
            <label htmlFor={`height__${form.id}`}>Wysokość (mm)</label>
            <select
              className="form-control"
              placeholder="Wpisz"
              id={`height__${form.id}`}
              name="height"
              value={formik.values.height}
              onChange={formik.handleChange}
              required
            >
              <option value="" disabled>Wybierz</option>
              <option value={322}>322</option>
              <option value={402}>402</option>
              <option value={482}>482</option>
              <option value={562}>562</option>
              <option value={642}>642</option>
              <option value={722}>722</option>
              <option value={802}>802</option>
              <option value={882}>882</option>
              <option value={962}>962</option>
              <option value={1042}>1042</option>
              <option value={1122}>1122</option>
              <option value={1202}>1202</option>
              <option value={1282}>1282</option>
              <option value={1362}>1362</option>
              <option value={1442}>1442</option>
              <option value={1522}>1522</option>
              <option value={1602}>1602</option>
              <option value={1682}>1682</option>
              <option value={1762}>1762</option>
              <option value={1842}>1842</option>
              <option value={1922}>1922</option>
              <option value={2002}>2002</option>
              <option value={2082}>2082</option>
              <option value={2162}>2162</option>
              <option value={2242}>2242</option>
              <option value={2322}>2322</option>
            </select>
          </div>
          <div className="form-group">
            <label htmlFor={`width__${form.id}`}>Szerokość (mm)</label>
            <input
              type="number"
              className="form-control"
              placeholder="Wpisz"
              id={`width__${form.id}`}
              name="width"
              value={formik.values.width}
              autoComplete="off"
              onChange={formik.handleChange}
              required
            />
            <span className="error">{formik.errors.width && formik.errors.width}</span>
          </div>
          <Poles
            formId={form.id}
            onChange={formik.handleChange}
            fenceType={FENCE_TYPE_GATE_SELF_SUPPORTING}
            value={formik.values.poles}
          />
          <Holder
            formId={form.id}
            onChange={formik.handleChange}
            poles={formik.values.poles}
            value={formik.values.holder}
          />
          <GearRail formId={form.id} onChange={formik.handleChange} value={formik.values.gearRail} />
          <Counterweight
            formId={form.id}
            onChange={formik.handleChange}
            value={formik.values.counterweight}
          />
          <FullLength
            formId={form.id}
            onChange={formik.handleChange}
            counterweight={formik.values.counterweight}
            value={formik.values.fullLength}
          />
          <LockAndHandrail
            formId={form.id}
            onChange={formik.handleChange}
            value={formik.values.lockAndHandrail}
          />
          <GateDirection
            formId={form.id}
            onChange={formik.handleChange}
            value={formik.values.gateDirection}
          />
          <div className="formInputs">
            <Slide
              formId={form.id}
              onChange={formik.handleChange}
              value={formik.values.slide}
            />
          </div>
        </div>
        <FormActions isSubmitting={formik.isSubmitting} />
        {form.response?.error && (
        <h2 style={{ color: 'red' }}>
          Wystąpił błąd:
          {' '}
          {form.response.error}
        </h2>
        )}
        {isResponseAvailable && !form.response?.error && <GateSelfSupportingResponse values={form.values} response={form.response} isPdf={false} />}
      </form>
    </>
  );
};

GateSelfSupporting.propTypes = {
  form: PropTypes.object.isRequired,
  updateForm: PropTypes.func.isRequired,
};

export default GateSelfSupporting;
