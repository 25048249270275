import React from 'react';

const MachineFortificationHeight = ({ formId, onChange, value }) => (
  <div className="form-group">
    <label htmlFor={`machineFortificationHeight__${formId}`}>Wysokość wzmocnienia do automatyki</label>
    <input
      type="number"
      className="form-control"
      placeholder="Wpisz"
      id={`machineFortificationHeight__${formId}`}
      name="machineFortificationHeight"
      value={value}
      onChange={onChange}
      autoComplete="off"
      required
    />
  </div>
);

export default MachineFortificationHeight;
