import React from 'react';
import PropTypes from 'prop-types';
import Gateway from './Gateway';
import GateTilting from './GateTilting';
import GateSelfSupporting from './GateSelfSupporting';

const Tvora3 = ({ form, addNextForm, updateForm }) => {
  const FormComponent = () => {
    // TODO try to dynamically render type, to avoid switch case
    switch (form.type) {
      case 'gateway':
        return <Gateway form={form} addNextForm={addNextForm} updateForm={updateForm} />;
      case 'gateTilting':
        return <GateTilting form={form} addNextForm={addNextForm} updateForm={updateForm} />;
      case 'gateSelfSupporting':
        return <GateSelfSupporting form={form} addNextForm={addNextForm} updateForm={updateForm} />;
      default:
        throw new Error(`Unknown type (${form.type}).`);
    }
  };

  return (
    <section>
      <FormComponent />
    </section>
  );
};

Tvora3.propTypes = {
  form: PropTypes.object.isRequired,
  addNextForm: PropTypes.func.isRequired,
  updateForm: PropTypes.func.isRequired,
};

export default Tvora3;
