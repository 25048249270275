import PropTypes from 'prop-types';

const PdfHtml = (body) => `
  <html lang="pl">
    <head>
      <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
      <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css" integrity="sha384-Vkoo8x4CGsO3+Hhxv8T/Q5PaXtkKtu6ug5TOeNV6gBiFeWPGFN9MuhOf23Q9Ifjh" crossOrigin="anonymous" />
      <style>
        .new-page {
          page-break-before: always;
          padding: 4rem 0 2rem 0;
        }
        
        .new-page-inner {
          position: relative;
          min-height: 1440px;
        }
        
        .header {
          text-align: center;
        }
        
        .responseThSingular {
          width: 5%;
          text-align: left;
        }

        .responseThName {
          width: 30%;
          text-align: left;
        }

        .responseThMaterial {
          width: 30%;
          text-align: left;
        }

        .responseThQuantity {
          width: 12%;
          text-align: left;
        }

        .responseThMeasure {
          width: 12%;
          text-align: left;
        }
      
        .responseThSaws {
          width: 5%;
          text-align: center;
        }
          
        .responseThD {
          width: 5%;
          text-align: center;
        }
        
        .pdfSquare {
          display: inline-block;
          height: 25px;
          width: 25px;
          border: black 2px solid;
        }
        
        .pdfFakeSquare {
          display: inline-block;
          height: 25px;
          width: 25px;
          border: white 2px solid;
        }
        
        .fakeSawHeader {
          color: white;
        }
        
        .tdCenter {
          text-align: center;
        }
          
        /*.signature {*/
        /*  display: inline-block;*/
        /*  height: 40px;*/
        /*  width: 100%;*/
        /*  border: black 2px solid;*/
        /*  text-align: right;*/
        /*}*/
        
        .comments {
          white-space: pre;
        }
        
        #generalComment{
            white-space:pre;
            margin-top: 3rem;
        }
        
        .pageNumber {
          position: absolute;
          right: 0;
          bottom: 0;
        }
        
        #signature {
          position: absolute;
          left: 0;
          bottom: 10px;
        }
        
        #signature > div > p {
          border-bottom: 2px solid #000;
          margin-bottom: 2rem;
          width: 400px;
        }
        
        #signature > div:last-child, #signatureSecondColumn > div:last-child {
          margin-bottom: 0;
        }
        
        #signatureSecondColumn {
          position: absolute;
          right: 100px;
          bottom: 10px;
        }
        
        #signatureSecondColumn {
          margin-left: 130%;
        }
        
        #signatureSecondColumn > div > p {
          border-bottom: 2px solid #000;
          margin-bottom: 2rem;
          width: 400px;
        }
        
        .table td, .table th {
          padding: .3rem;
        }
        
        .fillingPart {
            margin-top: 3rem;
            width: 100%;
        }
        
        .fillingPart .title {
            font-weight: bold;
            font-size: 1rem;
        }
        
        .fillingLine {
            width: 100%;
            height: 3px;
            background: black;
            margin-bottom: 10px;
        }
        
        .illustration {
            height: 100px;
            width: 120px;
            margin-top: -100px;
            margin-left: 60%;
        }
        
        .illustrationSquare {
            height: 75px;
            width: 75px;
            border: black 2px solid;
        }
        
        .illustrationSquareGate {
            height: 75px;
            width: 90px;
            border: black 2px solid;
        }
        
        .illustrationHeightSymbol {
            font-size: 1rem;
            margin-left: 80px;
            margin-top: -50px; 
        }
        
        .illustrationWidthSymbol {
            font-size: 1rem;
            margin-left: 35px;
            padding-top: 7px;
        }
        
        .illustrationHeightSymbolGate {
            font-size: 1rem;
            margin-left: 95px;
            margin-top: -50px; 
        }
        
        .illustrationWidthSymbolGate {
            font-size: 1rem;
            margin-left: 45px;
            padding-top: 7px;
        }
      </style>
    </head>
    <body>
            ${body}
    </body>
  </html>
`;

PdfHtml.propTypes = {
  body: PropTypes.string.isRequired,
};

export default PdfHtml;
