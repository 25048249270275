import axios from 'axios';

const getCalculation = async (zamowieniaId) => {
  try {
    const response = await axios.get(`/production-materials-manually-calculated/${zamowieniaId}`);
    return response;
  } catch (error) {
    /* eslint-disable no-console */
    console.error(`Error while fetching calculation results: ${error}`);
  }
  return null;
};

const getCalculationWithRetry = async (zamowieniaId, order, maxRetries = 5) => {
  let retries = 0;
  let delayMS = 3000;
  const timeWithinFreshCalculation = (Date.now() - (2 * 60 * 1000)) / 1000;

  while (retries <= maxRetries) {
    /* eslint-disable no-await-in-loop */
    /* eslint-disable no-loop-func */
    await new Promise((resolve) => setTimeout(resolve, delayMS));
    /* eslint-disable no-await-in-loop */
    const response = await getCalculation(zamowieniaId);
    if (response && response.status === 200) {
      let res = null;
      response.data.products.map((product) => {
        if (product.order === Number(order) && (product.calculatedAt >= timeWithinFreshCalculation)) {
          sessionStorage.setItem('save', true);
          res = { products: [product] };
        }
        return null;
      });
      if (res) {
        return res;
      }
    }
    retries += 1;
    delayMS += (retries * 1000);
  }
  return { products: [{ error: 'Nie udało się pobrać wyników kalkulacji dla materiałów.' }] };
};

const invoke = async (attributes) => {
  const order = sessionStorage.getItem('order');
  const zamowieniaId = sessionStorage.getItem('zamowieniaId');
  const payload = {
    zamowieniaId,
    products: [
      {
        order,
        attributes,
      },
    ],
  };
  const { status } = await axios.post(
    '/orders/manual/calculate-production-materials',
    payload,
  );

  switch (status) {
    case 204:
      return getCalculationWithRetry(zamowieniaId, order);
    default:
      return { products: [{ error: 'Nie udało się wysłać zamówienia do kalkulacji materiałów.' }] };
  }
};

export default invoke;
