import React from 'react';

const GeneralComments = ({ generalComment }) => {
  if (!generalComment) {
    return null;
  }

  return (
    <>
      <div id="generalComment">
        <h2>Komentarz do zamówienia</h2>
        <p>{generalComment}</p>
      </div>
    </>
  );
};

export default GeneralComments;
