import { connect } from 'react-redux';
import { addNextForm } from '../actions';
import AddNextForm from '../components/AddNextForm';

const mapStateToProps = (state) => ({
  forms: state.app.forms,
});

const mapDispatchToProps = (dispatch) => ({
  addNextForm: (form) => dispatch(addNextForm(form)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddNextForm);
