/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import Span from './Span';
import Gateway from './Gateway';
import GatewayAccessories from './GatewayAccessories';
import GateTilting from './GateTilting';
import GateSelfSupporting from './GateSelfSupporting';
import GateTiltingAccessories from './GateTiltingAccessories';

const Verticale_LM = ({ form, addNextForm, updateForm }) => {
  const FormComponent = () => {
    switch (form.type) {
      case 'gateway':
        return <Gateway form={form} addNextForm={addNextForm} updateForm={updateForm} />;
      case 'gatewayAccessories':
        return <GatewayAccessories form={form} addNextForm={addNextForm} updateForm={updateForm} />;
      case 'span':
        return <Span form={form} addNextForm={addNextForm} updateForm={updateForm} />;
      case 'gateTilting':
        return <GateTilting form={form} addNextForm={addNextForm} updateForm={updateForm} />;
      case 'gateTiltingAccessories':
        return <GateTiltingAccessories form={form} addNextForm={addNextForm} updateForm={updateForm} />;
      case 'gateSelfSupporting':
        return <GateSelfSupporting form={form} addNextForm={addNextForm} updateForm={updateForm} />;
      default:
        throw new Error(`Unknown type (${form.type}).`);
    }
  };

  return (
    <section>
      <FormComponent />
    </section>
  );
};

Verticale_LM.propTypes = {
  form: PropTypes.object.isRequired,

  updateForm: PropTypes.func.isRequired,
};

export default Verticale_LM;
