import React from 'react';

export const SLIDE_TOGETHER = 'slideTogether';
export const SLIDE_APART = 'slideApart';

export const slideInPolish = {
  [SLIDE_TOGETHER]: 'Zjeżdża się',
  [SLIDE_APART]: 'Nie zjeżdża się',
};

const Slide = ({ formId, onChange, value }) => (
  <div className="form-group">
    <label htmlFor={`slide__${formId}`}>Zjeżdża się</label>
    <select
      className="form-control"
      id={`slide__${formId}`}
      name="slide"
      value={value}
      onChange={onChange}
      required
    >
      <option value="" disabled>Wybierz</option>
      <option value={SLIDE_TOGETHER}>TAK</option>
      <option value={SLIDE_APART}>NIE</option>
    </select>
  </div>
);

export default Slide;
