/* eslint-disable camelcase */
import React, { useState } from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { FENCE_TYPE_GATE_SELF_SUPPORTING } from 'FenceTypesConstants';
import {
  COUNTERWEIGHT_NON_STANDARD,
} from '../Input/Counterweight';
import Poles, {
  POLES_GSS_ONE,
  POLES_GSS_THREE, POLES_GSS_TWO,
} from '../Input/Poles';
import Holder, { HOLDER_FORM_NAME, HOLDER_NO } from '../Input/Holder';
import GearRail from '../Input/GearRail';
import FullLength from '../Input/FullLength';
import LockAndHandrail from '../Input/LockAndHandrail';
import FormActions from '../Input/FormActions';
import GateSelfSupportingResponse from './GateSelfSupportingResponse';
import GateDirection from '../Input/GateDirection';
import Slide from '../Input/Slide';
import invoke from '../../lambda';

const GateSelfSupporting = ({ form, updateForm }) => {
  const initialValues = {
    height: form.values.height || '',
    width: form.values.width || '',
    poles: form.values.poles || '',
    holder: form.values.holder || '',
    gearRail: form.values.gearRail || '',
    fullLength: form.values.fullLength || '',
    lockAndHandrail: form.values.lockAndHandrail || '',
    gateDirection: form.values.gateDirection || '',
    slide: form.values.slide || '',
  };

  const [tempWidth, setTempWidth] = useState('');
  const isResponseAvailable = Object.keys(form.response).length > 0;

  const polesOnChange = (e, handleChange, setFieldValue) => {
    handleChange(e);

    const value = parseInt(e.target.value);
    if (value === POLES_GSS_THREE || value === POLES_GSS_TWO || value === POLES_GSS_ONE) {
      setFieldValue(HOLDER_FORM_NAME, HOLDER_NO);
    }
  };

  const heightOnChange = (e, handleChange, width = '') => {
    handleChange(e);

    setTempWidth(width);
  };

  const widthOnChange = (e, handleChange, setFieldValue) => {
    handleChange(e);

    if (tempWidth > 5800 && e.target.value <= 5800) {
      setFieldValue('height', '');
    }
    if (e.target.value > 5800 && tempWidth <= 5800) {
      setFieldValue('height', '');
    }
  };

  const onSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);

    const res = await invoke({
      amount: form.quantity,
      model: 'PP002(P82_0)',
      type: 'samonosna',
      ...values,
    });

    const newForm = {
      ...form,
      values: {
        ...values,
      },
      response: {
        ...res.products[0],
      },
    };

    setSubmitting(false);
    updateForm(newForm);
  };

  const validation = ({
    height,
    width,
  }) => {
    const errors = {};

    if (!height) {
      errors.height = 'Wymagana wartość';
    }

    if (width && !height) {
      errors.height = 'Zwróć uwagę na wysokość,\ndostępne wartości uległy zmianie.';
    }

    if (!width) {
      errors.width = 'Wymagana wartość';
    } else if (width < 1000) {
      errors.width = 'Minimalna szerokość to 1000mm.';
    } else if (width > 7500) {
      errors.width = 'Maksymalna szerokość to 7500mm.';
    }

    return errors;
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validate={validation}
    >
      {({
        values,
        errors,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
      }) => (
        <form onSubmit={handleSubmit}>
          <div className="formInputs">
            <div className="form-group">
              <label htmlFor={`height__${form.id}`}>Wysokość (mm)</label>
              <select
                className="form-control"
                placeholder="Wpisz"
                id={`height__${form.id}`}
                name="height"
                value={values.height}
                onChange={(e) => heightOnChange(e, handleChange, values.width)}
                required
              >
                {values.width <= 5800 ? (
                  <>
                    <option value={322}>322</option>
                    <option value={402}>402</option>
                    <option value={482}>482</option>
                    <option value={562}>562</option>
                    <option value={642}>642</option>
                    <option value={722}>722</option>
                    <option value={802}>802</option>
                    <option value={882}>882</option>
                    <option value={962}>962</option>
                    <option value={1042}>1042</option>
                    <option value={1122}>1122</option>
                    <option value={1202}>1202</option>
                    <option value={1282}>1282</option>
                    <option value={1362}>1362</option>
                    <option value={1442}>1442</option>
                    <option value={1522}>1522</option>
                    <option value={1602}>1602</option>
                    <option value={1682}>1682</option>
                    <option value={1762}>1762</option>
                    <option value={1842}>1842</option>
                    <option value={1922}>1922</option>
                    <option value={2002}>2002</option>
                    <option value={2082}>2082</option>
                    <option value={2162}>2162</option>
                    <option value={2242}>2242</option>
                    <option value={2322}>2322</option>
                  </>
                ) : (
                  <>
                    <option value="" disabled>Wybierz</option>
                    <option value={362}>362</option>
                    <option value={442}>442</option>
                    <option value={522}>552</option>
                    <option value={602}>602</option>
                    <option value={682}>682</option>
                    <option value={762}>762</option>
                    <option value={842}>842</option>
                    <option value={922}>922</option>
                    <option value={1002}>1002</option>
                    <option value={1082}>1082</option>
                    <option value={1162}>1162</option>
                    <option value={1242}>1242</option>
                    <option value={1322}>1322</option>
                    <option value={1402}>1402</option>
                    <option value={1482}>1482</option>
                    <option value={1562}>1562</option>
                    <option value={1642}>1642</option>
                    <option value={1722}>1722</option>
                    <option value={1802}>1802</option>
                    <option value={1882}>1882</option>
                    <option value={1962}>1962</option>
                    <option value={2042}>2042</option>
                    <option value={2122}>2122</option>
                    <option value={2202}>2202</option>
                    <option value={2282}>2282</option>
                    <option value={2362}>2362</option>
                  </>
                )}
              </select>
              <span className="error">{errors.height && errors.height}</span>
            </div>
            <div className="form-group">
              <label htmlFor={`width__${form.id}`}>Szerokość (mm)</label>
              <input
                type="number"
                className="form-control"
                placeholder="Wpisz"
                id={`width__${form.id}`}
                name="width"
                value={values.width}
                autoComplete="off"
                onChange={(e) => widthOnChange(e, handleChange, setFieldValue)}
                required
              />
              <span className="error">{errors.width && errors.width}</span>
            </div>
            <Poles
              formId={form.id}
              onChange={(e) => polesOnChange(e, handleChange, setFieldValue)}
              fenceType={FENCE_TYPE_GATE_SELF_SUPPORTING}
              value={values.poles}
            />
            <Holder
              formId={form.id}
              onChange={handleChange}
              poles={values.poles}
              value={values.holder}
            />
            <GearRail formId={form.id} onChange={handleChange} value={values.gearRail} />
            <FullLength
              formId={form.id}
              onChange={handleChange}
              counterweight={COUNTERWEIGHT_NON_STANDARD}
              value={values.fullLength}
            />
            <LockAndHandrail
              formId={form.id}
              onChange={handleChange}
              value={values.lockAndHandrail}
            />
            <GateDirection
              formId={form.id}
              onChange={handleChange}
              value={values.gateDirection}
            />
            <div className="formInputs">
              <Slide
                formId={form.id}
                onChange={handleChange}
                value={values.slide}
              />
            </div>
          </div>
          <FormActions isSubmitting={isSubmitting} />
          {form.response?.error && (
          <h2 style={{ color: 'red' }}>
            Wystąpił błąd:
            {' '}
            {form.response.error}
          </h2>
          )}
          {isResponseAvailable && !form.response?.error && <GateSelfSupportingResponse values={form.values} response={form.response} isPdf={false} />}
        </form>
      )}
    </Formik>
  );
};

GateSelfSupporting.propTypes = {
  form: PropTypes.object.isRequired,
  updateForm: PropTypes.func.isRequired,
};

export default GateSelfSupporting;
