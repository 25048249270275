import React from 'react';

const MachinePosition = ({ formId, onChange, value }) => (
  <div className="form-group">
    <label htmlFor={`machinePosition__${formId}`}>Położenie automatyki (mm)</label>
    <input
      type="number"
      className="form-control"
      placeholder="Wpisz"
      id={`machinePosition__${formId}`}
      name="machinePosition"
      value={value}
      onChange={onChange}
      autoComplete="off"
      required
    />
  </div>
);

export default MachinePosition;
