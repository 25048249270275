import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import Machine from 'components/Input/Machine';
import FormActions from '../Input/FormActions';
import GateTiltingResponse from './GateTiltingResponse';
import invoke from '../../lambda';

const GateTilting = ({ form, updateForm }) => {
  const formik = useFormik({
    initialValues: {
      height: form.values.height || '',
      width: 4000,
      machine: form.values.machine || '',
      poles: '',
      rivetNutPole: '',
      holder: '',
      hinges: '',
      coverBetweenWings: '',
    },
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);

      const res = await invoke({
        amount: form.quantity,
        model: 'VerticaleLM',
        type: 'gate_tilting',
        ...values,
      });

      const newForm = {
        ...form,
        values: {
          ...values,
        },
        response: {
          ...res.products[0],
        },
      };

      setSubmitting(false);
      updateForm(newForm);
    },
  });
  const isResponseAvailable = Object.keys(form.response).length > 0;

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="formInputs">
        <div className="form-group">
          <label htmlFor={`height__${form.id}`}>Wysokość (mm)</label>
          <select
            type="number"
            className="form-control"
            placeholder="Wybierz"
            id={`height__${form.id}`}
            name="height"
            value={formik.values.height}
            onChange={formik.handleChange}
            required
          >
            <option value="" disabled>Wybierz</option>
            <option value={1310}>1310</option>
            <option value={1540}>1540</option>
            <option value={1640}>1640</option>
            <option value={1810}>1810</option>
          </select>
        </div>
        <Machine
          formId={form.id}
          onChange={formik.handleChange}
          value={formik.values.machine}
        />
      </div>
      <FormActions isSubmitting={formik.isSubmitting} />
      {form.response?.error && (
        <h2 style={{ color: 'red' }}>
          Wystąpił błąd:
          {' '}
          {form.response.error}
        </h2>
      )}
      {isResponseAvailable && !form.response?.error && <GateTiltingResponse values={formik.values} response={form.response} isPdf={false} />}
    </form>
  );
};

GateTilting.propTypes = {
  form: PropTypes.object.isRequired,
  updateForm: PropTypes.func.isRequired,
};

export default GateTilting;
