import React from 'react';

export const COMPLETE_MOUNTING_KIT_YES = 'yes';
export const COMPLETE_MOUNTING_KIT_NO = 'no';

const CompleteMountingKit = ({ formId, onChange, value }) => (
  <div className="form-group">
    <label htmlFor={`completeMountingKit__${formId}`}>Komplet podstaw montażowych</label>
    <select
      className="form-control"
      id={`completeMountingKit__${formId}`}
      name="completeMountingKit"
      value={value}
      onChange={onChange}
      required
    >
      <option value="" disabled>Wybierz</option>
      <option value={COMPLETE_MOUNTING_KIT_YES}>TAK</option>
      <option value={COMPLETE_MOUNTING_KIT_NO}>NIE</option>
    </select>
  </div>
);

export default CompleteMountingKit;
