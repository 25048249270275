import React from 'react';
import PropTypes from 'prop-types';
import BallastStandard from './Ballast_standard';
import Windchest from './Windchest';

const Ballast = ({ form, addNextForm, updateForm }) => {
  const FormComponent = () => {
    // TODO try to dynamically render type, to avoid switch case
    switch (form.type) {
      case 'ballast':
        return <BallastStandard form={form} addNextForm={addNextForm} updateForm={updateForm} />;
      case 'windchest':
        return <Windchest form={form} addNextForm={addNextForm} updateForm={updateForm} />;
      default:
        throw new Error(`Unknown type (${form.type}).`);
    }
  };

  return (
    <section>
      <FormComponent />
    </section>
  );
};

Ballast.propTypes = {
  form: PropTypes.object.isRequired,

  updateForm: PropTypes.func.isRequired,
};

export default Ballast;
