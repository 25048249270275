import React from 'react';

export const MORE_THAN_ONE_HEIGHT_YES = 'yes';
export const MORE_THAN_ONE_HEIGHT_NO = 'no';

const MoreThanOneHeight = ({ formId, onChange, value }) => (
  <div className="form-group">
    <label htmlFor={`moreThanOneHeight__${formId}`}>Więcej niż jedna wysokość</label>
    <select
      className="form-control"
      id={`moreThanOneHeight__${formId}`}
      name="moreThanOneHeight"
      value={value || ''}
      onChange={onChange}
      required
    >
      <option value="" disabled>Wybierz</option>
      <option value={MORE_THAN_ONE_HEIGHT_YES}>TAK</option>
      <option value={MORE_THAN_ONE_HEIGHT_NO}>NIE</option>
    </select>
  </div>
);

export default MoreThanOneHeight;
