import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { canShowActions } from './AddNextForm';

const CloseProject = () => {
  const [showModal, setShowModal] = useState(false);

  const forms = useSelector((state) => state.app.forms);

  const showActions = canShowActions(forms);
  if (!showActions) {
    return null;
  }

  const closeApp = () => {
    window.location.reload();
  };

  const Modal = () => (
    <div
      className="modal"
      id="closingModal"
      role="dialog"
      style={{ display: 'block', paddingTop: '25vh' }}
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-body">
            <p>Czy na pewno chcesz zakończyć pracę nad obecnym kalkulatorem?</p>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-danger"
              onClick={closeApp}
            >
              Tak, chcę zakończyć.
            </button>
            <button
              type="button"
              className="btn btn-success"
              onClick={() => setShowModal(false)}
            >
              Nie, chcę zostać.
            </button>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div>
      {showModal && <Modal />}
      <div className="container-xl">
        <div className="row">
          <div className="col">
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <button
                type="button"
                className="btn btn-danger btn-primary"
                onClick={() => setShowModal(true)}
              >
                Zakończ
              </button>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
};

export default CloseProject;
