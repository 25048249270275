import React from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import './AddNextForm.css';

export const formBase = () => ({
  id: uuidv4(),
  model: null,
  type: null,
  productSingular: null,
  addNextFormWasClicked: false,
  show: true,
  values: {},
  response: {},
});

export const canShowActions = (forms) => forms.length && Object.keys(forms[forms.length - 1].response).length > 0;

const AddNextForm = ({ forms, addNextForm }) => {
  const showActions = canShowActions(forms);
  if (!showActions) {
    return null;
  }

  const onClick = (e) => {
    e.preventDefault();

    addNextForm(formBase());
  };

  return (
    <article className="nextFormActions">
      <div className="container-xl">
        <div className="row">
          <div className="col">
            <div className="finishBtns">
              <button
                type="button"
                className="btn btn-danger"
                onClick={onClick}
              >
                Dodaj kolejny
              </button>
            </div>
          </div>
        </div>
      </div>
    </article>
  );
};

AddNextForm.propTypes = {
  forms: PropTypes.array.isRequired,
  addNextForm: PropTypes.func.isRequired,
};

export default AddNextForm;
