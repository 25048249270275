import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import FormActions from '../Input/FormActions';
import GateTiltingAccessoriesResponse from './GateTiltingAccessoriesResponse';
import invoke from '../../lambda';

const GateTiltingAccessories = ({ form, updateForm }) => {
  const formik = useFormik({
    initialValues: {
      height: '',
      width: 4000,
      accessories: form.values.accessories || '',
      accessoriesModel: true,
      machine: '',
      poles: '',
      rivetNutPole: '',
      holder: '',
      hinges: '',
      coverBetweenWings: '',
    },
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);

      const res = await invoke({
        amount: form.quantity,
        model: 'VerticaleLM',
        type: 'gate_tilting',
        ...values,
      });

      const newForm = {
        ...form,
        values: {
          ...values,
        },
        response: {
          ...res.products[0],
        },
      };

      setSubmitting(false);
      updateForm(newForm);
    },
  });
  const isResponseAvailable = Object.keys(form.response).length > 0;

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="formInputs">
        <div className="form-group">
          <label htmlFor={`accessories__${form.id}`}>Pudełko z akcesoriami</label>
          <select
            type="number"
            className="form-control"
            placeholder="Wybierz"
            id={`accessories__${form.id}`}
            name="accessories"
            value={formik.values.accessories}
            onChange={formik.handleChange}
            required
          >
            <option value="" disabled>Wybierz</option>
            <option value={4}>Zestaw nr 4</option>
          </select>
        </div>
      </div>
      <FormActions isSubmitting={formik.isSubmitting} />
      {form.response?.error && (
        <h2 style={{ color: 'red' }}>
          Wystąpił błąd:
          {' '}
          {form.response.error}
        </h2>
      )}
      {isResponseAvailable && !form.response?.error && <GateTiltingAccessoriesResponse values={formik.values} response={form.response} isPdf={false} />}
    </form>
  );
};

GateTiltingAccessories.propTypes = {
  form: PropTypes.object.isRequired,
  updateForm: PropTypes.func.isRequired,
};

export default GateTiltingAccessories;
