import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import invoke from '../../lambda';
import FormActions from '../Input/FormActions';
import PoleSolarPanelResponse from './PoleSolarPanelResponse';

const PoleSolarPanel = ({ form, updateForm }) => {
  const formik = useFormik({
    initialValues: {
      height: form.values.height || '',
      direction: form.values.direction || '',
      holder: form.values.holder || '',
      profile_2500: form.values.profile_2500 || '',
      profile_1250: form.values.profile_1250 || '',
      profile_400: form.values.profile_400 || '',
    },
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);

      const res = await invoke({
        amount: form.quantity,
        model: 'solar_panel_poles',
        type: 'solar_panel_poles',
        ...values,
      });

      const newForm = {
        ...form,
        values: {
          ...values,
        },
        response: {
          ...res.products[0],
        },
      };

      setSubmitting(false);
      updateForm(newForm);
    },
  });
  const isResponseAvailable = Object.keys(form.response).length > 0;

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="formInputs">
        <div className="form-group">
          <label htmlFor={`height__${form.id}`}>Wysokość (mm)</label>
          <input
            type="number"
            className="form-control"
            placeholder="Wpisz"
            id={`height__${form.id}`}
            name="height"
            autoComplete="off"
            value={formik.values.height}
            onChange={formik.handleChange}
            required
          />
          <span className="error">{formik.errors.height && formik.errors.height}</span>
        </div>
        <div className="form-group">
          <label htmlFor={`direction__${form.id}`}>Kierunek</label>
          <select
            className="form-control"
            id={`direction__${form.id}`}
            onChange={formik.handleChange}
            name="direction"
            value={formik.values.direction}
            required
          >
            <option value="" disabled>Wybierz</option>
            <option value="direction_up">Góra</option>
            <option value="direction_down">Dół</option>
          </select>
        </div>
        <div className="form-group">
          <label htmlFor={`holder__${form.id}`}>Marka</label>
          <select
            className="form-control"
            id={`holder__${form.id}`}
            onChange={formik.handleChange}
            name="holder"
            value={formik.values.holder}
            required
          >
            <option value="" disabled>Wybierz</option>
            <option value="holder_yes">Tak</option>
            <option value="holder_no">Nie</option>
          </select>
        </div>
        <div className="form-group">
          <label htmlFor={`profile_2500__${form.id}`}>PROFIL PÓŁZAMKNIĘTY 2500mm</label>
          <input
            type="number"
            className="form-control"
            placeholder="Wpisz"
            id={`profile_2500__${form.id}`}
            name="profile_2500"
            autoComplete="off"
            value={formik.values.profile_2500}
            onChange={formik.handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor={`profile_1250__${form.id}`}>PROFIL PÓŁZAMKNIĘTY 1250mm</label>
          <input
            type="number"
            className="form-control"
            placeholder="Wpisz"
            id={`profile_1250__${form.id}`}
            name="profile_1250"
            autoComplete="off"
            value={formik.values.profile_1250}
            onChange={formik.handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor={`profile_400__${form.id}`}>PROFIL PÓŁZAMKNIĘTY 400mm</label>
          <input
            type="number"
            className="form-control"
            placeholder="Wpisz"
            id={`profile_400__${form.id}`}
            name="profile_400"
            autoComplete="off"
            value={formik.values.profile_400}
            onChange={formik.handleChange}
            required
          />
        </div>
      </div>
      <FormActions isSubmitting={formik.isSubmitting} />
      {form.response?.error && (
        <h2 style={{ color: 'red' }}>
          Wystąpił błąd:
          {' '}
          {form.response.error}
        </h2>
      )}
      {isResponseAvailable && !form.response?.error && <PoleSolarPanelResponse values={form.values} response={form.response} isPdf={false} />}
    </form>
  );
};

PoleSolarPanel.propTypes = {
  form: PropTypes.object.isRequired,
  updateForm: PropTypes.func.isRequired,
};

export default PoleSolarPanel;
