import React from 'react';
import {
  FENCE_TYPE_GATE_GATEWAY, FENCE_TYPE_GATE_TILTING, FENCE_TYPE_NON_SYMMETRICAL_GATE, FENCE_TYPE_SINGLE_LEAF_GATE,
} from 'FenceTypesConstants';
import {
  MODEL_PP001C, MODEL_PP001CC, MODEL_PP001W, MODEL_PP002, MODEL_PP002P, MODEL_PP002P102_0, MODEL_PP002P82_0,
} from 'ModelsConstants';

const Info = ({ formResponseProductionMaterials }) => {
  if (formResponseProductionMaterials?.info?.areaAmount) {
    return (
      <p>
        <b>Ilość pól:</b>
        {' '}
        {formResponseProductionMaterials.info.areaAmount.value}
        {' '}
        {formResponseProductionMaterials.info.areaAmount.unit}
        {' | '}
        <b>Wysokość pola:</b>
        {' '}
        {formResponseProductionMaterials.info.areaHeight.value}
        {' '}
        {formResponseProductionMaterials.info.areaHeight.unit}
        {' | '}
        <b>Szerokość pola:</b>
        {' '}
        {formResponseProductionMaterials.info.areaWidth.value}
        {' '}
        {formResponseProductionMaterials.info.areaWidth.unit}
      </p>
    );
  }
  if (formResponseProductionMaterials?.infoAboveMachine) {
    return (
      <>
        <p>
          <b>Ilość pól nad automatem:</b>
          {' '}
          {formResponseProductionMaterials.infoAboveMachine.areaAmount.value}
          {' '}
          {formResponseProductionMaterials.infoAboveMachine.areaAmount.unit}
          {' | '}
          <b>Wysokość pola:</b>
          {' '}
          {formResponseProductionMaterials.infoAboveMachine.areaHeight.value}
          {' '}
          {formResponseProductionMaterials.infoAboveMachine.areaHeight.unit}
          {' | '}
          <b>Szerokość pola:</b>
          {' '}
          {formResponseProductionMaterials.infoAboveMachine.areaWidth.value}
          {' '}
          {formResponseProductionMaterials.infoAboveMachine.areaWidth.unit}
        </p>
        <p>
          <b>Ilość pól pod automatem:</b>
          {' '}
          {formResponseProductionMaterials.infoUnderMachine.areaAmount.value}
          {' '}
          {formResponseProductionMaterials.infoUnderMachine.areaAmount.unit}
          {' | '}
          <b>Wysokość pola:</b>
          {' '}
          {formResponseProductionMaterials.infoUnderMachine.areaHeight.value}
          {' '}
          {formResponseProductionMaterials.infoUnderMachine.areaHeight.unit}
          {' | '}
          <b>Szerokość pola:</b>
          {' '}
          {formResponseProductionMaterials.infoUnderMachine.areaWidth.value}
          {' '}
          {formResponseProductionMaterials.infoUnderMachine.areaWidth.unit}
        </p>
      </>
    );
  }
  return null;
};

const InfoPP002P = ({ formResponseProductionMaterials }) => {
  if (formResponseProductionMaterials?.info?.areaAmount) {
    return (
      <p>
        <b>Ilość pól:</b>
        {' '}
        {formResponseProductionMaterials.info.areaAmount.value}
        {' '}
        {formResponseProductionMaterials.info.areaAmount.unit}
        {' | '}
        <b>Wysokość pola:</b>
        {' '}
        {formResponseProductionMaterials.info.areaHeight.value}
        {' '}
        {formResponseProductionMaterials.info.areaHeight.unit}
        {' | '}
        <b>Szerokość pola:</b>
        {' '}
        {formResponseProductionMaterials.info.areaWidth.value}
        {' '}
        {formResponseProductionMaterials.info.areaWidth.unit}
        {' | '}
        <b>Rozstaw:</b>
        {' '}
        {formResponseProductionMaterials.info.spacing.value}
        {' '}
        {formResponseProductionMaterials.info.spacing.unit}
      </p>
    );
  }
  if (formResponseProductionMaterials?.infoAboveMachine?.areaAmount) {
    return (
      <>
        <p>
          <b>Ilość pól nad automatem:</b>
          {' '}
          {formResponseProductionMaterials.infoAboveMachine.areaAmount.value}
          {' '}
          {formResponseProductionMaterials.infoAboveMachine.areaAmount.unit}
          {' | '}
          <b>Wysokość pola:</b>
          {' '}
          {formResponseProductionMaterials.infoAboveMachine.areaHeight.value}
          {' '}
          {formResponseProductionMaterials.infoAboveMachine.areaHeight.unit}
          {' | '}
          <b>Szerokość pola:</b>
          {' '}
          {formResponseProductionMaterials.infoAboveMachine.areaWidth.value}
          {' '}
          {formResponseProductionMaterials.infoAboveMachine.areaWidth.unit}
          {' | '}
          <b>Rozstaw:</b>
          {' '}
          {formResponseProductionMaterials.infoAboveMachine.spacing.value}
          {' '}
          {formResponseProductionMaterials.infoAboveMachine.spacing.unit}
          {' | '}
          <b>Wysokość automatu:</b>
          {' '}
          {formResponseProductionMaterials.infoAboveMachine.machineHeight.value}
          {' '}
          {formResponseProductionMaterials.infoAboveMachine.machineHeight.unit}
        </p>
        <p>
          <b>Ilość pól pod automatem:</b>
          {' '}
          {formResponseProductionMaterials.infoUnderMachine.areaAmount.value}
          {' '}
          {formResponseProductionMaterials.infoUnderMachine.areaAmount.unit}
          {' | '}
          <b>Wysokość pola:</b>
          {' '}
          {formResponseProductionMaterials.infoUnderMachine.areaHeight.value}
          {' '}
          {formResponseProductionMaterials.infoUnderMachine.areaHeight.unit}
          {' | '}
          <b>Szerokość pola:</b>
          {' '}
          {formResponseProductionMaterials.infoUnderMachine.areaWidth.value}
          {' '}
          {formResponseProductionMaterials.infoUnderMachine.areaWidth.unit}
          {' | '}
          <b>Rozstaw:</b>
          {' '}
          {formResponseProductionMaterials.infoUnderMachine.spacing.value}
          {' '}
          {formResponseProductionMaterials.infoUnderMachine.spacing.unit}
          {' | '}
          <b>Wysokość automatu:</b>
          {' '}
          {formResponseProductionMaterials.infoUnderMachine.machineHeight.value}
          {' '}
          {formResponseProductionMaterials.infoUnderMachine.machineHeight.unit}
        </p>
      </>
    );
  }
  return null;
};

const InfoPP002PNP = ({ formResponseProductionMaterials }) => {
  if (formResponseProductionMaterials?.infoLeft?.areaAmount) {
    return (
      <>
        <p><b>LEWA STRONA</b></p>
        <p>
          <b>Ilość pól:</b>
          {' '}
          {formResponseProductionMaterials.infoLeft.areaAmount.value}
          {' '}
          {formResponseProductionMaterials.infoLeft.areaAmount.unit}
          {' | '}
          <b>Wysokość pola:</b>
          {' '}
          {formResponseProductionMaterials.infoLeft.areaHeight.value}
          {' '}
          {formResponseProductionMaterials.infoLeft.areaHeight.unit}
          {' | '}
          <b>Szerokość pola:</b>
          {' '}
          {formResponseProductionMaterials.infoLeft.areaWidth.value}
          {' '}
          {formResponseProductionMaterials.infoLeft.areaWidth.unit}
          {' | '}
          <b>Rozstaw:</b>
          {' '}
          {formResponseProductionMaterials.infoLeft.spacing.value}
          {' '}
          {formResponseProductionMaterials.infoLeft.spacing.unit}
        </p>
        <p><b>PRAWA STRONA</b></p>
        <p>
          <b>Ilość pól:</b>
          {' '}
          {formResponseProductionMaterials.infoRight.areaAmount.value}
          {' '}
          {formResponseProductionMaterials.infoRight.areaAmount.unit}
          {' | '}
          <b>Wysokość pola:</b>
          {' '}
          {formResponseProductionMaterials.infoRight.areaHeight.value}
          {' '}
          {formResponseProductionMaterials.infoRight.areaHeight.unit}
          {' | '}
          <b>Szerokość pola:</b>
          {' '}
          {formResponseProductionMaterials.infoRight.areaWidth.value}
          {' '}
          {formResponseProductionMaterials.infoRight.areaWidth.unit}
          {' | '}
          <b>Rozstaw:</b>
          {' '}
          {formResponseProductionMaterials.infoRight.spacing.value}
          {' '}
          {formResponseProductionMaterials.infoRight.spacing.unit}
        </p>
      </>
    );
  }
  if (formResponseProductionMaterials?.infoLeftAboveMachine?.areaAmount) {
    return (
      <>
        <p><b>LEWA STRONA</b></p>
        <p>
          <b>Ilość pól nad automatem:</b>
          {' '}
          {formResponseProductionMaterials.infoLeftAboveMachine.areaAmount.value}
          {' '}
          {formResponseProductionMaterials.infoLeftAboveMachine.areaAmount.unit}
          {' | '}
          <b>Wysokość pola:</b>
          {' '}
          {formResponseProductionMaterials.infoLeftAboveMachine.areaHeight.value}
          {' '}
          {formResponseProductionMaterials.infoLeftAboveMachine.areaHeight.unit}
          {' | '}
          <b>Szerokość pola:</b>
          {' '}
          {formResponseProductionMaterials.infoLeftAboveMachine.areaWidth.value}
          {' '}
          {formResponseProductionMaterials.infoLeftAboveMachine.areaWidth.unit}
          {' | '}
          <b>Rozstaw:</b>
          {' '}
          {formResponseProductionMaterials.infoLeftAboveMachine.spacing.value}
          {' '}
          {formResponseProductionMaterials.infoLeftAboveMachine.spacing.unit}
          {' | '}
          <b>Wysokość automatu:</b>
          {' '}
          {formResponseProductionMaterials.infoLeftAboveMachine.machineHeight.value}
          {' '}
          {formResponseProductionMaterials.infoLeftAboveMachine.machineHeight.unit}
        </p>
        <p>
          <b>Ilość pól pod automatem:</b>
          {' '}
          {formResponseProductionMaterials.infoLeftUnderMachine.areaAmount.value}
          {' '}
          {formResponseProductionMaterials.infoLeftUnderMachine.areaAmount.unit}
          {' | '}
          <b>Wysokość pola:</b>
          {' '}
          {formResponseProductionMaterials.infoLeftUnderMachine.areaHeight.value}
          {' '}
          {formResponseProductionMaterials.infoLeftUnderMachine.areaHeight.unit}
          {' | '}
          <b>Szerokość pola:</b>
          {' '}
          {formResponseProductionMaterials.infoLeftUnderMachine.areaWidth.value}
          {' '}
          {formResponseProductionMaterials.infoLeftUnderMachine.areaWidth.unit}
          {' | '}
          <b>Rozstaw:</b>
          {' '}
          {formResponseProductionMaterials.infoLeftUnderMachine.spacing.value}
          {' '}
          {formResponseProductionMaterials.infoLeftUnderMachine.spacing.unit}
          {' | '}
          <b>Wysokość automatu:</b>
          {' '}
          {formResponseProductionMaterials.infoLeftUnderMachine.machineHeight.value}
          {' '}
          {formResponseProductionMaterials.infoLeftUnderMachine.machineHeight.unit}
        </p>
        <p><b>PRAWA STRONA</b></p>
        <p>
          <b>Ilość pól nad automatem:</b>
          {' '}
          {formResponseProductionMaterials.infoRightAboveMachine.areaAmount.value}
          {' '}
          {formResponseProductionMaterials.infoRightAboveMachine.areaAmount.unit}
          {' | '}
          <b>Wysokość pola:</b>
          {' '}
          {formResponseProductionMaterials.infoRightAboveMachine.areaHeight.value}
          {' '}
          {formResponseProductionMaterials.infoRightAboveMachine.areaHeight.unit}
          {' | '}
          <b>Szerokość pola:</b>
          {' '}
          {formResponseProductionMaterials.infoRightAboveMachine.areaWidth.value}
          {' '}
          {formResponseProductionMaterials.infoRightAboveMachine.areaWidth.unit}
          {' | '}
          <b>Rozstaw:</b>
          {' '}
          {formResponseProductionMaterials.infoRightAboveMachine.spacing.value}
          {' '}
          {formResponseProductionMaterials.infoRightAboveMachine.spacing.unit}
          {' | '}
          <b>Wysokość automatu:</b>
          {' '}
          {formResponseProductionMaterials.infoRightAboveMachine.machineHeight.value}
          {' '}
          {formResponseProductionMaterials.infoRightAboveMachine.machineHeight.unit}
        </p>
        <p>
          <b>Ilość pól pod automatem:</b>
          {' '}
          {formResponseProductionMaterials.infoRightUnderMachine.areaAmount.value}
          {' '}
          {formResponseProductionMaterials.infoRightUnderMachine.areaAmount.unit}
          {' | '}
          <b>Wysokość pola:</b>
          {' '}
          {formResponseProductionMaterials.infoRightUnderMachine.areaHeight.value}
          {' '}
          {formResponseProductionMaterials.infoRightUnderMachine.areaHeight.unit}
          {' | '}
          <b>Szerokość pola:</b>
          {' '}
          {formResponseProductionMaterials.infoRightUnderMachine.areaWidth.value}
          {' '}
          {formResponseProductionMaterials.infoRightUnderMachine.areaWidth.unit}
          {' | '}
          <b>Rozstaw:</b>
          {' '}
          {formResponseProductionMaterials.infoRightUnderMachine.spacing.value}
          {' '}
          {formResponseProductionMaterials.infoRightUnderMachine.spacing.unit}
          {' | '}
          <b>Wysokość automatu:</b>
          {' '}
          {formResponseProductionMaterials.infoRightUnderMachine.machineHeight.value}
          {' '}
          {formResponseProductionMaterials.infoRightUnderMachine.machineHeight.unit}
        </p>
      </>
    );
  }
  return null;
};

const InfoPP001CNS = ({ formResponseProductionMaterials }) => {
  if (!formResponseProductionMaterials?.spacingLeft) {
    return null;
  }
  return (
    <>
      <p><b>LEWA STRONA</b></p>
      <p>
        <b>Rozstaw:</b>
        {' '}
        {formResponseProductionMaterials.spacingLeft.size.value}
        {' '}
        {formResponseProductionMaterials.spacingLeft.size.unit}
      </p>
      <p><b>PRAWA STRONA</b></p>
      <p>
        <b>Rozstaw:</b>
        {' '}
        {formResponseProductionMaterials.spacingRight.size.value}
        {' '}
        {formResponseProductionMaterials.spacingRight.size.unit}
      </p>
    </>
  );
};

const InfoNonSymmetrical = ({ formResponseProductionMaterials }) => {
  if (formResponseProductionMaterials?.info?.leftWing?.areaAmount) {
    return (
      <>
        <span>LEWA STRONA</span>
        <p>
          <b>Ilość pól:</b>
          {' '}
          {formResponseProductionMaterials.info.leftWing.areaAmount.value}
          {' '}
          {formResponseProductionMaterials.info.leftWing.areaAmount.unit}
          {' | '}
          <b>Wysokość pola:</b>
          {' '}
          {formResponseProductionMaterials.info.leftWing.areaHeight.value}
          {' '}
          {formResponseProductionMaterials.info.leftWing.areaHeight.unit}
          {' | '}
          <b>Szerokość pola:</b>
          {' '}
          {formResponseProductionMaterials.info.leftWing.areaWidth.value}
          {' '}
          {formResponseProductionMaterials.info.leftWing.areaWidth.unit}
        </p>
        <span>PRAWA STRONA</span>
        <p>
          <b>Ilość pól:</b>
          {' '}
          {formResponseProductionMaterials.info.rightWing.areaAmount.value}
          {' '}
          {formResponseProductionMaterials.info.rightWing.areaAmount.unit}
          {' | '}
          <b>Wysokość pola:</b>
          {' '}
          {formResponseProductionMaterials.info.rightWing.areaHeight.value}
          {' '}
          {formResponseProductionMaterials.info.rightWing.areaHeight.unit}
          {' | '}
          <b>Szerokość pola:</b>
          {' '}
          {formResponseProductionMaterials.info.rightWing.areaWidth.value}
          {' '}
          {formResponseProductionMaterials.info.rightWing.areaWidth.unit}
        </p>
      </>
    );
  }
  if (formResponseProductionMaterials?.infoAboveMachine?.leftWing?.areaAmount) {
    return (
      <>
        <p><b>LEWA STRONA</b></p>
        <p>
          <b>Ilość pól nad automatem:</b>
          {' '}
          {formResponseProductionMaterials.infoAboveMachine.leftWing.areaAmount.value}
          {' '}
          {formResponseProductionMaterials.infoAboveMachine.leftWing.areaAmount.unit}
          {' | '}
          <b>Wysokość pola:</b>
          {' '}
          {formResponseProductionMaterials.infoAboveMachine.leftWing.areaHeight.value}
          {' '}
          {formResponseProductionMaterials.infoAboveMachine.leftWing.areaHeight.unit}
          {' | '}
          <b>Szerokość pola:</b>
          {' '}
          {formResponseProductionMaterials.infoAboveMachine.leftWing.areaWidth.value}
          {' '}
          {formResponseProductionMaterials.infoAboveMachine.leftWing.areaWidth.unit}
        </p>
        <p>
          <b>Ilość pól pod automatem:</b>
          {' '}
          {formResponseProductionMaterials.infoUnderMachine.leftWing.areaAmount.value}
          {' '}
          {formResponseProductionMaterials.infoUnderMachine.leftWing.areaAmount.unit}
          {' | '}
          <b>Wysokość pola:</b>
          {' '}
          {formResponseProductionMaterials.infoUnderMachine.leftWing.areaHeight.value}
          {' '}
          {formResponseProductionMaterials.infoUnderMachine.leftWing.areaHeight.unit}
          {' | '}
          <b>Szerokość pola:</b>
          {' '}
          {formResponseProductionMaterials.infoUnderMachine.leftWing.areaWidth.value}
          {' '}
          {formResponseProductionMaterials.infoUnderMachine.leftWing.areaWidth.unit}
        </p>
        <p><b>PRAWA STRONA</b></p>
        <p>
          <b>Ilość pól nad automatem:</b>
          {' '}
          {formResponseProductionMaterials.infoAboveMachine.rightWing.areaAmount.value}
          {' '}
          {formResponseProductionMaterials.infoAboveMachine.rightWing.areaAmount.unit}
          {' | '}
          <b>Wysokość pola:</b>
          {' '}
          {formResponseProductionMaterials.infoAboveMachine.rightWing.areaHeight.value}
          {' '}
          {formResponseProductionMaterials.infoAboveMachine.rightWing.areaHeight.unit}
          {' | '}
          <b>Szerokość pola:</b>
          {' '}
          {formResponseProductionMaterials.infoAboveMachine.rightWing.areaWidth.value}
          {' '}
          {formResponseProductionMaterials.infoAboveMachine.rightWing.areaWidth.unit}
        </p>
        <p>
          <b>Ilość pól pod automatem:</b>
          {' '}
          {formResponseProductionMaterials.infoUnderMachine.rightWing.areaAmount.value}
          {' '}
          {formResponseProductionMaterials.infoUnderMachine.rightWing.areaAmount.unit}
          {' | '}
          <b>Wysokość pola:</b>
          {' '}
          {formResponseProductionMaterials.infoUnderMachine.rightWing.areaHeight.value}
          {' '}
          {formResponseProductionMaterials.infoUnderMachine.rightWing.areaHeight.unit}
          {' | '}
          <b>Szerokość pola:</b>
          {' '}
          {formResponseProductionMaterials.infoUnderMachine.rightWing.areaWidth.value}
          {' '}
          {formResponseProductionMaterials.infoUnderMachine.rightWing.areaWidth.unit}
        </p>
      </>
    );
  }
  return null;
};

const GeneralInfo = ({ form }) => {
  const formResponseProductionMaterials = form.response.productionMaterials;

  if (form.model === MODEL_PP001C || form.model === MODEL_PP001CC || form.model === MODEL_PP001W) {
    if (form.type !== FENCE_TYPE_NON_SYMMETRICAL_GATE && form.type !== FENCE_TYPE_GATE_GATEWAY) {
      return null;
    }
  }

  return (
    <div>
      <table className="table">
        <thead>
          <tr>
            <th colSpan="7">INFORMACJE OGÓLNE</th>
          </tr>
        </thead>
      </table>
      {(form.model === MODEL_PP002P82_0 || form.model === MODEL_PP002P102_0) && form.response.productionMaterials.info.productionHeight && (
        <p>
          <b>Wysokość produkcji:</b>
          {' '}
          {form.response.productionMaterials.info.productionHeight.value}
          {' '}
          mm
        </p>
      )}
      {form.model !== MODEL_PP002P && (form.model === MODEL_PP001C || form.model === MODEL_PP001CC || form.model === MODEL_PP001W || form.model === MODEL_PP002) && (form.type === FENCE_TYPE_NON_SYMMETRICAL_GATE || form.type === FENCE_TYPE_GATE_GATEWAY) && (
        <InfoPP001CNS formResponseProductionMaterials={formResponseProductionMaterials} />
      )}
      {(form.model === MODEL_PP002P && ((form.type === FENCE_TYPE_GATE_TILTING && form.values.width >= 6000) || (form.type === FENCE_TYPE_SINGLE_LEAF_GATE && form.values.width >= 3000))) && (
        <InfoPP002P formResponseProductionMaterials={formResponseProductionMaterials} />
      )}
      {(form.model === MODEL_PP002P && (form.type === FENCE_TYPE_NON_SYMMETRICAL_GATE || form.type === FENCE_TYPE_GATE_GATEWAY) && (form.values.leftWingwidth >= 3000 || form.values.rightWingWidth >= 3000)) && (
        <InfoPP002PNP formResponseProductionMaterials={formResponseProductionMaterials} />
      )}
      {(form.model !== MODEL_PP002P && form.model !== MODEL_PP002 && form.model !== MODEL_PP001C && form.model !== MODEL_PP001CC && form.model !== MODEL_PP001W && form.type !== FENCE_TYPE_NON_SYMMETRICAL_GATE && form.type !== FENCE_TYPE_GATE_GATEWAY) && (
        <Info formResponseProductionMaterials={formResponseProductionMaterials} />
      )}
      {(form.model !== MODEL_PP002P && form.model !== MODEL_PP002 && form.model !== MODEL_PP001C && form.model !== MODEL_PP001CC && form.model !== MODEL_PP001W && (form.type === FENCE_TYPE_NON_SYMMETRICAL_GATE || form.type === FENCE_TYPE_GATE_GATEWAY)) && (
        <InfoNonSymmetrical formResponseProductionMaterials={formResponseProductionMaterials} />
      )}
    </div>
  );
};

export default GeneralInfo;
