import { connect } from 'react-redux';
import { setOrderNumber } from 'actions';
import Welcome from './Home/welcome';

const mapStateToProps = (state) => ({
  orderNumber: state.app.orderNumber,
});

const mapDispatchToProps = (dispatch) => ({
  setOrderNumber: (orderNumber) => dispatch(setOrderNumber(orderNumber)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Welcome);
