/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import { GEAR_RAIL_NO } from '../Input/GearRail';

export const PP002P820GateSelfSupporting5801SecondPage = ({ form }) => {
  const gallantry = Object.keys(form.response.productionMaterials.gallantry).map((key, index) => (
    <tr key={key}>
      <td>{index + 1}</td>
      <td>{form.response.productionMaterials.gallantry[key].name}</td>
      <td>{form.response.productionMaterials.gallantry[key].material}</td>
      <td>
        {form.response.productionMaterials.gallantry[key].amount.value}
        {' '}
        {form.response.productionMaterials.gallantry[key].amount.unit}
      </td>
      <td>
        {form.response.productionMaterials.gallantry[key].size.value}
        {' '}
        {form.response.productionMaterials.gallantry[key].size.unit}
      </td>
      <td />
      <td className="tdCenter">
        <div className="pdfSquare" />
      </td>
    </tr>
  ));

  const GearRail = () => {
    if (Object.keys(form.response.productionMaterials.gearRail).length > 0) {
      if (form.response.productionMaterials.gearRail.gearRailAmount !== 'NIE DOTYCZY' && form.response.productionMaterials.gearRail.gearRail !== '#VALUE!' && form.response.productionMaterials.gearRail.gearRailAmount > 0) {
        return (
          <table className="table">
            <thead>
              <tr>
                <th colSpan="7">MAGAZYN - ZBROJENIE</th>
              </tr>
              <tr>
                <th className="responseThSingular">Lp.</th>
                <th className="responseThName">Nazwa</th>
                <th className="responseThMaterial">Materiał</th>
                <th className="responseThQuantity">Ilość</th>
                <th className="responseThMeasure">Wymiar</th>
                <th className="responseThSaws" />
                <th className="responseThD">MAGAZYN</th>
              </tr>
            </thead>
            {form.values.width <= 5800 && (
              <tbody>
                <tr>
                  <td>1</td>
                  <td>{form.response.productionMaterials.gearRail.gearRail}</td>
                  <td>Listwa zębata 8x30x1005</td>
                  <td>
                    {form.response.productionMaterials.gearRail.gearRailAmount}
                    {' '}
                    szt
                  </td>
                  <td>
                    1005 mm
                  </td>
                  <td />
                  <td className="tdCenter">
                    <div className="pdfSquare" />
                  </td>
                </tr>
              </tbody>
            )}
            {form.values.width > 5800 && (
              <tbody>
                <tr>
                  <td>1</td>
                  <td>Listwa zębata</td>
                  <td>Listwa zębata 8x30x1005</td>
                  <td>
                    {form.response.productionMaterials.gearRail.gearRailAmount}
                    {' '}
                    szt
                  </td>
                  <td>
                    1005 mm
                  </td>
                  <td />
                  <td className="tdCenter">
                    <div className="pdfSquare" />
                  </td>
                </tr>
                {(form.response.productionMaterials.gearRail.cutGearRailAmount !== 'NIE DOTYCZY' && form.response.productionMaterials.gearRail.gearRail !== '#VALUE!') && (
                  <tr>
                    <td>2</td>
                    <td>Listwa zębata docinana</td>
                    <td>Listwa zębata 8x30x1005</td>
                    <td>
                      {form.response.productionMaterials.gearRail.cutGearRailAmount}
                    </td>
                    <td>
                      DOCINANA
                    </td>
                    <td />
                    <td className="tdCenter">
                      <div className="pdfSquare" />
                    </td>
                  </tr>
                )}
              </tbody>
            )}
          </table>
        );
      }
      return null;
    } return null;
  };
  const InfoForOffice = () => {
    if (form.values.width <= 5800) {
      return (
        <div>
          <p><b>BOK</b></p>
          <p>
            Całkowita długość bramy:
            {' '}
            {form.response.productionMaterials.saw.rail.size.value + 20}
            {' '}
            mm
          </p>
        </div>
      );
    }
    const firstGateHalf = form.values.gearRail === GEAR_RAIL_NO ? form.response.productionMaterials.saw.topFrontHorizontalFrameWidth.size.value : form.response.productionMaterials.saw.bottomZProfile.size.value + 200;
    const secondGateHalf = form.response.productionMaterials.saw.backRail.size.value;
    if (form.values.width <= 7500) {
      return (
        <div>
          <p><b>BOK</b></p>
          <p>
            1
            {'. '}
            {firstGateHalf > secondGateHalf ? 'Dłuższa ' : 'Krótsza '}
            połówka bramy:
            {' '}
            {firstGateHalf}
            {' '}
            mm
          </p>
          <p>
            2
            {'. '}
            {secondGateHalf > firstGateHalf ? 'Dłuższa ' : 'Krótsza '}
            połówka bramy:
            {' '}
            {secondGateHalf}
            {' '}
            mm
          </p>
        </div>
      );
    } return null;
  };

  return (
    <>
      <table className="table">
        <thead>
          <tr>
            <th colSpan="7">GALANTERIA</th>
          </tr>
          <tr>
            <th className="responseThSingular">Lp.</th>
            <th className="responseThName">Nazwa</th>
            <th className="responseThMaterial">Materiał</th>
            <th className="responseThQuantity">Ilość</th>
            <th className="responseThMeasure">Wymiar</th>
            <th>{' '}</th>
            <th className="responseThSaws">GALANTERIA</th>
          </tr>
        </thead>
        <tbody>
          {gallantry}
        </tbody>
      </table>
      <GearRail />
      <InfoForOffice />
    </>
  );
};

const GateSelfSupportingResponse = ({ values, response, isPdf }) => {
  const angles = Object.keys(response.productionMaterials.angles).map((key, index) => (
    <tr key={key}>
      <td>{index + 1}</td>
      <td>{response.productionMaterials.angles[key].name}</td>
      <td>{response.productionMaterials.angles[key].material}</td>
      <td>
        {response.productionMaterials.angles[key].amount.value}
        {' '}
        {response.productionMaterials.angles[key].amount.unit}
      </td>
      <td>
        {response.productionMaterials.angles[key].size.value}
        {' '}
        {response.productionMaterials.angles[key].size.unit}
      </td>
      {isPdf && (
        <>
          <td />
          <td className="tdCenter">
            <div className="pdfSquare" />
          </td>
        </>
      )}
    </tr>
  ));

  const laser = Object.keys(response.productionMaterials.laser).map((key, index) => (
    <tr key={key}>
      <td>{index + 1}</td>
      <td>{response.productionMaterials.laser[key].name}</td>
      <td>{response.productionMaterials.laser[key].material}</td>
      <td>
        {response.productionMaterials.laser[key].amount.value}
        {' '}
        {response.productionMaterials.laser[key].amount.unit}
      </td>
      <td>
        {response.productionMaterials.laser[key].size.value}
        {' '}
        {response.productionMaterials.laser[key].size.unit}
      </td>
      {isPdf && (
        <>
          <td />
          <td className="tdCenter">
            <div className="pdfSquare" />
          </td>
        </>
      )}
    </tr>
  ));

  const saw = Object.keys(response.productionMaterials.saw).map((key, index) => (
    <tr key={key}>
      <td>{index + 1}</td>
      <td>{response.productionMaterials.saw[key].name}</td>
      <td>{response.productionMaterials.saw[key].material}</td>
      <td>
        {response.productionMaterials.saw[key].amount.value}
        {' '}
        {response.productionMaterials.saw[key].amount.unit}
      </td>
      <td>
        {response.productionMaterials.saw[key].size.value}
        {' '}
        {response.productionMaterials.saw[key].size.unit}
      </td>
      {isPdf && (
        <>
          <td className="tdCenter">
            <div className="pdfSquare" />
          </td>
          <td className="tdCenter">
            <div className="pdfSquare" />
          </td>
        </>
      )}
    </tr>
  ));

  const gallantry = Object.keys(response.productionMaterials.gallantry).map((key, index) => (
    <tr key={key}>
      <td>{index + 1}</td>
      <td>{response.productionMaterials.gallantry[key].name}</td>
      <td>{response.productionMaterials.gallantry[key].material}</td>
      <td>
        {response.productionMaterials.gallantry[key].amount.value}
        {' '}
        {response.productionMaterials.gallantry[key].amount.unit}
      </td>
      <td>
        {response.productionMaterials.gallantry[key].size.value}
        {' '}
        {response.productionMaterials.gallantry[key].size.unit}
      </td>
      {isPdf && (
        <>
          <td />
          <td className="tdCenter">
            <div className="pdfSquare" />
          </td>
        </>
      )}
    </tr>
  ));

  const GearRail = () => {
    if (Object.keys(response.productionMaterials.gearRail).length > 0) {
      if (response.productionMaterials.gearRail.gearRailAmount !== 'NIE DOTYCZY' && response.productionMaterials.gearRail.gearRail !== '#VALUE!' && response.productionMaterials.gearRail.gearRailAmount > 0) {
        return (
          <table className="table">
            <thead>
              <tr>
                <th colSpan={isPdf ? '7' : '5'}>MAGAZYN - ZBROJENIE</th>
              </tr>
              <tr>
                <th className="responseThSingular">Lp.</th>
                <th className="responseThName">Nazwa</th>
                <th className="responseThMaterial">Materiał</th>
                <th className="responseThQuantity">Ilość</th>
                <th className="responseThMeasure">Wymiar</th>
                {isPdf && (
                  <>
                    {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                    <th className="responseThSaws" />
                    <th className="responseThD">MAGAZYN</th>
                  </>
                )}
              </tr>
            </thead>
            {values.width <= 5800 && (
              <tbody>
                <tr>
                  <td>1</td>
                  <td>{response.productionMaterials.gearRail.gearRail}</td>
                  <td>Listwa zębata 8x30x1005</td>
                  <td>
                    {response.productionMaterials.gearRail.gearRailAmount}
                    {' '}
                    szt
                  </td>
                  <td>
                    1005 mm
                  </td>
                  {isPdf && (
                    <>
                      <td />
                      <td className="tdCenter">
                        <div className="pdfSquare" />
                      </td>
                    </>
                  )}
                </tr>
              </tbody>
            )}
            {values.width > 5800 && (
              <tbody>
                <tr>
                  <td>1</td>
                  <td>Listwa zębata</td>
                  <td>Listwa zębata 8x30x1005</td>
                  <td>
                    {response.productionMaterials.gearRail.gearRailAmount}
                    {' '}
                    szt
                  </td>
                  <td>
                    1005 mm
                  </td>
                  {isPdf && (
                    <>
                      <td />
                      <td className="tdCenter">
                        <div className="pdfSquare" />
                      </td>
                    </>
                  )}
                </tr>
                {(response.productionMaterials.gearRail.cutGearRailAmount !== 'NIE DOTYCZY' && response.productionMaterials.gearRail.gearRail !== '#VALUE!') && (
                  <tr>
                    <td>2</td>
                    <td>Listwa zębata docinana</td>
                    <td>Listwa zębata 8x30x1005</td>
                    <td>
                      {response.productionMaterials.gearRail.cutGearRailAmount}
                    </td>
                    <td>
                      DOCINANA
                    </td>
                    {isPdf && (
                      <>
                        <td />
                        <td className="tdCenter">
                          <div className="pdfSquare" />
                        </td>
                      </>
                    )}
                  </tr>
                )}
              </tbody>
            )}
          </table>
        );
      }
      return null;
    } return null;
  };

  const InfoForOffice = () => {
    if (values.width <= 5800 && response.productionMaterials.saw.rail) {
      return (
        <div>
          <p><b>BOK</b></p>
          <p>
            Całkowita długość bramy:
            {' '}
            {response.productionMaterials.saw.rail.size.value + 20}
            {' '}
            mm
          </p>
        </div>
      );
    }
    const firstGateHalf = values.gearRail === GEAR_RAIL_NO ? response.productionMaterials.saw.topFrontHorizontalFrameWidth.size.value : response.productionMaterials.saw.bottomZProfile.size.value + 200;
    const secondGateHalf = response.productionMaterials.saw.backRail.size.value;
    if (values.width <= 7500) {
      return (
        <div>
          <p><b>BOK</b></p>
          <p>
            1
            {'. '}
            {firstGateHalf > secondGateHalf ? 'Dłuższa ' : 'Krótsza '}
            połówka bramy:
            {' '}
            {firstGateHalf}
            {' '}
            mm
          </p>
          <p>
            2
            {'. '}
            {secondGateHalf > firstGateHalf ? 'Dłuższa ' : 'Krótsza '}
            połówka bramy:
            {' '}
            {secondGateHalf}
            {' '}
            mm
          </p>
        </div>
      );
    } return null;
  };

  return (
    <div>
      {(values.width <= 5800 && response.productionMaterials.info.gateIsRectangle && response.productionMaterials.info.gateIsRectangle === 'TAK')
        && (
          <p>
            <b>Czy brama prostokątna?</b>
            {' '}
            {response.productionMaterials.info.gateIsRectangle}
          </p>
        )}
      <table className="table">
        <thead>
          <tr>
            <th colSpan={isPdf ? 7 : 5}>KĄTY</th>
          </tr>
          <tr>
            <th className="responseThSingular">Lp.</th>
            <th className="responseThName">Nazwa</th>
            <th className="responseThMaterial">Materiał</th>
            <th className="responseThQuantity">Ilość</th>
            <th className="responseThMeasure">Wymiar</th>
            {isPdf && (
              <>
                <th>{' '}</th>
                <th className="responseThSaws">KĄTY</th>
              </>
            )}
          </tr>
        </thead>
        <tbody>
          {angles}
        </tbody>
      </table>
      <table className="table">
        <thead>
          <tr>
            <th colSpan={isPdf ? 7 : 5}>LASER - MATERIAŁY DO WYPALENIA</th>
          </tr>
          <tr>
            <th className="responseThSingular">Lp.</th>
            <th className="responseThName">Nazwa</th>
            <th className="responseThMaterial">Materiał</th>
            <th className="responseThQuantity">Ilość</th>
            <th className="responseThMeasure">Wymiar</th>
            {isPdf && (
              <>
                <th>{' '}</th>
                <th className="responseThSaws">3D</th>
              </>
            )}
          </tr>
        </thead>
        <tbody>
          {laser}
        </tbody>
      </table>
      <table className="table">
        <thead>
          <tr>
            <th colSpan={isPdf ? '7' : '5'}>PIŁA - MATERIAŁY DO WYCIĘCIA</th>
          </tr>
          <tr>
            <th className="responseThSingular">Lp.</th>
            <th className="responseThName">Nazwa</th>
            <th className="responseThMaterial">Materiał</th>
            <th className="responseThQuantity">Ilość</th>
            <th className="responseThMeasure">Wymiar</th>
            {isPdf && (
              <>
                <th className="responseThSaws">PIŁA</th>
                <th className="responseThD">2D</th>
              </>
            )}
          </tr>
        </thead>
        <tbody>
          {saw}
          {values.width <= 5800 && response.productionMaterials.gearRail && response.productionMaterials.gearRail?.cutGearRailAmount && (
            <tr>
              <td>{saw.length + 1}</td>
              <td>{response.productionMaterials.gearRail.cutGearRail}</td>
              <td>Listwa zębata 8x30x1005</td>
              <td>
                {response.productionMaterials.gearRail.cutGearRailAmount}
                {' '}
                szt
              </td>
              <td>
                {response.productionMaterials.gearRail.cutGearRailSize}
                {' '}
                mm
              </td>
              {isPdf && (
                <>
                  <td className="tdCenter">
                    <div className="pdfSquare" />
                  </td>
                  <td className="tdCenter">
                    <div className="pdfSquare" />
                  </td>
                </>
              )}
            </tr>
          )}
          {values.width > 5800 && (Object.keys(response.productionMaterials.gearRail).length > 0 && response.productionMaterials.gearRail.cutGearRailAmount !== 'NIE DOTYCZY') && (
            <tr>
              <td>{saw.length + 1}</td>
              <td>Listwa zębata</td>
              <td>Listwa zębata 8x30x1005</td>
              <td>1 szt</td>
              <td>DOCINANA</td>
              {isPdf && (
                <>
                  <td className="tdCenter">
                    <div className="pdfSquare" />
                  </td>
                  <td className="tdCenter">
                    <div className="pdfSquare" />
                  </td>
                </>
              )}
            </tr>
          )}
        </tbody>
      </table>
      {!isPdf && (
        <table className="table">
          <thead>
            <tr>
              <th colSpan={isPdf ? 7 : 5}>GALANTERIA</th>
            </tr>
            <tr>
              <th className="responseThSingular">Lp.</th>
              <th className="responseThName">Nazwa</th>
              <th className="responseThMaterial">Materiał</th>
              <th className="responseThQuantity">Ilość</th>
              <th className="responseThMeasure">Wymiar</th>
              {isPdf && (
                <>
                  <th>{' '}</th>
                  <th className="responseThSaws">GALANTERIA</th>
                </>
              )}
            </tr>
          </thead>
          <tbody>
            {gallantry}
          </tbody>
        </table>
      )}
      {!isPdf && <GearRail />}
      {!isPdf && <InfoForOffice />}
    </div>
  );
};

GateSelfSupportingResponse.propTypes = {
  response: PropTypes.object.isRequired,
};

export default GateSelfSupportingResponse;
