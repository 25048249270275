export const NOT_APPLICABLE = 'NIE DOTYCZY';
export const CHECK_WITH_MANAGER = 'Uzgodnić z kierownikiem';
export const CALC_INDIVIDUALLY = 'WYLICZANE INDYWIDUALNIE';
export const EMPTY_ANSWER = null;
export const SIZE_MM = 'mm';

export const RECTANGLE_YES = 'TAK';
export const RECTANGLE_NO = 'NIE';

export const C_25_25_25_15 = 'Ceownik 25x25x25x1,5';
export const KA_30_20_3 = 'Kątownik 30x20x3';
export const KA_30_22_3 = 'Kątownik 30x22x3';
export const KA_31_12_08 = 'Kątownik 31x12x0,8';
export const KA_32_14_08 = 'Kątownik 32x14x0,8';
export const KA_30_19_2 = 'Kątownik 30x19x2';
export const KA_33_22_3 = 'Kątownik 33x22x3';
export const K_20_20_15 = 'Kształtownik 20x20x1,5';
export const K_25_25_12 = 'Kształtownik 25x25x1,2';
export const K_25_25_15 = 'Kształtownik 25x25x1,5';
export const K_40_20_15 = 'Kształtownik 40x20x1,5';
export const K_40_30_15 = 'Kształtownik 40x30x1,5';
export const K_60_40_15 = 'Kształtownik 60x40x1,5';
export const K_60_40_20 = 'Kształtownik 60x40x2';
export const K_60_40_30 = 'Kształtownik 60x40x3';
export const K_60_50_20 = 'Kształtownik 60x50x2';
export const K_60_50_30 = 'Kształtownik 60x50x3';
export const K_60_50_15 = 'Kształtownik 60x50x1,5';
export const K_80_20_12 = 'Kształtownik 80x20x1,2';
export const K_80_20_20 = 'Kształtownik 80x20x2';
export const K_80_20_30 = 'Kształtownik 80x20x3';
export const K_80_40_20 = 'Kształtownik 80x40x2';
export const K_80_40_30 = 'Kształtownik 80x40x3';
export const K_80_60_20 = 'Kształtownik 80x60x2';
export const K_80_60_30 = 'Kształtownik 80x60x3';
export const K_80_80_20 = 'Kształtownik 80x80x2';
export const K_80_80_30 = 'Kształtownik 80x80x3';
export const K_100_20_12 = 'Kształtownik 100x20x1,2';
export const K_100_40_20 = 'Kształtownik 100x40x2';
export const K_100_40_30 = 'Kształtownik 100x40x3';
export const K_100_100_30 = 'Kształtownik 100x100x3';
export const K_100_100_40 = 'Kształtownik 100x100x4';
export const K_120_120_40 = 'Kształtownik 120x120x4';
export const K_150_150_40 = 'Kształtownik 150x150x4';
export const PZ_80_80_40 = 'Szyna 80x80x4';
export const Z_18_40_37_25 = 'Zetownik 18x40x37x2,5';
export const P_20_4_S = 'Płaskownik 20x4';
export const P_25_2_S = 'Płaskownik 25x2';

export const ADDITIONAL_VERT_FRAME = 'Rama dodatkowy pion';
export const VERT_FRAME_AND_COUNTERWEIGHT = 'Rama i przeciwwaga pion';

export const FORM_QUANTITY = 'formQuantity';

// JSON NAMES
export const AREA_HEIGHT = 'areaHeight';
export const AREA_HEIGHT_UNDER_1000 = 'areaHeightUnderH1000';
export const AREA_HEIGHT_UNDER_MACHINE = 'areaHeightUnderMachine';
export const AREA_HEIGHT_ABOVE_MACHINE = 'areaHeightAboveMachine';
export const SPACING = 'spacing';
export const PROFILE_AMOUNT = 'profileAmount';
export const PROFILE_AMOUNT_UNDER_1000 = 'profileAmountUnderH1000';
export const PROFILE_AMOUNT_UNDER_MACHINE = 'profileAmountUnderMachine';
export const PROFILE_AMOUNT_ABOVE_MACHINE = 'profileAmountAboveMachine';
export const PLANK_HEIGHT = 'height';

// FURTKA WYPELNIENIE
export const GATEWAY_HEIGHT = 'gatewayHeight';
export const GATEWAY_WIDTH = 'gatewayWidth';
export const GATEWAY_AMOUNT = 'gatewayAmount';

// BRAMA UCHYLNA WYPELNIENIE
export const GATE_TILTING_AMOUNT = 'gateTiltingAmount';
export const GATE_TILTING_WIDTH = 'gateTiltingWidth';
export const GATE_TILTING_HEIGHT = 'gateTiltingHeight';
export const GATE_TILTING_HEIGHT_ABOVE_MACHINE = 'gateTiltingHeightAboveMachine';
export const GATE_TILTING_HEIGHT_UNDER_MACHINE = 'gateTiltingHeightUnderMachine';

// BRAMA JEDNOSKRZYDLOWA
export const SINGLE_LEAF_GATE_AMOUNT = 'singleLeafGateAmount';
export const SINGLE_LEAF_GATE_WIDTH = 'singleLeafGateWidth';
export const SINGLE_LEAF_GATE_HEIGHT = 'singleLeafGateHeight';
export const SINGLE_LEAF_GATE_HEIGHT_ABOVE_MACHINE = 'singleLeafGateHeightAboveMachine';
export const SINGLE_LEAF_GATE_HEIGHT_UNDER_MACHINE = 'singleLeafGateHeightUnderMachine';

// BRAMA ŁAMANA
export const FOLDING_GATE_AMOUNT = 'foldingGateAmount';
export const FOLDING_GATE_HEIGHT = 'foldingGateHeight';
export const FOLDING_GATE_HEIGHT_ABOVE_MACHINE = 'foldingGateHeightAboveMachine';
export const FOLDING_GATE_HEIGHT_UNDER_MACHINE = 'foldingGateHeightUnderMachine';
export const FOLDING_GATE_WIDTH = 'foldingGateWidth';

export const FILLING_ABOVE_MACHINE_AMOUNT = 'fillingAboveMachineAmount';
export const FILLING_UNDER_MACHINE_AMOUNT = 'fillingUnderMachineAmount';

export const FILLING_RAL_AMOUNT = 'fillingRalAmount';
export const FILLING_LAMINAT_AMOUNT = 'fillingLaminatAmount';
export const FILLING_RAL_AMOUNT_ABOVE_MACHINE = 'fillingRalAmountAboveMachine';
export const FILLING_LAMINAT_AMOUNT_ABOVE_MACHINE = 'fillingLaminatAmountAboveMachine';
export const FILLING_RAL_AMOUNT_UNDER_MACHINE = 'fillingRalAmountUnderMachine';

export const FILLING_80_20_AMOUNT = 'filling80x20Amount';
export const FILLING_80_20_SIZE = 'filling80x20Size';
export const FILLING_40_20_AMOUNT = 'filling40x20Amount';
export const FILLING_40_20_SIZE = 'filling40x20xSize';
export const FILLING_80_20_AMOUNT_UNDER_MACHINE = 'filling80x20AmountUnderMachine';

// BRAMA SAMONOSNA WYPELNIENIE
export const GATE_SELF_SUPPORTING_AMOUNT = 'gateSelfSupportingAmount';
export const GATE_SELF_SUPPORTING_HEIGHT = 'gateSelfSupportingHeight';
export const GATE_SELF_SUPPORTING_WIDTH = 'gateSelfSupportingWidth';

// LISTWA DOMYKOWA MIEJSCE
export const CLOSING_STRIP_SPACE = 'closingStripSpace';
export const CLOSING_STRIP_SAW = 'closingStripSaw';
export const CLOSING_STRIP_LASER = 'closingStripLaser';

// RAMA SZEROKOSC (POZIOM)
export const FRAME_WIDTH_HORIZONTAL_NUMBER = 'frameWidthHorizontalNumber';
export const FRAME_WIDTH_HORIZONTAL_AMOUNT = 'frameWidthHorizontalAmount';
export const FRAME_WIDTH_HORIZONTAL_AMOUNT_ADDITIONAL_DATA = 'frameWidthHorizontalAmountAdditionalData';
export const FRAME_WIDTH_HORIZONTAL_SIZE = 'frameWidthHorizontalSize';
export const FRAME_WIDTH_HORIZONTAL_SIZE_ADDITIONAL_DATA = 'frameWidthHorizontalSizeAdditionalData';
export const FRAME_WIDTH_HORIZONTAL_MATERIAL = 'frameWidthHorizontalMaterial';
export const HORIZONTAL_FRAME_WIDTH = 'horizontalFrameWidth';

// KATOWNIK BAZOWY
export const BASE_BRACKET_AMOUNT = 'baseBracketAmount';
export const BASE_BRACKET_AMOUNT_ADDITIONAL_DATA = 'baseBracketAmountAdditionalData';
export const BASE_BRACKET_SIZE = 'baseBracketSize';
export const BASE_BRACKET_SIZE_ADDITIONAL_DATA = 'baseBracketSizeAdditionalData';
export const BASE_BRACKET_MATERIAL = 'baseBracketMaterial';

export const BASE_BRACKET_ABOVE_MACHINE_SIZE = 'baseBracketAboveMachineSize';
export const BASE_BRACKET_UNDER_MACHINE_SIZE = 'baseBracketUnderMachineSize';

// KATOWNIK ZAKRYWAJACY
export const COVERING_BRACKET_AMOUNT = 'coveringBracketAmount';
export const COVERING_BRACKET_AMOUNT_ADDITIONAL_DATA = 'coveringBracketAmountAdditionalData';
export const COVERING_BRACKET_SIZE = 'coveringBracketSize';
export const COVERING_BRACKET_SIZE_ADDITIONAL_DATA = 'coveringBracketSizeAdditionalData';
export const COVERING_BRACKET_MATERIAL = 'coveringBracketMaterial';

export const COVERING_BRACKET_ABOVE_MACHINE_SIZE = 'coveringBracketAboveMachineSize';
export const COVERING_BRACKET_UNDER_MACHINE_SIZE = 'coveringBracketUnderMachineSize';

// MASKOWNICA ZAWIASOWA
export const HINGED_COVER_MATERIAL = 'hingedCoverMaterial';
export const HINGED_COVER_AMOUNT = 'hingedCoverAmount';
export const HINGED_COVER_AMOUNT_ADDITIONAL_DATA = 'hingedCoverAmountAdditionalData';
export const HINGED_COVER_SIZE = 'hingedCoverSize';
export const HINGED_COVER_SIZE_ADDITIONAL_DATA = 'hingedCoverSizeAdditionalData';

// SLUPY
export const POLES_AMOUNT = 'polesAmount';
export const POLES_AMOUNT_ADDITIONAL_DATA = 'polesAmountAdditionalData';
export const POLES_MATERIAL = 'polesMaterial';
export const POLES_SIZE = 'polesSize';
export const POLES_SIZE_ADDITIONAL_DATA = 'polesSizeAdditionalData';

// SLUPY NA MARCE
export const POLES_ON_HOLDER_AMOUNT = 'polesOnHolderAmount';
export const POLES_ON_HOLDER_AMOUNT_ADDITIONAL_DATA = 'polesOnHolderAmountAdditionalData';
export const POLES_ON_HOLDER_MATERIAL = 'polesOnHolderMaterial';
export const POLES_ON_HOLDER_SIZE = 'polesOnHolderSize';
export const POLES_ON_HOLDER_SIZE_ADDITIONAL_DATA = 'polesOnHolderSizeAdditionalData';

// RAMA ZAWIASOWY PION
export const HINGED_FRAME_VERTICAL_AMOUNT = 'hingedFrameVerticalAmount';
export const HINGED_FRAME_VERTICAL_AMOUNT_ADDITIONAL_DATA = 'hingedFrameVerticalAmountAdditionalData';
export const HINGED_FRAME_VERTICAL_MATERIAL = 'hingedFrameVerticalMaterial';
export const HINGED_FRAME_VERTICAL_SIZE = 'hingedFrameVerticalSize';

// RAMA PION
export const VERTICAL_FRAME_AMOUNT = 'verticalFrameAmount';
export const VERTICAL_FRAME_AMOUNT_ADDITIONAL_DATA = 'verticalFrameAmountAdditionalData';
export const VERTICAL_FRAME_SIZE = 'verticalFrameSize';

// RAMA PION PRZOD
export const FRONT_VERTICAL_FRAME_AMOUNT = 'frontVerticalFrameAmount';
export const FRONT_VERTICAL_FRAME_AMOUNT_ADDITIONAL_DATA = 'frontVerticalFrameAmountAdditionalData';
export const FRONT_VERTICAL_FRAME_SIZE = 'frontVerticalFrameSize';

// RAMA PION TYL
export const BACK_VERTICAL_FRAME_AMOUNT = 'backVerticalFrameAmount';
export const BACK_VERTICAL_FRAME_AMOUNT_ADDITIONAL_DATA = 'backVerticalFrameAmountAdditionalData';
export const BACK_VERTICAL_FRAME_SIZE = 'backVerticalFrameSize';

// LISTWA DOMYKOWA
export const CLOSING_STRIP_NUMBER = 'closingStripNumber';
export const CLOSING_STRIP_AMOUNT = 'closingStripAmount';
export const CLOSING_STRIP_AMOUNT_ADDITIONAL_DATA = 'closingStripAmountAdditionalData';
export const CLOSING_STRIP_SIZE = 'closingStripSize';

// RAMA POPRZECZKA AUTOMAT
export const AUTOMATIC_FRAME_AMOUNT = 'automaticFrameAmount';
export const AUTOMATIC_FRAME_AMOUNT_ADDITIONAL_DATA = 'automaticFrameAmountAdditionalData';
export const AUTOMATIC_FRAME_SIZE = 'automaticFrameSize';
export const AUTOMATIC_FRAME_SIZE_ADDITIONAL_DATA = 'automaticFrameAdditionalData';
export const AUTOMATIC_FRAME_MATERIAL = 'automaticFrameMaterial';

// DODATKOWY PION
export const ADDITIONAL_VERTICAL_FRAME_AMOUNT = 'additionalVerticalFrameAmount';
export const ADDITIONAL_VERTICAL_FRAME_AMOUNT_ADDITIONAL_DATA = 'additionalVerticalFrameAmountAdditionalData';
export const ADDITIONAL_VERTICAL_FRAME_MATERIAL = 'additionalVerticalFrameMaterial';
export const ADDITIONAL_VERTICAL_FRAME_SIZE = 'additionalVerticalFrameSize';
export const ADDITIONAL_VERTICAL_FRAME_SIZE_ADDITIONAL_DATA = 'additionalVerticalFrameSizeAdditionalData';

// DLA DIVERSO DODATKOWY PION > WZMOCNIENIE WYPEŁNIENIA
export const FILLING_FORTIFICATION_AMOUNT = 'fillingFortificationAmount';
export const FILLING_FORTIFICATION_AMOUNT_ADDITIONAL_DATA = 'fillingFortificationAmountAdditionalData';
export const FILLING_FORTIFICATION_MATERIAL = 'fillingFortificationMaterial';
export const FILLING_FORTIFICATION_SIZE = 'fillingFortificationSize';
export const FILLING_FORTIFICATION_ABOVE_MACHINE_SIZE = 'fillingFortificationAboveMachineSize';
export const FILLING_FORTIFICATION_UNDER_MACHINE_SIZE = 'fillingFortificationUnderMachineSize';
export const FILLING_FORTIFICATION_SIZE_ADDITIONAL_DATA = 'fillingFortificationSizeAdditionalData';

// MASKOWNICA MIEDZY SKRZYDLAMI
export const MASK_BETWEEN_WINGS_AMOUNT = 'maskBetweenWingsAmount';
export const MASK_BETWEEN_WINGS_AMOUNT_ADDITIONAL_DATA = 'maskBetweenWingsAmountAdditionalData';
export const MASK_BETWEEN_WINGS_MATERIAL = 'maskBetweenWingsMaterial';
export const MASK_BETWEEN_WINGS_SIZE = 'maskBetweenWingsSize';
export const MASK_BETWEEN_WINGS_SIZE_ADDITIONAL_DATA = 'maskBetweenWingsSizeAdditionalData';

// RAMA I PRZECIWWAGA
export const FRAME_AND_COUNTERWEIGHT_NAME = 'frameAndCounterweightName';
export const FRAME_AND_COUNTERWEIGHT_AMOUNT = 'frameAndCounterweightAmount';
export const FRAME_AND_COUNTERWEIGHT_MATERIAL = 'frameAndCounterweightMaterial';
export const FRAME_AND_COUNTERWEIGHT_AMOUNT_ADDITIONAL_DATA = 'frameAndCounterweightAmountAdditionalData';
export const FRAME_AND_COUNTERWEIGHT_SIZE = 'frameAndCounterweightSize';
export const FRAME_AND_COUNTERWEIGHT_SIZE_ADDITIONAL_DATA = 'frameAndCounterweightSizeAdditionalData';

// DODATKOWY POZIOM
export const ADDITIONAL_HORIZONTAL_FRAME_AMOUNT = 'additionalHorizontalFrameAmount';
export const ADDITIONAL_HORIZONTAL_FRAME_AMOUNT_ADDITIONAL_DATA = 'additionalHorizontalFrameAmountAdditionalData';
export const ADDITIONAL_HORIZONTAL_FRAME_MATERIAL = 'additionalHorizontalFrameMaterial';
export const ADDITIONAL_HORIZONTAL_FRAME_SIZE = 'additionalHorizontalFrameSize';
export const ADDITIONAL_HORIZONTAL_FRAME_SIZE_ADDITIONAL_DATA = 'additionalHorizontalFrameSizeAdditionalData';

// RAMA SZEROKOSC GORA
export const TOP_FRAME_WIDTH_AMOUNT = 'topFrameWidthAmount';
export const TOP_FRAME_WIDTH_AMOUNT_ADDITIONAL_DATA = 'topFrameWidthAmountAdditionalData';
export const TOP_FRAME_WIDTH_SIZE = 'topFrameWidthSize';

// RAMA SZEROKOSC POZIOM GORA
export const TOP_HORIZONTAL_FRAME_WIDTH_AMOUNT = 'topHorizontalFrameWidthAmount';
export const TOP_HORIZONTAL_FRAME_WIDTH_AMOUNT_ADDITIONAL_DATA = 'topHorizontalFrameWidthAmountAdditionalData';
export const TOP_HORIZONTAL_FRAME_WIDTH_SIZE = 'topHorizontalFrameWidthSize';

// ZETOWNIK GORNY
export const PROFILE_TOP_AMOUNT = 'profileTopAmount';
export const PROFILE_TOP_AMOUNT_ADDITIONAL_DATA = 'profileTopAmountAdditionalData';
export const PROFILE_TOP_SIZE = 'profileTopSize';

// ZETOWNIK DOLNY
export const PROFILE_BOTTOM_AMOUNT = 'profileBottomAmount';
export const PROFILE_BOTTOM_AMOUNT_ADDITIONAL_DATA = 'profileBottomAmountAdditionalData';
export const PROFILE_BOTTOM_MATERIAL = 'profileBottomMaterial';
export const PROFILE_BOTTOM_SIZE = 'profileBottomSize';
export const PROFILE_BOTTOM_SIZE_ADDITIONAL_DATA = 'profileBottomSizeAdditionalData';

// SZYNA
export const RAIL_AMOUNT = 'railAmount';
export const RAIL_AMOUNT_ADDITIONAL_DATA = 'railAmountAdditionalData';
export const RAIL_SIZE = 'railSize';

// SKOS PRZECIWWAGA
export const SLANT_COUNTERWEIGHT_AMOUNT = 'slantCounterweightAmount';
export const SLANT_COUNTERWEIGHT_AMOUNT_ADDITIONAL_DATA = 'slantCounterweightAmountAdditionalData';
export const SLANT_COUNTERWEIGHT_SIZE = 'slantCounterweightSize';

// LISTWA DOJAZDOWA
export const ACCESSING_STRIP_AMOUNT = 'accessingStripAmount';
export const ACCESSING_STRIP_AMOUNT_ADDITIONAL_DATA = 'accessingStripAmountAdditionalData';
export const ACCESSING_STRIP_SIZE = 'accessingStripSize';

// RAMA SZEROKOSC POZIOM DOL
export const BOTTOM_FRAME_WIDTH_AMOUNT = 'bottomFrameWidthAmount';
export const BOTTOM_FRAME_WIDTH_AMOUNT_ADDITIONAL_DATA = 'bottomFrameWidthAmountAdditionalData';
export const BOTTOM_FRAME_WIDTH_SIZE = 'bottomFrameWidthSize';

// PROSTOKAT
export const RECTANGLE = 'rectangle';

// PION
export const VERTICAL_PART_AMOUNT = 'verticalPartAmount';
export const VERTICAL_PART_AMOUNT_ADDITIONAL_DATA = 'verticalPartAmountAdditionalData';
export const VERTICAL_PART_SIZE = 'verticalPartSize';
export const VERTICAL_PART_SIZE_ADDITIONAL_DATA = 'verticalPartSizeAdditionalData';
export const VERTICAL_PART_MATERIAL = 'verticalPartMaterial';

// WYPELNIENIE
export const FILLING_AMOUNT = 'fillingAmount';
export const FILLING_AMOUNT_ADDITIONAL_DATA = 'fillingAmountAdditionalData';
export const FILLING_SIZE = 'fillingSize';
export const FILLING_SIZE_ADDITIONAL_DATA = 'fillingSizeAdditionalData';
export const FILLING_MATERIAL = 'fillingMaterial';

export const FILLING_PROPERTY_AMOUNT = 'fillingPropertyAmount';
export const FILLING_PROPERTY_SIZE = 'fillingPropertySize';
export const FILLING_PROPERTY_SIZE_ADDITIONAL_DATA = 'fillingPropertySizeAdditionalData';

export const FILLING_SECOND_ROW_MATERIAL = 'fillingSecondRowMaterial';

// PALISADA
export const PALISADE_AMOUNT = 'palisadeAmount';
export const PALISADE_AMOUNT_ADDITIONAL_DATA = 'palisadeAmountAdditionalData';
export const PALISADE_SIZE = 'palisadeSize';
export const PALISADE_SIZE_ADDITIONAL_DATA = 'palisadeSizeAdditionalSizeAdditionalData';
export const PALISADE_MATERIAL = 'palisadeMaterial';

// PALISADA 1 PRZESLO
export const SPAN_PALISADE_AMOUNT = 'spanPalisadeAmount';
export const SPAN_PALISADE_AMOUNT_ADDITIONAL_DATA = 'spanPalisadeAmountAdditionalData';
export const SPAN_PALISADE_SIZE = 'spanPalisadeSize';

// DODATKOWA PALISADA
export const ADDITIONAL_PALISADE_AMOUNT = 'additionalPalisadeAmount';
export const ADDITIONAL_PALISADE_AMOUNT_ADDITIONAL_DATA = 'additionalPalisadeAmountAdditionalData';
export const ADDITIONAL_PALISADE_SIZE = 'additionalPalisadeSize';
export const ADDITIONAL_PALISADE_SIZE_ADDITIONAL_DATA = 'additionalPalisadeSizeAdditionalData';
export const ADDITIONAL_PALISADE_MATERIAL = 'additionalPalisadeMaterial';

// CEOWNIK
export const CHANNEL_AMOUNT = 'channelAmount';
export const CHANNEL_AMOUNT_ADDITIONAL_DATA = 'channelAmountAdditionalData';
export const CHANNEL_SIZE = 'channelSize';

// PION MODULU
export const VERTICAL_MODULE_AMOUNT = 'verticalModuleAmount';
export const VERTICAL_MODULE_AMOUNT_ADDITIONAL_DATA = 'verticalModuleAmountAdditionalData';
export const VERTICAL_MODULE_SIZE = 'verticalModuleSize';

// DODATKOWY PION MODULU
export const ADDITIONAL_VERTICAL_MODULE_AMOUNT = 'additionalVerticalModuleAmount';
export const ADDITIONAL_VERTICAL_MODULE_AMOUNT_ADDITIONAL_DATA = 'additionalVerticalModuleAmountAdditionalData';
export const ADDITIONAL_VERTICAL_MODULE_SIZE = 'additionalVerticalModuleSize';
export const ADDITIONAL_VERTICAL_MODULE_SIZE_ADDITIONAL_DATA = 'additionalVerticalModuleSizeAdditionalData';
export const ADDITIONAL_VERTICAL_MODULE_MATERIAL = 'additionalVerticalModuleMaterial';

// SZEROKOSC MODULU
export const MODULE_WIDTH_AMOUNT = 'moduleWidthAmount';
export const MODULE_WIDTH_AMOUNT_ADDITIONAL_DATA = 'moduleWidthAmountAdditionalData';
export const MODULE_WIDTH_SIZE = 'moduleWidthSize';

// POLE MODEL
export const POLE_MODEL_BLIX = 'poleModelBlix';
export const POLE_MODEL_HOLDER = 'poleModelHolder';
export const POLE_MODEL_AMOUNT = 'poleModelAmount';
export const POLE_MODEL_SIZE = 'poleModelSize';
export const POLE_MODEL_MATERIAL = 'poleModelMaterial';

// SPAN - PLANK PART
export const SPAN_MOUNTING_CEOWNIK_AMOUNT = 'spanMountingCeownikAmount';
// eslint-disable-next-line camelcase
export const SPAN_RIVETS_4x10_AMOUNT = 'spanRivets_4x10_amount';
// eslint-disable-next-line camelcase
export const SPAN_RIVETS_4x8_AMOUNT = 'spanRivets_4x8_amount';
export const SPAN_RIVET_CAP_AMOUNT = 'spanRivetCapAmount';

// SPAN FILLING
export const FILLING_20_20_MATERIAL = 'filling20x20Material';
export const FILLING_20_20_AMOUNT = 'filling20x20Amount';
export const FILLING_20_20_AMOUNT_ADDITIONAL_DATA = 'filling20x20AmountAdditionalData';
export const FILLING_20_20_SIZE = 'filling20x20Size';
export const FILLING_20_20_SIZE_ADDITIONAL_DATA = 'filling20x20SizeAdditionalData';

export const FILLING_40_20_MATERIAL = 'filling40x20Material';
export const FILLING_40_20_AMOUNT_ADDITIONAL_DATA = 'filling40x20AmountAdditionalData';
export const FILLING_40_20_SIZE_ADDITIONAL_DATA = 'filling40x20SizeAdditionalData';

export const SPAN_FILLING_80_20_LONG_AMOUNT = 'spanFilling80x20LongAmount';
export const SPAN_FILLING_80_20_LONG_SIZE = 'spanFilling80x20LongSize';

export const FILLING_80_20_MATERIAL = 'filling80x20Material';
export const FILLING_80_20_AMOUNT_ADDITIONAL_DATA = 'filling80x20AmountAdditionalData';
export const FILLING_80_20_SIZE_ADDITIONAL_DATA = 'filling80x20SizeAdditionalData';

export const FILLING_100_20_MATERIAL = 'filling100x20Material';
export const FILLING_100_20_AMOUNT = 'filling100x20Amount';
export const FILLING_100_20_AMOUNT_ADDITIONAL_DATA = 'filling100x20AmountAdditionalData';
export const FILLING_100_20_SIZE = 'filling100x20Size';
export const FILLING_100_20_SIZE_ADDITIONAL_DATA = 'filling100x20SizeAdditionalData';

export const FILLING_LONG_EXISTING = 'fillingLongExisting';
export const FILLING_LONG_MATERIAL = 'fillingLongMaterial';
export const FILLING_LONG_AMOUNT = 'fillingLongAmount';
export const FILLING_LONG_SIZE = 'fillingLongSize';

export const SPAN_SPACING_SIZE = 'spanSpacingSize';
export const SPACING_SIZE = 'spacingSize';

export const BORDER_SPACING_SIZE = 'borderSpacingSize';
export const SPACE_BETWEEN_FILLING_AND_VERTICAL_END = 'spaceBetweenFillingAndVerticalEnd';

// STUDDING
export const FRAME_WIDTH = 'frameWidth';
export const PALE_AMOUNT = 'paleAmount';

export const STUDDING_HEIGHT = 'studdingHeight';
export const STUDDING_MODULE_HEIGHT = 'studdingModuleHeight';
export const FILLING_MODULE_HEIGHT = 'fillingModuleHeight';

export const LIGHT_WIDTH = 'lightWidth';
export const WING_WIDTH = 'wingWidth';
export const POLE_AMOUNT_ONE_WING = 'paleAmountOneWing';
export const TOTAL_PALE_AMOUNT = 'totalPaleAmount';
export const PALE_AMOUNT_FRONT = 'paleAmountFront';

// CEOWNIKI
export const CEOWNIK_TOP_AMOUNT = 'ceownikTopAmount';
export const CEOWNIK_TOP_SIZE = 'ceownikTopSize';
export const CEOWNIK_SIDES_AMOUNT = 'ceownikSidesAmount';
export const CEOWNIK_SIDES_SIZE = 'ceownikSidesSize';

// PS00 SPAN
export const FRAME_ONE_AMOUNT = 'frameOneAmount';
export const FRAME_ONE_SIZE = 'frameOneSize';
export const FRAME_TWO_MATERIAL = 'frameTwoMaterial';
export const FRAME_TWO_AMOUNT = 'frameTwoAmount';
export const FRAME_TWO_AMOUNT_ADDITIONAL_DATA = 'frameTwoAmountAdditionalData';
export const FRAME_TWO_SIZE = 'frameTwoSize';
export const FRAME_TWO_SIZE_ADDITIONAL_DATA = 'frameTwoSizeAdditionalData';

export const FRAME_CENTER_AMOUNT = 'frameCenterAmount';
export const FRAME_CENTER_SIZE = 'frameCenterSize';

// GATEWAY LONG HANDRAIL
export const LONG_HANDRAIL_HEIGHT_AMOUNT = 'longHandrailHeightAmount';
export const LONG_HANDRAIL_HEIGHT_SIZE = 'longHandrailHeightSize';

export const LONG_HANDRAIL_WIDTH_AMOUNT = 'longHandrailWidthAmount';
export const LONG_HANDRAIL_WIDTH_SIZE = 'longHandrailWidthSize';

// PS004 HOLES
export const HOLE_ONE_SIZE = 'holeOneSize';
export const HOLE_TWO_SIZE = 'holeTwoSize';
export const HOLE_THREE_SIZE = 'holeThreeSize';
export const HOLE_FOUR_SIZE = 'holeFourSize';
export const LAST_HOLE_SIZE = 'lastHoleSize';
export const LAST_HOLE_NAME = 'lastHoleName';

// PS004 PALISADE HOLES
export const PALISADE_HOLE_ONE_SIZE = 'palisadeHoleOneSize';
export const PALISADE_HOLE_TWO_SIZE = 'palisadeHoleTwoSize';
export const PALISADE_HOLE_THREE_SIZE = 'palisadeHoleThreeSize';
export const PALISADE_HOLE_FOUR_SIZE = 'palisadeHoleFourSize';
export const PALISADE_HOLE_FIVE_SIZE = 'palisadeHoleFiveSize';
export const PALISADE_LAST_HOLE_SIZE = 'palisadeLastHoleSize';
export const PALISADE_LAST_HOLE_NAME = 'palisadeLastHoleName';

// RAMA PION WEWNETRZNY
export const FRAME_INSIDE_VERTICAL_PART_AMOUNT = 'frameInsideVerticalPartAmount';
export const FRAME_INSIDE_VERTICAL_PART_SIZE = 'frameInsideVerticalPartSize';

// VMS
export const MAT_AMOUNT = 'matAmount';
export const MAT_MATERIAL = 'matMaterial';

// OPOREK
export const RESISTANCE_AMOUNT = 'resistanceAmount';
export const RESISTANCE_SIZE = 'resistanceSize';

// LISTWY MONTAŻOWE
export const MOUNTING_STRIP_AMOUNT = 'mountingStripAmount';
export const MOUNTING_STRIP_AMOUNT_ADDITIONAL_DATA = 'mountingStripAmountAdditionalData';
export const MOUNTING_STRIP_MATERIAL = 'mountingStripMaterial';
export const MOUNTING_STRIP_SIZE = 'mountingStripSize';
export const MOUNTING_STRIP_SIZE_ADDITIONAL_DATA = 'mountingStripAdditionalData';

export const MOUNTING_STRIP_OPENING_AMOUNT = 'mountingStripOpeningAmount';
export const MOUNTING_STRIP_SPACING = 'mountingStripSpacing';

// BLACHA - PB - FILLING
export const PLATE_HEIGHT = 'plateHeight';
export const PLATE_WIDTH = 'plateWidth';
export const PLATE_AMOUNT = 'plateAmount';
export const AREA_AMOUNT = 'areaAmount';
export const AREA_WIDTH = 'areaWidth';

// LISTWA ZEBATA
export const GEAR_RAIL_NAME = 'gearRailName';
export const GEAR_RAIL_AMOUNT = 'gearRailAmount';
export const GEAR_RAIL_AMOUNT_ADDITIONAL_DATA = 'gearRailAmountAdditionalData';
export const CUT_GEAR_RAIL_AMOUNT = 'cutGearRailAmount';
export const CUT_GEAR_RAIL_AMOUNT_ADDITIONAL_DATA = 'cutGearRailAmountAdditionalData';
