import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { useDispatch } from 'react-redux';
import { DELETE_FORM } from '../../actions';

const OrderFormHeaderDeleteBtn = ({ id, title }) => {
  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState(false);

  const style = showModal ? { display: 'block' } : {};

  return (
    <>
      <div className="modal" tabIndex="-1" role="dialog" style={style}>
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Chcesz usunąć formularz</h5>
            </div>
            <div className="modal-body">
              <p>
                Czy na pewno chcesz usunąć formularz (
                {title}
                )?
              </p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-danger"
                onClick={() => dispatch({ type: DELETE_FORM, id })}
              >
                Tak, usuń
              </button>
              <button
                type="button"
                className="btn btn-success"
                onClick={() => setShowModal(false)}
              >
                Nie, zachowaj
              </button>
            </div>
          </div>
        </div>
      </div>
      <button
        className="btn orderFormDeleteBtn"
        type="button"
        onClick={() => setShowModal(!showModal)}
      >
        <FontAwesomeIcon icon={faTrashAlt} size="2x" />
      </button>
    </>
  );
};

export default OrderFormHeaderDeleteBtn;
