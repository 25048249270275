import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faUser } from '@fortawesome/free-solid-svg-icons';
import { FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';
import * as Sentry from '@sentry/browser';

import { useSelector } from 'react-redux';

import {
  Container,
  LoginContainerRow,
  ContainerCol4,
  LoginFormContainer,
  LoginFormInput,
  InputContainer,
  ButtonsContainer,
  LoginButton,
  ButtonText,
  Image,
  ResponseContainer,
} from 'Pages/shared/components';

const validationSchema = yup.object().shape({
  email: yup.string().email().required(),
});

const IconStyle = {
  position: 'absolute',
  opacity: '0.5',
  top: '10px',
  left: '15px',
};

const ForgottenPassword = () => {
  const [info, setInfo] = useState('');
  const [error, setError] = useState(false);
  const authenticated = useSelector((s) => s.app.authenticated);
  const authError = useSelector((s) => s.app.authError);
  const formik = useFormik({
    initialValues: {
      email: '',
    },
    onSubmit: (values) => {
      setInfo('');
      setError(false);
      axios
        .post('/user-forgot-password', {
          email: values.email,
          ui: 'PRODUCTION_CARD',
        })
        .then(() => {
          setInfo('Link do resetu hasła został wysłany');
        })
        .catch((err) => {
          if (err?.response?.status >= 400) {
            setInfo('Nieprawidłowy email');
            setError(true);
            return;
          }
          Sentry.captureException(err);
        });
    },
    validationSchema,
  });
  if (authenticated) {
    return <Redirect to={{ pathname: '/' }} />;
  }

  return (
    <FormikProvider value={formik}>
      <Container>
        <div className="container-xl">
          <LoginContainerRow className="row">
            <ContainerCol4 className="col-sm-4">
              <LoginFormContainer>
                <form onSubmit={formik.handleSubmit}>
                  <div>
                    <h2>Zresetuj hasło</h2>
                  </div>
                  <InputContainer className="form-group">
                    <FontAwesomeIcon icon={faUser} style={IconStyle} />
                    <LoginFormInput
                      type="email"
                      name="email"
                      className="form-control"
                      placeholder="Email"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    // eslint-disable-next-line jsx-a11y/no-autofocus
                      autoFocus
                    />
                  </InputContainer>
                  <ButtonsContainer>
                    <LoginButton className="btn btn-danger" type="submit">
                      <ButtonText>Zresetuj hasło</ButtonText>
                      <FontAwesomeIcon icon={faAngleRight} />
                    </LoginButton>
                  </ButtonsContainer>
                  <ResponseContainer error={error || authError}>
                    {info}
                    {authError && (
                    <span>
                      Wystąpił błąd przy resetowaniu hasła
                      <br />
                      (
                      {authError}
                      )
                    </span>
                    )}
                  </ResponseContainer>
                </form>
              </LoginFormContainer>
            </ContainerCol4>
            <div className="col-sm-8">
              <div>
                <Image src="img/home-background.jpg" alt="" />
              </div>
            </div>
          </LoginContainerRow>
        </div>
      </Container>
    </FormikProvider>
  );
};

export default ForgottenPassword;
