import React from 'react';

export const LONG_PROFILE_NO = 'longProfileNo';
export const LONG_PROFILE_20_20 = 'longProfile20x20';
export const LONG_PROFILE_40_20 = 'longProfile40x20';
export const LONG_PROFILE_80_20 = 'longProfile80x20';
export const LONG_PROFILE_100_20 = 'longProfile100x20';

const LongProfile = ({
  formId, onChange, value, profile20, profile40, profile80, profile100,
}) => {
  const profile20exists = !!profile20;
  const profile40exists = !!profile40;
  const profile80exists = !!profile80;
  const profile100exists = !!profile100;

  return (
    <div className="longProfileInput">
      <div className="form-group">
        <label htmlFor={`longProfile__${formId}`}>Długi profil</label>
        <select
          className="form-control"
          id={`longProfile__${formId}`}
          name="longProfile"
          onChange={onChange}
          value={value}
          required
        >
          <option value={LONG_PROFILE_NO}>BRAK</option>
          {profile20exists && <option value={LONG_PROFILE_20_20}>20x20</option>}
          {profile40exists && <option value={LONG_PROFILE_40_20}>40x20</option>}
          {profile80exists && <option value={LONG_PROFILE_80_20}>80x20</option>}
          {profile100exists && <option value={LONG_PROFILE_100_20}>100x20</option>}
        </select>
      </div>
    </div>
  );
};

export default LongProfile;
