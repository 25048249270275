import React from 'react';
import {
  POLES_LACK,
  POLES_ONE,
  POLES_STANDARD,
  POLES_GSS_ZERO,
  POLES_GSS_ONE,
  POLES_GSS_TWO,
  POLES_GSS_THREE,
} from './Poles';

export const HOLDER_15_15 = '15x15';
export const HOLDER_20_14 = '20x14';
export const HOLDER_20_25 = '20x25';
export const HOLDER_NO = 'no';
export const HOLDER_GSS_ONE = 1;
export const HOLDER_GSS_TWO = 2;
export const HOLDER_GSS_THREE = 3;
export const HOLDER_FORM_NAME = 'holder';

const HOLDER_15_15_OBJECT = {
  label: '15x15',
  value: HOLDER_15_15,
};

const HOLDER_20_14_OBJECT = {
  label: '20x14',
  value: HOLDER_20_14,
};

const HOLDER_20_25_OBJECT = {
  label: '20x25',
  value: HOLDER_20_25,
};

const HOLDER_NO_OBJECT = {
  label: 'BRAK',
  value: HOLDER_NO,
};

const HOLDER_GSS_ZERO_OBJECT = {
  label: '0 szt',
  value: HOLDER_NO,
};

const HOLDER_GSS_ONE_OBJECT = {
  label: '1 szt',
  value: HOLDER_GSS_ONE,
};

const HOLDER_GSS_TWO_OBJECT = {
  label: '2 szt',
  value: HOLDER_GSS_TWO,
};

const HOLDER_GSS_THREE_OBJECT = {
  label: '3 szt',
  value: HOLDER_GSS_THREE,
};

const HolderSelect = ({
  label,
  classNames,
  options,
  onChange,
  formId,
  required,
  value,
}) => {
  const optionsParsed = options.map((o) => <option key={Math.random()} value={o.value}>{o.label}</option>);

  return (
    <div className={classNames}>
      <label htmlFor={`holder__${formId}`}>{label}</label>
      <select
        className="form-control"
        id={`holder__${formId}`}
        name={HOLDER_FORM_NAME}
        onChange={onChange}
        value={value}
        required={required}
      >
        <option value="" disabled>Wybierz</option>
        {optionsParsed}
      </select>
    </div>
  );
};

const Holder = ({
  formId, onChange, poles = null, value,
}) => {
  const getProps = () => {
    const getClassNames = () => {
      if (!poles) {
        return 'form-group formGroupNotVisible';
      }

      if (poles === POLES_LACK) {
        return 'form-group formGroupNotVisible';
      }
      if (poles === POLES_STANDARD || poles === POLES_ONE) {
        return 'form-group';
      }

      const polesInt = parseInt(poles);

      if (polesInt === POLES_GSS_ZERO || polesInt === POLES_GSS_ONE || polesInt === POLES_GSS_TWO) {
        return 'form-group';
      }
      if (polesInt === POLES_GSS_THREE) {
        return 'form-group formGroupNotVisible';
      }
      throw new Error('Case not supported.');
    };

    const getLabel = () => {
      if (!poles) {
        return '';
      }
      if (poles === POLES_LACK || poles === POLES_STANDARD || poles === POLES_ONE) {
        return 'Marka';
      }

      const polesInt = parseInt(poles);

      if (polesInt === POLES_GSS_ZERO || polesInt === POLES_GSS_ONE || polesInt === POLES_GSS_TWO || polesInt === POLES_GSS_THREE) {
        return 'Słupy na marce';
      }

      throw new Error('Case not supported.');
    };

    const getOptions = () => {
      if (!poles) {
        return [];
      }
      if (poles === POLES_LACK || poles === POLES_STANDARD || poles === POLES_ONE) {
        return [
          HOLDER_NO_OBJECT,
          HOLDER_15_15_OBJECT,
          HOLDER_20_14_OBJECT,
          HOLDER_20_25_OBJECT,
        ];
      }

      const polesInt = parseInt(poles);

      if (polesInt === POLES_GSS_ZERO) {
        return [
          HOLDER_GSS_ZERO_OBJECT,
          HOLDER_GSS_ONE_OBJECT,
          HOLDER_GSS_TWO_OBJECT,
          HOLDER_GSS_THREE_OBJECT,
        ];
      }

      if (polesInt === POLES_GSS_ONE) {
        return [
          HOLDER_GSS_ZERO_OBJECT,
          HOLDER_GSS_ONE_OBJECT,
          HOLDER_GSS_TWO_OBJECT,
        ];
      }

      if (polesInt === POLES_GSS_TWO || POLES_GSS_THREE) {
        return [
          HOLDER_GSS_ZERO_OBJECT,
          HOLDER_GSS_ONE_OBJECT,
        ];
      }

      throw new Error('Case not supported.');
    };

    const classNames = getClassNames();
    const label = getLabel();
    const options = getOptions();

    const required = !poles || poles === POLES_STANDARD;

    return {
      label,
      classNames,
      options,
      onChange,
      formId,
      required,
      value,
    };
  };
  const props = getProps();

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <HolderSelect {...props} />
  );
};

export default Holder;
