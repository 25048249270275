import axios from 'axios';
import React from 'react';

const fencesTypesInPolish = {
  samonosna: 'Brama samonośna',
  gate_tilting: 'Brama uchylna',
  uchylna_od_6000: 'Brama uchylna',
  gateway: 'Furtka',
  single_leaf_gate: 'Brama jednoskrzydłowa',
  span: 'Przęsło',
  folding_gate: 'Brama łamana',
  single_leaf_folding_gate: 'Brama łamana jednoskrzydłowa',
  uchylna: 'Bramo-furtka',
  basePolycarbonate: 'Baza poliwęglan',
  basePlate: 'Baza płyta trapezowa',
  baseSolarPanels: 'Baza fotowoltaika',
  extensionPolycarbonate: 'Rozbudowa poliwęglan',
  extensionPlate: 'Rozbudowa płyta trapezowa',
  extensionSolarPanels: 'Rozbudowa fotowoltaika',
  ballast: 'Balast',
  windchest: 'Wiatrownica',
};

const buttonStyle = {
  border: 'none',
  cursor: 'pointer',
  padding: '5px',
  borderRadius: '5px',
  transition: 'background-color 0.3s ease',
};

const disableProduct = async (order) => {
  const zamowieniaId = sessionStorage.getItem('zamowieniaId');
  const payload = {
    html: '',
    header: '',
    order,
  };
  await axios.post(
    `/production-materials-manually-calculated/${zamowieniaId}/html`,
    payload,
  );
};

const IconButton = ({ order }) => (
  <button className="btn btn-danger" type="button" onClick={() => disableProduct(order)} style={buttonStyle}>
    <b>Usuń widok produktu</b>
  </button>
);

const ProductTable = ({ product }) => {
  const vals = product?.rawArray;
  const height = vals?.height ? vals.height : '';
  const width = vals?.width ? vals.width : '';
  const typ = vals?.assymetry ? vals.assymetry.toLowerCase() : fencesTypesInPolish[vals?.type];
  return (
    <section>
      <div className="container-xl">
        <div className="row">
          <div className="col">
            <div>
              <b>Lp: </b>
              {product.order}
              {' | '}
              <b>Model: </b>
              {vals?.model}
              {' | '}
              <b>Typ: </b>
              {typ}
              {' | '}
              <b>Wymiary: </b>
              {height}
              {' x '}
              {width}
              {' | '}
              {product.header && <IconButton order={product.order} />}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProductTable;
