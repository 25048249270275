import React from 'react';

export const MASKING_ROD_YES = 'TAK';
export const MASKING_ROD_NO = 'NIE';

const MaskingRod = ({
  formId, onChange, value,
}) => (
  <div className="hingesInput">
    <div className="form-group">
      <label htmlFor={`maskingRod__${formId}`}>Kątownik maskujący</label>
      <select
        className="form-control"
        id={`maskingRod__${formId}`}
        name="maskingRod"
        onChange={onChange}
        value={value}
        required
      >
        <option value="" disabled>Wybierz</option>
        <option value={MASKING_ROD_YES}>TAK</option>
        <option value={MASKING_ROD_NO}>NIE</option>
      </select>
    </div>
  </div>
);

export default MaskingRod;
