import React from 'react';

export const GEAR_RAIL_YES = 'tak';
export const GEAR_RAIL_NO = 'nie';
export const GEAR_RAIL_FORM_NAME = 'gearRail';

const GearRail = ({ formId, onChange, value }) => (
  <div className="form-group">
    <label htmlFor={`gearRail__${formId}`}>Listwa zębata</label>
    <select
      className="form-control"
      id={`gearRail__${formId}`}
      name={GEAR_RAIL_FORM_NAME}
      onChange={onChange}
      value={value}
      required
    >
      <option value="" disabled>Wybierz</option>
      <option value={GEAR_RAIL_YES}>TAK</option>
      <option value={GEAR_RAIL_NO}>NIE</option>
    </select>
  </div>
);

export default GearRail;
