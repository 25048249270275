import React from 'react';
import PropTypes from 'prop-types';

const SpanResponse = ({ response, isPdf }) => {
  const saw = Object.keys(response.productionMaterials.saw).map((key, index) => (
    <tr key={key}>
      <td>{index + 1}</td>
      <td>{response.productionMaterials.saw[key].name}</td>
      <td>{response.productionMaterials.saw[key].material}</td>
      <td>
        {response.productionMaterials.saw[key].amount.value}
        {' '}
        {response.productionMaterials.saw[key].amount.unit}
      </td>
      <td>
        {response.productionMaterials.saw[key].size.value}
        {' '}
        {response.productionMaterials.saw[key].size.unit}
      </td>
      {isPdf && (
      <>
        <td />
        <td className="tdCenter">
          <div className="pdfSquare" />
        </td>
      </>
      )}
    </tr>
  ));

  return (
    <div>
      <table className="table">
        <thead>
          <tr>
            <th colSpan={isPdf ? '7' : '5'}>PIŁA - MATERIAŁY DO WYCIĘCIA</th>
          </tr>
          <tr>
            <th className="responseThSingular">Lp.</th>
            <th className="responseThName">Nazwa</th>
            <th className="responseThMaterial">Materiał</th>
            <th className="responseThQuantity">Ilość</th>
            <th className="responseThMeasure">Wymiar</th>
            {isPdf && (
              <>
                <th className="responseThSaws">PIŁA</th>
                <th className="responseThD">2D</th>
              </>
            )}
          </tr>
        </thead>
        <tbody>
          {saw}
        </tbody>
      </table>
      <table className="table">
        <thead>
          <tr>
            <th colSpan={isPdf ? 7 : 5}>SPAWALNIA</th>
          </tr>
          <tr>
            <th className="responseThSingular">Lp.</th>
            <th className="responseThName">Nazwa</th>
            <th className="responseThMaterial">Materiał</th>
            <th className="responseThQuantity">Ilość</th>
            {isPdf && (
              <>
                <th>{' '}</th>
                <th className="responseThSaws">SPAWALNIA</th>
              </>
            )}
          </tr>
        </thead>
        <tbody>
          {Object.keys(response.productionMaterials.welding).map((key, index) => (
            <tr key={key}>
              <td>{index + 1}</td>
              <td>{response.productionMaterials.welding[key].name}</td>
              <td>{response.productionMaterials.welding[key].material}</td>
              <td>
                {response.productionMaterials.welding[key].amount.value}
                {' '}
                {response.productionMaterials.welding[key].amount.unit}
              </td>
              {isPdf && (
                <>
                  <td />
                  <td className="tdCenter">
                    <div className="pdfSquare" />
                  </td>
                </>
              )}
            </tr>
          ))}
        </tbody>
      </table>
      <table className="table">
        <thead>
          <tr>
            <th colSpan={isPdf ? 7 : 5}>BUFOR</th>
          </tr>
          <tr>
            <th className="responseThSingular">Lp.</th>
            <th className="responseThName">Nazwa</th>
            <th className="responseThMaterial">Materiał</th>
            <th className="responseThQuantity">Ilość</th>
            {isPdf && (
              <>
                <th>{' '}</th>
                <th className="responseThSaws">BUFOR</th>
              </>
            )}
          </tr>
        </thead>
        <tbody>
          {Object.keys(response.productionMaterials.buffer).map((key, index) => (
            <tr key={key}>
              <td>{index + 1}</td>
              <td>{response.productionMaterials.buffer[key].name}</td>
              <td>{response.productionMaterials.buffer[key].material}</td>
              <td>
                {response.productionMaterials.buffer[key].amount.value}
                {' '}
                {response.productionMaterials.buffer[key].amount.unit}
              </td>
              {isPdf && (
                <>
                  <td />
                  <td className="tdCenter">
                    <div className="pdfSquare" />
                  </td>
                </>
              )}
            </tr>
          ))}
        </tbody>
      </table>
      <table className="table">
        <thead>
          <tr>
            <th colSpan={isPdf ? 7 : 5}>MAGAZYN - OSPRZĘT</th>
          </tr>
          <tr>
            <th className="responseThSingular">Lp.</th>
            <th className="responseThName">Nazwa</th>
            <th className="responseThMaterial">Materiał</th>
            <th className="responseThQuantity">Ilość</th>
            {isPdf && (
            <>
              <th>{' '}</th>
              <th className="responseThSaws">OSPRZĘT</th>
            </>
            )}
          </tr>
        </thead>
        <tbody>
          {Object.keys(response.productionMaterials.storage).map((key, index) => (
            <tr key={key}>
              <td>{index + 1}</td>
              <td>{response.productionMaterials.storage[key].name}</td>
              <td>{response.productionMaterials.storage[key].material}</td>
              <td>
                {response.productionMaterials.storage[key].amount.value}
                {' '}
                {response.productionMaterials.storage[key].amount.unit}
              </td>
              {isPdf && (
              <>
                <td />
                <td className="tdCenter">
                  <div className="pdfSquare" />
                </td>
              </>
              )}
            </tr>
          ))}
        </tbody>
      </table>
      <>
        <div>
          <p>
            <b>Rozstaw między poziomami:</b>
            {' '}
            {response.productionMaterials.info.levelSpacing.size.value}
            {' '}
            {response.productionMaterials.info.levelSpacing.size.unit}
          </p>
        </div>
      </>
    </div>
  );
};

SpanResponse.propTypes = {
  response: PropTypes.object.isRequired,
};

export default SpanResponse;
