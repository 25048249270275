import React from 'react';

export const FERRULE_SHORT = 'ferruleShort';
export const FERRULE_LONG = 'ferruleLong';
export const FERRULE_HANDLEHANDLE = 'ferruleHandleHandle';
export const FERRULE_HANDLEKNOB = 'ferruleHandleKnob';
export const FERRULE_KNOBKNOB = 'ferruleKnobKnob';
export const FERRULE_STAINLESS800 = 'ferruleStainless800';
export const FERRULE_STAINLESS1400 = 'ferruleStainless1400';
export const FERRULE_STAINLESS800OVAL = 'ferruleStainless800oval';
export const FERRULE_STAINLESS1400OVAL = 'ferruleStainless1400oval';
export const FERRULE_BLACK800 = 'ferruleBlack800';
export const FERRULE_BLACK1400 = 'ferruleBlack1400';
export const FERRULE_BLACK800OVAL = 'ferruleBlack800oval';
export const FERRULE_BLACK1400OVAL = 'ferruleBlack1400oval';

const Ferrule = ({ formId, onChange, value }) => (
  <div className="form-group">
    <label htmlFor={`ferrule__${formId}`}>Okucie</label>
    <select
      className="form-control"
      id={`ferrule__${formId}`}
      name="ferrule"
      onChange={onChange}
      value={value}
      required
    >
      <option value="" disabled>Wybierz</option>
      <option value={FERRULE_SHORT}>Pochwyt krótki</option>
      <option value={FERRULE_LONG}>Pochwyt długi</option>
      <option value={FERRULE_HANDLEHANDLE}>Klamka/klamka</option>
      <option value={FERRULE_HANDLEKNOB}>Klamka/gałka</option>
      <option value={FERRULE_KNOBKNOB}>Gałka/gałka</option>
      <option value={FERRULE_STAINLESS800}>Pochwyt kwadratowy nierdzewny 800</option>
      <option value={FERRULE_STAINLESS1400}>Pochwyt kwadratowy nierdzewny 1400</option>
      <option value={FERRULE_STAINLESS800OVAL}>Pochwyt okrągły nierdzewny 800</option>
      <option value={FERRULE_STAINLESS1400OVAL}>Pochwyt okrągły nierdzewny 1400</option>
      <option value={FERRULE_BLACK800}>Pochwyt kwadratowy czarny 800</option>
      <option value={FERRULE_BLACK1400}>Pochwyt kwadratowy czarny 1400</option>
      <option value={FERRULE_BLACK800OVAL}>Pochwyt okrągły czarny 800</option>
      <option value={FERRULE_BLACK1400OVAL}>Pochwyt okrągły czarny 1400</option>
      <option value="no">Bez pochwytów</option>
    </select>
  </div>
);

export default Ferrule;
