import React from 'react';
import PropTypes from 'prop-types';
import { HOLDER_NO } from '../Input/Holder';
import { POLES_LACK } from '../Input/Poles';

const GateGatewayResponse = ({
  values, hdg, response, isPdf,
}) => {
  let angles = null;
  if (response.leftWingWidth < 3000 && response.rightWingWidth < 3000) {
    angles = Object.keys(response.productionMaterials.angles).map((key, index) => (
      <tr key={key}>
        <td>{index + 1}</td>
        <td>{response.productionMaterials.angles[key].name}</td>
        <td>{response.productionMaterials.angles[key].material}</td>
        <td>
          {response.productionMaterials.angles[key].amount.value}
          {' '}
          {response.productionMaterials.angles[key].amount.unit}
        </td>
        <td>
          {response.productionMaterials.angles[key].size.value}
          {' '}
          {response.productionMaterials.angles[key].size.unit}
        </td>
        {isPdf && (
          <>
            <td />
            <td className="tdCenter">
              <div className="pdfSquare" />
            </td>
          </>
        )}
      </tr>
    ));
  }

  const laser = Object.keys(response.productionMaterials.laser).map((key, index) => (
    <tr key={key}>
      <td>{index + 1}</td>
      <td>{response.productionMaterials.laser[key].name}</td>
      <td>{response.productionMaterials.laser[key].material}</td>
      <td>
        {response.productionMaterials.laser[key].amount.value}
        {' '}
        {response.productionMaterials.laser[key].amount.unit}
      </td>
      <td>
        {response.productionMaterials.laser[key].size.value}
        {' '}
        {response.productionMaterials.laser[key].size.unit}
      </td>
      {isPdf && (
        <>
          <td />
          <td className="tdCenter">
            <div className="pdfSquare" />
          </td>
        </>
      )}
    </tr>
  ));

  const saw = Object.keys(response.productionMaterials.saw).map((key, index) => (
    <tr key={key}>
      <td>{index + 1}</td>
      <td>{response.productionMaterials.saw[key].name}</td>
      <td>{response.productionMaterials.saw[key].material}</td>
      <td>
        {response.productionMaterials.saw[key].amount.value}
        {' '}
        {response.productionMaterials.saw[key].amount.unit}
      </td>
      <td>
        {response.productionMaterials.saw[key].size.value}
        {' '}
        {response.productionMaterials.saw[key].size.unit}
      </td>
      {isPdf && (
        <>
          <td className="tdCenter">
            <div className="pdfSquare" />
          </td>
          <td className="tdCenter">
            <div className="pdfSquare" />
          </td>
        </>
      )}
    </tr>
  ));

  const TechnologicalOpening = () => {
    if (hdg && values.poles !== POLES_LACK) {
      if (values.holder === HOLDER_NO) {
        if (response.productionMaterials.saw.poles.size.value <= 3000) {
          return (
            <>
              <p><b>OTWORY TECHNOLOGICZNE NA SŁUPACH DO WBETONOWANIA</b></p>
              <p>
                <b>Otwór 1</b>
                {': '}
                od góry 10 mm na mniejszej ściance
              </p>
            </>
          );
        }
        return (
          <>
            <p><b>OTWORY TECHNOLOGICZNE NA SŁUPACH DO WBETONOWANIA</b></p>
            <p>
              <b>Otwór 1</b>
              {': '}
              od góry 10 mm na mniejszej ściance
              <>
                {' '}
                |
                {' '}
                <b>Otwór 2</b>
                {': '}
                od dołu 10 mm na mniejszej ściance
              </>
            </p>
          </>
        );
      }
      if (response.productionMaterials.saw.poles.size.value <= 3000) {
        return null;
      }
      return (
        <>
          <p><b>OTWORY TECHNOLOGICZNE NA SŁUPACH NA MARCE</b></p>
          <p>
            <b>Otwór 1</b>
            {': '}
            od góry 10 mm na mniejszej ściance
          </p>
        </>
      );
    } return null;
  };

  return (
    <div>
      {response.leftWingWidth < 3000 && response.rightWingWidth < 3000
        && (
          <table className="table">
            <thead>
              <tr>
                <th colSpan={isPdf ? 7 : 5}>KĄTY</th>
              </tr>
              <tr>
                <th className="responseThSingular">Lp.</th>
                <th className="responseThName">Nazwa</th>
                <th className="responseThMaterial">Materiał</th>
                <th className="responseThQuantity">Ilość</th>
                <th className="responseThMeasure">Wymiar</th>
                {isPdf && (
                  <>
                    <th>{' '}</th>
                    <th className="responseThSaws">KĄTY</th>
                  </>
                )}
              </tr>
            </thead>
            <tbody>
              {angles}
            </tbody>
          </table>
        )}
      <table className="table">
        <thead>
          <tr>
            <th colSpan={isPdf ? 7 : 5}>LASER - MATERIAŁY DO WYPALENIA</th>
          </tr>
          <tr>
            <th className="responseThSingular">Lp.</th>
            <th className="responseThName">Nazwa</th>
            <th className="responseThMaterial">Materiał</th>
            <th className="responseThQuantity">Ilość</th>
            <th className="responseThMeasure">Wymiar</th>
            {isPdf && (
            <>
              <th className="responseThSaws">3D</th>
              <th>{' '}</th>
            </>
            )}
          </tr>
        </thead>
        <tbody>
          {laser}
        </tbody>
      </table>
      <table className="table">
        <thead>
          <tr>
            <th colSpan={isPdf ? '7' : '5'}>PIŁA - MATERIAŁY DO WYCIĘCIA</th>
          </tr>
          <tr>
            <th className="responseThSingular">Lp.</th>
            <th className="responseThName">Nazwa</th>
            <th className="responseThMaterial">Materiał</th>
            <th className="responseThQuantity">Ilość</th>
            <th className="responseThMeasure">Wymiar</th>
            {isPdf && (
            <>
              <th className="responseThSaws">PIŁA</th>
              <th className="responseThD">2D</th>
            </>
            )}
          </tr>
        </thead>
        <tbody>
          {saw}
        </tbody>
      </table>
      <TechnologicalOpening />
    </div>
  );
};

GateGatewayResponse.propTypes = {
  response: PropTypes.object.isRequired,
};

export default GateGatewayResponse;
