import React from 'react';
import PropTypes from 'prop-types';
import TableSolarPanels from './TableSolarPanels';

const TableSolarPanel = ({ form, addNextForm, updateForm }) => (
  <section>
    <TableSolarPanels form={form} addNextForm={addNextForm} updateForm={updateForm} />
  </section>
);

TableSolarPanel.propTypes = {
  form: PropTypes.object.isRequired,

  updateForm: PropTypes.func.isRequired,
};

export default TableSolarPanel;
