import React from 'react';

export const GATE_DIRECTION_LEFT = 'lewa';
export const GATE_DIRECTION_RIGHT = 'prawa';

const GateDirection = ({ formId, onChange, value }) => (
  <div className="form-group">
    <label htmlFor={`gateDirection__${formId}`}>Kierunek</label>
    <select
      className="form-control"
      id={`gateDirection__${formId}`}
      onChange={onChange}
      name="gateDirection"
      value={value || ''}
      required
    >
      <option value="" disabled>Wybierz</option>
      <option value={GATE_DIRECTION_LEFT}>Lewa</option>
      <option value={GATE_DIRECTION_RIGHT}>Prawa</option>
    </select>
  </div>
);

export default GateDirection;
