import React from 'react';

export const LOCK_AND_HANDRAIL_NO = 'no';
export const LOCK_AND_HANDRAIL_YES = 'yes';

const LockAndHandrail = ({ formId, onChange, value }) => (
  <div className="form-group">
    <label htmlFor={`lockAndHandrail__${formId}`}>Zamek i pochwyt</label>
    <select
      className="form-control"
      id={`lockAndHandrail__${formId}`}
      onChange={onChange}
      name="lockAndHandrail"
      value={value}
      required
    >

      <option value="" disabled>Wybierz</option>
      <option value={LOCK_AND_HANDRAIL_YES}>TAK</option>
      <option value={LOCK_AND_HANDRAIL_NO}>NIE</option>
    </select>
  </div>
);

export default LockAndHandrail;
