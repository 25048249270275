import React from 'react';

export const STRIP_STANDARD = 'standard';
export const STRIP_LONG = 'long';

const Strip = ({ formId, onChange, value }) => (
  <div className="form-group">
    <label htmlFor={`strip__${formId}`}>Listwa domykowa</label>
    <select
      className="form-control"
      id={`strip__${formId}`}
      name="strip"
      value={value}
      onChange={onChange}
      required
    >
      <option value="" disabled>Wybierz</option>
      <option value={STRIP_STANDARD}>Standard</option>
      <option value={STRIP_LONG}>Długa</option>
    </select>
  </div>
);

export default Strip;
