import React from 'react';
import './OrderFormVersionBtn.css';

const OrderFormVersionBtn = () => (
  <a href="https://production.konsport.com" target="_blank" rel="noreferrer">
    <button type="button" className="orderFormVersionBtn btn btn-danger" id="OrderFormVersionBtn">
      Wypełniasz formularz w serwisie testowym.
      <br />
      Jeśli chcesz przejść do formularza w serwisie produkcyjnym, kliknij tutaj.
    </button>
  </a>
);

export default OrderFormVersionBtn;
