import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import { Provider } from 'react-redux';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import axios from 'axios';

import App from 'App/App';
import AppAuth from 'App/AppAuth';

import * as serviceWorker from './serviceWorker';
import appReducer from './reducers';

const store = createStore(appReducer, composeWithDevTools(applyMiddleware(thunk)));

if (process.env.NODE_ENV === 'production') {
  Sentry.init({ dsn: 'https://cc01ed4e335e4fccaded23a28edeac74@o380431.ingest.sentry.io/5281041' });
}

axios.defaults.baseURL = process.env.REACT_APP_API_URL.trim();

ReactDOM.render(
  <Provider store={store}>
    <script src="https://cdn.sheetjs.com/xlsx-latest/package/dist/xlsx.full.min.js" />
    <React.StrictMode>
      <AppAuth>
        <App />
      </AppAuth>
    </React.StrictMode>
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
