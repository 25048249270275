import React from 'react';
import PropTypes from 'prop-types';

const GatewayResponse = ({ hdg, response, isPdf }) => {
  const TechnologicalOpening = () => {
    if (hdg) {
      if (response.productionMaterials.saw.poles.size.value <= 3000) {
        return (
          <>
            <p><b>OTWORY TECHNOLOGICZNE NA SŁUPACH</b></p>
            <p>
              <b>Otwór 1</b>
              {': '}
              od góry 10 mm na mniejszej ściance
            </p>
          </>
        );
      }
      return (
        <>
          <p><b>OTWORY TECHNOLOGICZNE NA SŁUPACH</b></p>
          <p>
            <b>Otwór 1</b>
            {': '}
            od góry 10 mm na mniejszej ściance
            <>
              {' '}
              |
              {' '}
              <b>Otwór 2</b>
              {': '}
              od dołu 10 mm na mniejszej ściance
            </>
          </p>
        </>
      );
    } return null;
  };
  const laser = Object.keys(response.productionMaterials.laser).map((key, index) => (
    <tr key={key}>
      <td>{index + 1}</td>
      <td>{response.productionMaterials.laser[key].name}</td>
      <td>{response.productionMaterials.laser[key].material}</td>
      <td>
        {response.productionMaterials.laser[key].amount.value}
        {' '}
        {response.productionMaterials.laser[key].amount.unit}
      </td>
      <td>
        {response.productionMaterials.laser[key].size.value}
        {' '}
        {response.productionMaterials.laser[key].size.unit}
      </td>
      {isPdf && (
        <>
          <td />
          <td className="tdCenter">
            <div className="pdfSquare" />
          </td>
        </>
      )}
    </tr>
  ));

  const saw = Object.keys(response.productionMaterials.saw).map((key, index) => (
    <tr key={key}>
      <td>{index + 1}</td>
      <td>{response.productionMaterials.saw[key].name}</td>
      <td>{response.productionMaterials.saw[key].material}</td>
      <td>
        {response.productionMaterials.saw[key].amount.value}
        {' '}
        {response.productionMaterials.saw[key].amount.unit}
      </td>
      <td>
        {response.productionMaterials.saw[key].size.value}
        {' '}
        {response.productionMaterials.saw[key].size.unit}
      </td>
      {isPdf && (
        <>
          <td className="tdCenter">
            <div className="pdfSquare" />
          </td>
          <td className="tdCenter">
            <div className="pdfSquare" />
          </td>
        </>
      )}
    </tr>
  ));

  return (
    <div>
      <table className="table">
        <thead>
          <tr>
            <th colSpan={isPdf ? '7' : '5'}>LASER</th>
          </tr>
          <tr>
            <th className="responseThSingular">Lp.</th>
            <th className="responseThName">Nazwa</th>
            <th className="responseThMaterial">Materiał</th>
            <th className="responseThQuantity">Ilość</th>
            <th className="responseThMeasure">Wymiar</th>
            {isPdf && (
            <>
              <th className="responseThSaws" aria-label="extraColumn" />
              <th className="responseThD">3D</th>
            </>
            )}
          </tr>
        </thead>
        <tbody>
          {laser}
        </tbody>
      </table>
      <table className="table">
        <thead>
          <tr>
            <th colSpan={isPdf ? '7' : '5'}>PIŁA - MATERIAŁY DO WYCIĘCIA</th>
          </tr>
          <tr>
            <th className="responseThSingular">Lp.</th>
            <th className="responseThName">Nazwa</th>
            <th className="responseThMaterial">Materiał</th>
            <th className="responseThQuantity">Ilość</th>
            <th className="responseThMeasure">Wymiar</th>
            {isPdf && (
            <>
              <th className="responseThSaws">PIŁA</th>
              <th className="responseThD">2D</th>
            </>
            )}
          </tr>
        </thead>
        <tbody>
          {saw}
        </tbody>
      </table>
      <TechnologicalOpening />
    </div>
  );
};

GatewayResponse.propTypes = {
  response: PropTypes.object.isRequired,
};

export default GatewayResponse;
