import React from 'react';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import FormActions from '../Input/FormActions';
import invoke from '../../lambda';
import { COLOR_HDG } from '../OrderForm/OrderFormFenceColor';
import GateTiltingResponse from './GateTiltingResponse';

const GateTilting = ({ form, updateForm }) => {
  const formik = useFormik({
    initialValues: {
      height: form.values.height || '',
      width: form.values.width || '',
    },
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);

      const res = await invoke({
        amount: form.quantity,
        model: 'ECO',
        type: 'gate_tilting',
        ...values,
      });

      const newForm = {
        ...form,
        values: {
          ...values,
        },
        response: {
          ...res.products[0],
        },
      };

      setSubmitting(false);
      updateForm(newForm);
    },
    validate: ({ height, width }) => {
      const errors = {};

      if (!height) {
        errors.height = 'Wymagana wartość';
      }

      if (!width) {
        errors.width = 'Wymagana wartość';
      }

      return errors;
    },
  });

  const isResponseAvailable = Object.keys(form.response).length > 0;

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="formInputs">
        <div className="form-group">
          <label htmlFor={`height__${form.id}`}>Wysokość (mm)</label>
          <select
            className="form-control"
            id={`height__${form.id}`}
            name="height"
            onChange={formik.handleChange}
            value={formik.values.height}
            required
          >
            <option value="" disabled>Wybierz</option>
            <option key={Math.random()} value={1480}>1480</option>
            <option key={Math.random()} value={1680}>1680</option>
            <option key={Math.random()} value={2080}>2080</option>
          </select>
          <span className="error">{formik.errors.height && formik.errors.height}</span>
        </div>
        <div className="form-group">
          <label htmlFor={`width__${form.id}`}>Szerokość (mm)</label>
          <select
            className="form-control"
            id={`width__${form.id}`}
            name="width"
            onChange={formik.handleChange}
            value={formik.values.width}
            required
          >
            <option value="" disabled>Wybierz</option>
            <option value={3115}>3115</option>
            <option value={4115}>4115</option>
          </select>
          <span className="error">{formik.errors.width && formik.errors.width}</span>
        </div>
      </div>
      <FormActions isSubmitting={formik.isSubmitting} />
      {form.response?.error && (
        <h2 style={{ color: 'red' }}>
          Wystąpił błąd:
          {' '}
          {form.response.error}
        </h2>
      )}
      {isResponseAvailable && !form.response?.error && <GateTiltingResponse response={form.response} hdg={form.color === COLOR_HDG} isPdf={false} />}
    </form>
  );
};

GateTilting.propTypes = {
  form: PropTypes.object.isRequired,
  updateForm: PropTypes.func.isRequired,
};

export default GateTilting;
