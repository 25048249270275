import React from 'react';

export const SPAN_READY_SIZE_YES = 'yes';
export const SPAN_READY_SIZE_NO = 'no';

export const spanReadySizeInPolish = {
  [SPAN_READY_SIZE_NO]: 'NIE',
  [SPAN_READY_SIZE_YES]: 'TAK',
};

const SpanReadySize = ({ formId, onChange, value }) => (
  <div className="form-group">
    <label htmlFor={`spanReadySize__${formId}`}>Wymiar na gotowo</label>
    <select
      className="form-control"
      id={`spanReadySize__${formId}`}
      name="spanReadySize"
      value={value || ''}
      onChange={onChange}
      required
    >
      <option value="" disabled>Wybierz</option>
      <option value={SPAN_READY_SIZE_YES}>TAK</option>
      <option value={SPAN_READY_SIZE_NO}>NIE</option>
    </select>
  </div>
);

export default SpanReadySize;
