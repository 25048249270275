import React from 'react';
import PropTypes from 'prop-types';
import PoleSolarPanel from './PoleSolarPanel';

const SolarPanelPole = ({ form, addNextForm, updateForm }) => (
  <section>
    <PoleSolarPanel form={form} addNextForm={addNextForm} updateForm={updateForm} />
  </section>
);

SolarPanelPole.propTypes = {
  form: PropTypes.object.isRequired,

  updateForm: PropTypes.func.isRequired,
};

export default SolarPanelPole;
