import React from 'react';
import { COUNTERWEIGHT_NON_STANDARD } from './Counterweight';

export const FULL_LENGTH_FORM_NAME = 'fullLength';

const FullLength = ({
  formId, onChange, counterweight, value = null,
}) => {
  const classNames = () => {
    if (counterweight === COUNTERWEIGHT_NON_STANDARD) {
      return 'form-group';
    }
    return 'form-group formGroupNotVisible';
  };

  const required = counterweight === COUNTERWEIGHT_NON_STANDARD;

  return (
    <div className={classNames()}>
      <label htmlFor={`fullLength__${formId}`}>Całkowita długość bramy (mm)</label>
      <input
        type="number"
        className="form-control"
        placeholder="Wpisz"
        id={`fullLength__${formId}`}
        name={FULL_LENGTH_FORM_NAME}
        value={value}
        autoComplete="off"
        onChange={onChange}
        required={required}
      />
    </div>
  );
};

export default FullLength;
