import React from 'react';
import PropTypes from 'prop-types';
import BasePolycarbonate from './BasePolycarbonate';
import BasePlate from './BasePlate';
import BaseSolarPanels from './BaseSolarPanels';
import ExtensionPolycarbonate from './ExtensionPolycarbonate';
import ExtensionPlate from './ExtensionPlate';
import ExtensionSolarPanels from './ExtensionSolarPanels';

const Carport = ({ form, addNextForm, updateForm }) => {
  const FormComponent = () => {
    // TODO try to dynamically render type, to avoid switch case
    switch (form.type) {
      case 'basePolycarbonate':
        return <BasePolycarbonate form={form} addNextForm={addNextForm} updateForm={updateForm} />;
      case 'basePlate':
        return <BasePlate form={form} addNextForm={addNextForm} updateForm={updateForm} />;
      case 'baseSolarPanels':
        return <BaseSolarPanels form={form} addNextForm={addNextForm} updateForm={updateForm} />;
      case 'extensionPolycarbonate':
        return <ExtensionPolycarbonate form={form} addNextForm={addNextForm} updateForm={updateForm} />;
      case 'extensionPlate':
        return <ExtensionPlate form={form} addNextForm={addNextForm} updateForm={updateForm} />;
      case 'extensionSolarPanels':
        return <ExtensionSolarPanels form={form} addNextForm={addNextForm} updateForm={updateForm} />;
      default:
        throw new Error(`Unknown type (${form.type}).`);
    }
  };

  return (
    <section>
      <FormComponent />
    </section>
  );
};

Carport.propTypes = {
  form: PropTypes.object.isRequired,

  updateForm: PropTypes.func.isRequired,
};

export default Carport;
