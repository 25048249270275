import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { canShowActions } from './AddNextForm';
import { SET_GENERAL_COMMENT } from '../actions';
import './GeneralComments.css';

const GeneralComments = () => {
  const dispatch = useDispatch();

  const [savingInfo, setSavingInfo] = useState('');

  const generalComment = useSelector((state) => state.app.generalComment);
  const forms = useSelector((state) => state.app.forms);

  const showActions = canShowActions(forms);

  if (!showActions) {
    return null;
  }

  const handleChange = (e) => {
    e.preventDefault();

    setSavingInfo('Zapisuję . . .');

    dispatch({ type: SET_GENERAL_COMMENT, generalComment: e.target.value });

    setSavingInfo('Zapisano.');
    setTimeout(() => {
      setSavingInfo('');
    }, 3000);
  };

  return (
    <section>
      <div className="container-xl">
        <div className="row">
          <div className="col">
            <div className="form-group generalComments">
              <label htmlFor="generalComment">Komentarz do zamówienia</label>
              <textarea
                className="form-control"
                id="generalComment"
                rows={3}
                value={generalComment}
                onChange={handleChange}
              >
                {generalComment}
              </textarea>
              <small className="form-text text-muted">{savingInfo}</small>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default GeneralComments;
