import * as XLSX from 'xlsx/xlsx.mjs';
// import { createFormTitle } from '../../components/OrderForm/OrderFormHeader';
import {
  MODEL_PB001,
  MODEL_PP001N,
  MODEL_PP002,
  MODEL_PP002DIVERSO,
  MODEL_PP002DIVERSO1,
  MODEL_PP002DIVERSO2, MODEL_PP002DIVERSO3, MODEL_PP002P, MODEL_PP002P64, MODEL_PS005,
} from '../../ModelsConstants';
import {
  ADDITIONAL_HORIZONTAL_FRAME_AMOUNT,
  ADDITIONAL_HORIZONTAL_FRAME_MATERIAL,
  ADDITIONAL_HORIZONTAL_FRAME_SIZE,
  ADDITIONAL_VERTICAL_FRAME_AMOUNT,
  ADDITIONAL_VERTICAL_FRAME_MATERIAL,
  ADDITIONAL_VERTICAL_FRAME_SIZE,
  ADDITIONAL_VERTICAL_MODULE_AMOUNT,
  ADDITIONAL_VERTICAL_MODULE_SIZE,
  BASE_BRACKET_AMOUNT,
  BASE_BRACKET_SIZE,
  COVERING_BRACKET_AMOUNT,
  COVERING_BRACKET_SIZE,
  FILLING_100_20_AMOUNT,
  FILLING_100_20_MATERIAL,
  FILLING_100_20_SIZE,
  FILLING_20_20_AMOUNT,
  FILLING_20_20_MATERIAL,
  FILLING_20_20_SIZE,
  FILLING_40_20_AMOUNT,
  FILLING_40_20_MATERIAL,
  FILLING_40_20_SIZE,
  FILLING_80_20_AMOUNT,
  FILLING_80_20_MATERIAL,
  FILLING_80_20_SIZE,
  FILLING_AMOUNT,
  FILLING_FORTIFICATION_AMOUNT,
  FILLING_FORTIFICATION_MATERIAL,
  FILLING_FORTIFICATION_SIZE,
  FILLING_LONG_AMOUNT,
  FILLING_LONG_EXISTING,
  FILLING_LONG_MATERIAL,
  FILLING_LONG_SIZE,
  FILLING_SIZE,
  FRAME_WIDTH_HORIZONTAL_AMOUNT,
  FRAME_WIDTH_HORIZONTAL_SIZE,
  MODULE_WIDTH_AMOUNT,
  MODULE_WIDTH_SIZE,
  NOT_APPLICABLE,
  PLATE_AMOUNT,
  PLATE_HEIGHT,
  PLATE_WIDTH,
  VERTICAL_FRAME_AMOUNT,
  VERTICAL_FRAME_SIZE,
  VERTICAL_MODULE_AMOUNT,
  VERTICAL_MODULE_SIZE,
  VERTICAL_PART_AMOUNT,
  VERTICAL_PART_MATERIAL,
  VERTICAL_PART_SIZE,
} from '../../ResponseConstants';

const GenerateXLS = (products, orders) => {
  const productsForXls = products.map((p) => p);

  const materialsArray = [
    ['Materiał', 'Ilość [szt]', 'Wymiar [mm]'],
  ];

  productsForXls.forEach((product) => {
    const productionMaterials = product?.productionMaterials;
    if (productionMaterials) {
      Object.entries(productionMaterials).forEach((pM) => {
        if (pM[0] === 'saw' || pM[0] === 'angles' || pM[0] === 'bending' || pM[0] === 'gallantry' || pM[0] === 'gallantry1' || pM[0] === 'gallantry2' || pM[0] === 'laser' || pM[0] === 'studding' || pM[0] === 'railing' || pM[0] === 'plank') {
          if (!pM[1]) {
            return null;
          }
          const pMParts = pM[1];
          Object.entries(pMParts).forEach((material) => {
            if (material[0].includes('AfterBending')) {
              return null;
            }
            const materialValues = material[1];
            if (materialValues.size && materialValues.material !== 'NIE DOTYCZY') {
              const materialMaterial = product.rawArray?.kolor === 'colorHdg' ? `${String(materialValues.material)} czarne` : materialValues.material;
              const materialSizeValue = !String(materialValues.size.value).includes('x') ? materialValues.size.value : parseInt(materialValues.size.value.split('x')[0]) * parseInt(materialValues.size.value.split('x')[1]);
              materialsArray.push([materialMaterial, materialValues.amount.value, materialSizeValue]);
            }
            return null;
          });
        } if (pM[0] === 'gearRail') {
          if (product.productionMaterials.gearRail?.gearRailAmount) {
            materialsArray.push(['Listwa zębata 8x30x1005', product.productionMaterials.gearRail?.gearRailAmount, 1005]);
          }
        } if (pM[0] === 'calculation') {
          const pMParts = pM[1];
          Object.entries(pMParts).forEach((material) => {
            const materialValues = material[1];
            if (material[0] === 'non_standard_plate') {
              materialValues.name = 'Płyta XPS niestandardowa';
            }
            const materialSizeValue = !String(materialValues.size.value).includes('x') ? materialValues.size.value : parseInt(materialValues.size.value.split('x')[0]) * parseInt(materialValues.size.value.split('x')[1]);
            materialsArray.push([materialValues.name, materialValues.amount.value, materialSizeValue]);
          });
        }
        return null;
      });
    } else {
      const addColorToMaterial = (material) => {
        if (product.rawArray?.kolor === 'colorHdg') {
          const newMaterial = `${material} czarne`;
          return newMaterial;
        }
        return material;
      };
      if (product.rawArray?.model === MODEL_PB001) {
        if (product.rawArray[VERTICAL_PART_MATERIAL] !== NOT_APPLICABLE) {
          materialsArray.push([product.rawArray[VERTICAL_PART_MATERIAL], product.rawArray[VERTICAL_PART_AMOUNT], product.rawArray[VERTICAL_PART_SIZE]]);
        }
        materialsArray.push(['Blacha PB', product.rawArray[PLATE_AMOUNT], product.rawArray[PLATE_HEIGHT] * product.rawArray[PLATE_WIDTH]]);
      } if (product.rawArray?.model === MODEL_PP001N) {
        materialsArray.push(['Kształtownik 25x25x1,2', product.rawArray[FILLING_AMOUNT], product.rawArray[FILLING_SIZE]]);
        materialsArray.push(['Kształtownik 40x30x1,5', product.rawArray[FRAME_WIDTH_HORIZONTAL_AMOUNT], product.rawArray[FRAME_WIDTH_HORIZONTAL_SIZE]]);
      } if (product.rawArray?.model === MODEL_PP002) {
        materialsArray.push([addColorToMaterial('Kształtownik 60x40x1,5'), product.rawArray[FRAME_WIDTH_HORIZONTAL_AMOUNT], product.rawArray[FRAME_WIDTH_HORIZONTAL_SIZE]]);
        materialsArray.push([addColorToMaterial('Kształtownik 60x40x1,5'), product.rawArray[VERTICAL_FRAME_AMOUNT], product.rawArray[VERTICAL_FRAME_SIZE]]);
        materialsArray.push([addColorToMaterial('Kształtownik 25x25x1,2'), product.rawArray[FILLING_AMOUNT], product.rawArray[FILLING_SIZE]]);
      } if (product.rawArray?.model === MODEL_PP002DIVERSO) {
        materialsArray.push(['Kątownik 30x20x3', product.rawArray[BASE_BRACKET_AMOUNT], product.rawArray[BASE_BRACKET_SIZE]]);
        materialsArray.push(['Kątownik 32x18x1', product.rawArray[COVERING_BRACKET_AMOUNT], product.rawArray[COVERING_BRACKET_SIZE]]);
        if (product.rawArray[FILLING_20_20_MATERIAL] !== NOT_APPLICABLE) {
          materialsArray.push([product.rawArray[FILLING_20_20_MATERIAL], product.rawArray[FILLING_20_20_AMOUNT], product.rawArray[FILLING_20_20_SIZE]]);
        }
        if (product.rawArray[FILLING_40_20_MATERIAL] !== NOT_APPLICABLE) {
          materialsArray.push([product.rawArray[FILLING_40_20_MATERIAL], product.rawArray[FILLING_40_20_AMOUNT], product.rawArray[FILLING_40_20_SIZE]]);
        }
        if (product.rawArray[FILLING_80_20_MATERIAL] !== NOT_APPLICABLE) {
          materialsArray.push([product.rawArray[FILLING_80_20_MATERIAL], product.rawArray[FILLING_80_20_AMOUNT], product.rawArray[FILLING_80_20_SIZE]]);
        }
        if (product.rawArray[FILLING_100_20_MATERIAL] !== NOT_APPLICABLE) {
          materialsArray.push([product.rawArray[FILLING_100_20_MATERIAL], product.rawArray[FILLING_100_20_AMOUNT], product.rawArray[FILLING_100_20_SIZE]]);
        }
        if (product.rawArray[FILLING_LONG_EXISTING]) {
          materialsArray.push([product.rawArray[FILLING_LONG_MATERIAL], product.rawArray[FILLING_LONG_AMOUNT], product.rawArray[FILLING_LONG_SIZE]]);
        }
        if (product.rawArray[FILLING_FORTIFICATION_MATERIAL]) {
          materialsArray.push([product.rawArray[FILLING_FORTIFICATION_MATERIAL], product.rawArray[FILLING_FORTIFICATION_AMOUNT], product.rawArray[FILLING_FORTIFICATION_SIZE]]);
        }
      } if (product.rawArray?.model === MODEL_PP002DIVERSO1) {
        materialsArray.push(['Kątownik 30x20x3', product.rawArray[BASE_BRACKET_AMOUNT], product.rawArray[BASE_BRACKET_SIZE]]);
        materialsArray.push(['Kątownik 32x18x1', product.rawArray[COVERING_BRACKET_AMOUNT], product.rawArray[COVERING_BRACKET_SIZE]]);
        if (product.rawArray[FILLING_20_20_MATERIAL] !== NOT_APPLICABLE) {
          materialsArray.push([product.rawArray[FILLING_20_20_MATERIAL], product.rawArray[FILLING_20_20_AMOUNT], product.rawArray[FILLING_20_20_SIZE]]);
        }
        if (product.rawArray[FILLING_40_20_MATERIAL] !== NOT_APPLICABLE) {
          materialsArray.push([product.rawArray[FILLING_40_20_MATERIAL], product.rawArray[FILLING_40_20_AMOUNT], product.rawArray[FILLING_40_20_SIZE]]);
        }
        if (product.rawArray[FILLING_80_20_MATERIAL] !== NOT_APPLICABLE) {
          materialsArray.push([product.rawArray[FILLING_80_20_MATERIAL], product.rawArray[FILLING_80_20_AMOUNT], product.rawArray[FILLING_80_20_SIZE]]);
        }
        if (product.rawArray[FILLING_100_20_MATERIAL] !== NOT_APPLICABLE) {
          materialsArray.push([product.rawArray[FILLING_100_20_MATERIAL], product.rawArray[FILLING_100_20_AMOUNT], product.rawArray[FILLING_100_20_SIZE]]);
        }
        if (product.rawArray[FILLING_LONG_EXISTING]) {
          materialsArray.push([product.rawArray[FILLING_LONG_MATERIAL], product.rawArray[FILLING_LONG_AMOUNT], product.rawArray[FILLING_LONG_SIZE]]);
        }
        if (product.rawArray[FILLING_FORTIFICATION_MATERIAL]) {
          materialsArray.push([product.rawArray[FILLING_FORTIFICATION_MATERIAL], product.rawArray[FILLING_FORTIFICATION_AMOUNT], product.rawArray[FILLING_FORTIFICATION_SIZE]]);
        }
      } if (product.rawArray?.model === MODEL_PP002DIVERSO2 || product.rawArray?.model === MODEL_PP002DIVERSO3) {
        materialsArray.push(['Kątownik 30x20x3', product.rawArray[BASE_BRACKET_AMOUNT], product.rawArray[BASE_BRACKET_SIZE]]);
        materialsArray.push(['Kątownik 32x18x1', product.rawArray[COVERING_BRACKET_AMOUNT], product.rawArray[COVERING_BRACKET_SIZE]]);
        if (product.rawArray[FILLING_20_20_MATERIAL] !== NOT_APPLICABLE) {
          materialsArray.push([product.rawArray[FILLING_20_20_MATERIAL], product.rawArray[FILLING_20_20_AMOUNT], product.rawArray[FILLING_20_20_SIZE]]);
        }
        if (product.rawArray[FILLING_40_20_MATERIAL] !== NOT_APPLICABLE) {
          materialsArray.push([product.rawArray[FILLING_40_20_MATERIAL], product.rawArray[FILLING_40_20_AMOUNT], product.rawArray[FILLING_40_20_SIZE]]);
        }
        if (product.rawArray[FILLING_80_20_MATERIAL] !== NOT_APPLICABLE) {
          materialsArray.push([product.rawArray[FILLING_80_20_MATERIAL], product.rawArray[FILLING_80_20_AMOUNT], product.rawArray[FILLING_80_20_SIZE]]);
        }
        if (product.rawArray[FILLING_100_20_MATERIAL] !== NOT_APPLICABLE) {
          materialsArray.push([product.rawArray[FILLING_100_20_MATERIAL], product.rawArray[FILLING_100_20_AMOUNT], product.rawArray[FILLING_100_20_SIZE]]);
        }
        if (product.rawArray[FILLING_LONG_EXISTING]) {
          materialsArray.push([product.rawArray[FILLING_LONG_MATERIAL], product.rawArray[FILLING_LONG_AMOUNT], product.rawArray[FILLING_LONG_SIZE]]);
        }
        if (product.rawArray[FILLING_FORTIFICATION_MATERIAL]) {
          materialsArray.push([product.rawArray[FILLING_FORTIFICATION_MATERIAL], product.rawArray[FILLING_FORTIFICATION_AMOUNT], product.rawArray[FILLING_FORTIFICATION_SIZE]]);
        }
      } if (product.rawArray?.model === MODEL_PP002P) {
        materialsArray.push(['Kształtownik 60x40x1,5', product.rawArray[VERTICAL_FRAME_AMOUNT], product.rawArray[VERTICAL_FRAME_SIZE]]);
        if (product.rawArray[ADDITIONAL_HORIZONTAL_FRAME_MATERIAL] !== NOT_APPLICABLE) {
          materialsArray.push([product.rawArray[ADDITIONAL_HORIZONTAL_FRAME_MATERIAL], product.rawArray[ADDITIONAL_HORIZONTAL_FRAME_AMOUNT], product.rawArray[ADDITIONAL_HORIZONTAL_FRAME_SIZE]]);
        }
        materialsArray.push(['Kształtownik 60x40x1,5', product.rawArray[FRAME_WIDTH_HORIZONTAL_AMOUNT], product.rawArray[FRAME_WIDTH_HORIZONTAL_SIZE]]);
        materialsArray.push(['Kształtownik 25x25x1,2', product.rawArray[FILLING_AMOUNT], product.rawArray[FILLING_SIZE]]);
      } if (product.rawArray?.model === MODEL_PP002P64) {
        materialsArray.push(['Kształtownik 60x50x1,5', product.rawArray[VERTICAL_FRAME_AMOUNT], product.rawArray[VERTICAL_FRAME_SIZE]]);
        if (product.rawArray[ADDITIONAL_VERTICAL_FRAME_MATERIAL] !== NOT_APPLICABLE) {
          materialsArray.push([product.rawArray[ADDITIONAL_VERTICAL_FRAME_MATERIAL], product.rawArray[ADDITIONAL_VERTICAL_FRAME_AMOUNT], product.rawArray[ADDITIONAL_VERTICAL_FRAME_SIZE]]);
        }
        materialsArray.push(['Kształtownik 60x50x1,5', product.rawArray[FRAME_WIDTH_HORIZONTAL_AMOUNT], product.rawArray[FRAME_WIDTH_HORIZONTAL_SIZE]]);
        materialsArray.push(['Kształtownik 60x40x1,25', product.rawArray[FILLING_AMOUNT], product.rawArray[FILLING_SIZE]]);
      } if (product.rawArray?.model === MODEL_PS005) {
        materialsArray.push(['Kształtownik 20x20x1,5', product.rawArray[VERTICAL_MODULE_AMOUNT], product.rawArray[VERTICAL_MODULE_SIZE]]);
        materialsArray.push(['Kształtownik 20x20x1,5', product.rawArray[MODULE_WIDTH_AMOUNT], product.rawArray[MODULE_WIDTH_SIZE]]);
        materialsArray.push([product.rawArray[ADDITIONAL_VERTICAL_FRAME_MATERIAL], product.rawArray[ADDITIONAL_VERTICAL_MODULE_AMOUNT], product.rawArray[ADDITIONAL_VERTICAL_MODULE_SIZE]]);
        materialsArray.push(['Blacha 0,5x112mm DX51D', product.rawArray[FILLING_AMOUNT], product.rawArray[FILLING_SIZE]]);
      }
    }
  });

  const mergedMaterials = {};

  materialsArray.forEach((row) => {
    const material = `${row[0]}${row[2]}`;

    if (mergedMaterials[material]) {
      mergedMaterials[material][1] += row[1];
    } else {
      mergedMaterials[material] = row;
    }
  });

  const mergedMaterialsArray = Object.values(mergedMaterials);

  const workbook = XLSX.utils.book_new();
  const worksheet = XLSX.utils.aoa_to_sheet(mergedMaterialsArray);
  workbook.SheetNames.push('Materiały');
  workbook.Sheets['Materiały'] = worksheet;
  XLSX.writeFile(workbook, `materialy-${orders.join('-')}.xlsx`);
};

export default GenerateXLS;
