import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import jwtDecode from 'jwt-decode';
import dayjs from 'dayjs';
import axios from 'axios';

import {
  calcTokenDiff, loadUser, setTokens, signOutOnError, signOut,
  loadAndSetUserWorkingPlace,
} from 'auth';

const refreshToken = (token, dispatch) => new Promise((resolve, reject) => axios
  .post('/token/refresh', {
    refresh_token: token,
  })
  .then(({ data }) => {
    setTokens(data.token, data.refresh_token, dispatch);
    resolve();
  })
  .catch(() => {
    signOutOnError(dispatch, 'unable to refresh token');
    reject();
  }));

// returns diff to exp in ms
const getExpirationTimeDifference = (token) => {
  const t = jwtDecode(token);
  if (!t?.exp) return null;
  return dayjs.unix(t.exp).diff(new Date(), 'ms');
};

axios.interceptors.request.use(
  (c) => {
    if (
      c.url === '/login'
      || c.url === '/user-forgot-password'
      || c.url.includes('/user-forgot-password-new-password')
      || c.url === 'token/refresh'
      || c.url.endsWith('without-auth')
    ) {
      return c;
    }
    const t = localStorage.getItem('token');
    if (!t) {
      signOut();
      return c;
    }
    const exp = getExpirationTimeDifference(t);
    if (exp <= 0) {
      signOut();
      return c;
    }
    if (exp <= 120000) {
      refreshToken();
    }
    const newC = {
      ...c,
    };
    newC.headers.Authorization = `Bearer ${t}`;
    return newC;
  },
  (e) => {
    Promise.reject(e);
  },
);

const AppAuth = ({ children }) => {
  const dispatch = useDispatch();
  const user = loadUser();
  const authenticated = useSelector((s) => s.app.authenticated);
  const diff = calcTokenDiff(user);
  useEffect(() => {
    loadAndSetUserWorkingPlace();
    const token = localStorage.getItem('refreshToken');
    if (!token) {
      return undefined;
    }

    if (diff < 60000) {
      // 60000 ms = 1 minute
      refreshToken(token, dispatch);

      return undefined;
    }

    const id = setTimeout(() => {
      refreshToken(token, dispatch);
    }, diff - 60000); // 60000 ms = 1 minute

    return () => clearTimeout(id);
  }, [dispatch, diff]);
  useEffect(() => {
    if (!user || authenticated) return;
    dispatch({ type: 'SIGN_IN' });
  }, [user, authenticated]);

  if (!axios.defaults.headers.common.Authorization && localStorage.getItem('token')) {
    axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('token')}`;
    return null;
  }

  return children;
};

export default AppAuth;
