import React from 'react';
import { FENCE_TYPE_SINGLE_LEAF_FOLDING_GATE } from '../../FenceTypesConstants';
import { POLES_LACK, POLES_ONE, POLES_STANDARD } from './Poles';

export const MOUNTING_STRIP_NAME = 'mountingStrip';
export const MOUNTING_STRIP_ZERO = 'mountingStripZero';
export const MOUNTING_STRIP_ONE = 'mountingStripOne';
export const MOUNTING_STRIP_TWO = 'mountingStripTwo';

const MountingStrip = ({
  formId, onChange, value, poles, type,
}) => {
  // eslint-disable-next-line consistent-return
  const shownOptions = () => {
    if (poles === POLES_LACK) {
      if (type === FENCE_TYPE_SINGLE_LEAF_FOLDING_GATE) {
        return (
          <>
            <option value={MOUNTING_STRIP_ZERO}>0 szt</option>
            <option value={MOUNTING_STRIP_ONE}>1 szt</option>
          </>
        );
      }
      return (
        <>
          <option value={MOUNTING_STRIP_ZERO}>0 szt</option>
          <option value={MOUNTING_STRIP_ONE}>1 szt</option>
          <option value={MOUNTING_STRIP_TWO}>2 szt</option>
        </>
      );
    }
    if (poles === POLES_ONE) {
      return (
        <>
          <option value={MOUNTING_STRIP_ZERO}>0 szt</option>
          <option value={MOUNTING_STRIP_ONE}>1 szt</option>
        </>
      );
    } if (poles === POLES_STANDARD) {
      return (
        <>
          <option value={MOUNTING_STRIP_ZERO}>0 szt</option>
        </>
      );
    }
  };

  return (
    <div className="form-group">
      <label htmlFor={`mountingStrip__${formId}`}>Listwa montażowa</label>
      <select
        className="form-control"
        id={`mountingStrip__${formId}`}
        onChange={onChange}
        name="mountingStrip"
        value={value}
        required
      >
        <option disabled selected>Wybierz</option>
        {shownOptions()}
      </select>
    </div>
  );
};

export default MountingStrip;
